import React, {FC, FocusEvent, ChangeEvent, ReactElement} from 'react';
import {Input} from '@optimaxdev/design-system-u';
import classNames from 'classnames';

import {getCorrectClassName} from 'libs/utils/getCorrectClassName';
import {Icon} from 'components/__helpers/icon/icon';
import {errorMessages} from 'pages/rma/store/reducers';

import s from './inputForm.scss';

export type PropsType = {
    name: string;
    label: string;
    value: string;
    errorMessage?: string;
    type?: 'text' | 'password' | 'email' | 'number' | 'tel';
    placeholder?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    className?: string;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClear?: () => void;
};

const wrapper = (children: ReactElement, className?: string) =>
    className ? <div className={className}>{children}</div> : children;

const crossIcon = (isShow: boolean, isUnderErr: boolean, onClick?: () => void) =>
    isShow && onClick ? (
        <button
            aria-label="Clear input"
            type="button"
            onClick={onClick}
            className={classNames(
                s.icon,
                {[s.iconError]: isUnderErr},
                s[`${getCorrectClassName('icon')}`],
            )}
        >
            <Icon name="crossInCircle" />
        </button>
    ) : null;

export const InputForm: FC<PropsType> = props => {
    const {name, value, className, errorMessage, onClear, ...restProps} = props;

    return wrapper(
        <Input
            {...restProps}
            name={name}
            value={String(value).trim() ? value : ''}
            icon={crossIcon(Boolean(value), Boolean(errorMessages), onClear)}
            message={errorMessage}
            isError={Boolean(errorMessage)}
        />,
        className,
    );
};
