import {SagaIterator} from 'redux-saga';
import {
    select,
    put,
    putResolve,
    takeEvery,
    takeLeading,
    call,
    all,
    cancel,
    fork,
} from 'redux-saga/effects';

import {FULFILLED} from 'constants/actionSuffix';
import {oauth2Selector} from 'selectors/oauth2Data/oauth2Data';
import {isUHCMember} from 'selectors/user/user';
import {getCustomer, logoutCustomer} from 'reducers/customer';
import {getUser, getUserDiscount, clearUser} from 'reducers/user';
import {closeCmsPopup} from 'reducers/cms/cmsPopup';
import {getWishList, clearWishList} from 'reducers/wishList';
import {getCartTotals, clearLocalCart, getCartItems} from 'reducers/cart';
import {clearOrder} from 'reducers/order';
import {clearAddress} from 'reducers/addressEdit';
import {clearOrdersInfo} from 'reducers/ordersInfo';
import {clearPrescriptions} from 'reducers/prescription';
import {clearPrescription} from 'reducers/prescriptionEdit';
import {closeDialog} from 'reducers/dialog';
import {clearMyPlan, getMyPlan} from 'reducers/myPlan';
import {clearAllFormData} from 'reducers/formData';
import {
    getTokens,
    putRefreshToken,
    isTokenExist,
    isTokenSet,
    clearTokens,
    logOut,
    setToken,
    deleteUHCMember,
} from 'reducers/oauth2';
import {closeTryOn} from 'reducers/tryOn';
import {sendLoginEvent} from 'libs/analytics/analytics';
import {resetEligibilityWidget} from 'features/store/eligibilityWidget';
import {ActionType, Maybe} from 'constants/typescript/types';
import {setPage} from 'reducers/route';
import {resetShippingMethod} from 'reducers/shipping/shipping';
import {getPageName} from 'selectors/page/getPageData';
import flags from 'constants/flags/flags';
import {localStore} from 'libs/storage';

import {sendHashedEmail} from '../analytics/analytics';
import {sendWhenCartHaveItems} from '../analytics/EEC/cart';

const MEMBER_COOKIE_STRING = 'in_network=1;Path=/;';

const MEMBER_COOKIE_REMOVE_STRING = 'in_network=;Path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';

/**
 * Add cookie for member users
 */
export function addMemberCookieForDocument() {
    document.cookie = MEMBER_COOKIE_STRING;
}

/**
 * Removes member cookie
 *
 */
export function removeMemberCookieFromDocument() {
    document.cookie = MEMBER_COOKIE_REMOVE_STRING;
}

/**
 * Set oauth2 token
 */
export function* setAuthToken(): SagaIterator {
    const {access_token: accessToken}: ReturnType<typeof oauth2Selector> = yield select(
        oauth2Selector,
    );
    if (!accessToken) return;
    yield put(isTokenSet());
}

export const resetWelcomePopupShown = () => localStorage.setItem('popUpWasShown', '');

export function* logOutActions(
    ...[, isLogoutAfterPurchase = false]: [Maybe<ActionType>?, boolean?]
): SagaIterator {
    yield call([localStore, 'remove'], 'IS_SUPPORT_AGENT');

    yield all([
        put(clearTokens()),
        put(logoutCustomer()),
        put(clearUser()),
        put(clearWishList()),
        put(clearLocalCart()),
        put(clearOrder()),
        put(clearAddress()),
        put(clearOrdersInfo()),
        put(clearPrescriptions()),
        put(clearPrescription()),
        put(clearMyPlan()),
        put(clearAllFormData()),
        put(closeTryOn()),
        put(resetEligibilityWidget()),
        put(resetShippingMethod()),
        call(removeMemberCookieFromDocument),
    ]);

    yield call(resetWelcomePopupShown);

    if (!isLogoutAfterPurchase) yield put(deleteUHCMember());
}

/**
 * Get refresh token or logout if refresh token is invalid
 *
 * @param {string} refreshToken - refresh token
 */
export function* getRefreshToken(refreshToken: string): SagaIterator {
    const {error} = yield putResolve(putRefreshToken(refreshToken));

    if (error && error.response && error.response.status === 401) {
        yield call(logOutActions);
        yield cancel();
    }
}

/**
 * Put refresh token and set new access token. If user have active session and auto logged, send analytics
 */
export function* putRefreshTokenSaga(): SagaIterator {
    const {
        refresh_token: refreshToken,
        expiresDate,
    }: ReturnType<typeof oauth2Selector> = yield select(oauth2Selector);
    if (!refreshToken) return;

    if (expiresDate < Date.now()) {
        yield call(getRefreshToken, refreshToken);
    }

    yield call(setAuthToken);
    yield call(sendLoginEvent, 'Logged in', 'Earlier');
}

/**
 * After receiving tokens, actions for receive user data
 */
export function* logInActions(): SagaIterator {
    const page = yield select(getPageName);
    if (!flags.mfaUHCGD.isEnabled()) yield put(closeCmsPopup());

    yield all([
        put(getWishList()),
        put(getUser()),
        put(getCustomer()),
        put(getCartTotals(false)),
        put(getMyPlan()),
        put(getUserDiscount()),
        put(closeDialog()),
    ]);
    yield fork(sendHashedEmail);

    if (page !== 'cart' && page !== 'configureWizard') {
        yield put(getCartItems(false));
        yield call(sendWhenCartHaveItems);
    }
}

/**
 * set to default widget
 */
export function* setToDefaultWidget(): SagaIterator {
    const page = yield select(getPageName);
    if (page !== 'wizard') {
        yield put(resetEligibilityWidget());
    }
}

/**
 * Set the "in_network" cookie if insurance company is UHC
 */
export function* setInNetworkSaga(): SagaIterator {
    const isUHC = yield select(isUHCMember);
    if (isUHC) {
        yield call(addMemberCookieForDocument);
    }
}

/**
 * Begin of saga
 */
export function* oauth2Saga(): SagaIterator {
    yield takeEvery([`${getTokens}${FULFILLED}`, setToken], setAuthToken);
    yield takeEvery(`${getUserDiscount}${FULFILLED}`, setInNetworkSaga);
    yield takeEvery(isTokenExist, putRefreshTokenSaga);
    yield takeLeading(logOut, logOutActions);
    yield takeEvery(isTokenSet, logInActions);
    yield takeEvery(setPage, setToDefaultWidget);
}
