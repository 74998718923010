import {createAction, createReducer} from 'redux-act';
import {clone, mapObjIndexed} from 'ramda';
import {AxiosResponse} from 'axios';

import {fetchQuoteItemV2} from 'features/wizard/store/reducers/configure/configure';
import {QuoteItemV2OptionType, QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';
import {ApplicationStoreType} from 'constants/flow/flowTypes';
import {FULFILLED} from 'constants/actionSuffix';

import {CustomerConfigurationType, MetaOptionsType} from './customerConfigurationType';

export const initialState: CustomerConfigurationType = {
    options: {},
    quantity: 1,
};

/**
 * Sets configuration data that customers selected according to the wizard data.
 */
export const setOption = createAction<Record<string, number>, MetaOptionsType>(
    'CUSTOMER_CONFIGURATION_SET_OPTION',
    payload => payload,
    (payload, meta) => meta,
);

/**
 * Remove option by parent node id
 */
export const removeOptions = createAction<number[]>('CUSTOMER_CONFIGURATION_REMOVE_OPTION');

/**
 * Toggle configuration data that customers selected according to the wizard data.
 */
export const toggleOption = createAction<{parentNodeId: number; node: number}, MetaOptionsType>(
    'CUSTOMER_CONFIGURATION_TOGGLE_OPTION',
    payload => payload,
    (payload, meta) => meta,
);

/**
 * Sets quantity of the product that will be added to cart
 */
export const setQuantity = createAction<number>('CUSTOMER_CONFIGURATION_SET_QUANTITY');

/**
 * Reset customer configuration.
 */
export const reset = createAction('CUSTOMER_CONFIGURATION_RESET');

/**
 * Customer glasses configuration reducer.
 */
export const customerConfiguration = createReducer<CustomerConfigurationType>({}, initialState)
    .on(`${fetchQuoteItemV2}${FULFILLED}`, (state, payload: AxiosResponse<QuoteItemV2Type>) => ({
        ...state,
        quantity: payload.data.qty,
        options: mapObjIndexed((value: QuoteItemV2OptionType) => value.id, payload.data.options),
    }))
    .on(setOption, (state, payload) => ({
        ...state,
        options: {...state.options, ...payload},
    }))
    .on(removeOptions, (state, payload) => {
        const prepareDeleteItems = payload.filter(node => state.options[node]);
        const options = clone(state.options);

        prepareDeleteItems.forEach(node => {
            delete options[node];
        });

        return {
            ...state,
            options,
        };
    })
    .on(toggleOption, (state, {parentNodeId, node}) => {
        const isOptionExist = state.options[parentNodeId] === node;
        if (isOptionExist) {
            const options = clone(state.options);
            delete options[parentNodeId];
            return {
                ...state,
                options,
            };
        }
        return {
            ...state,
            options: {...state.options, [parentNodeId]: node},
        };
    })
    .on(setQuantity, (state, payload) => ({
        ...state,
        quantity: payload,
    }))
    .on(reset, () => initialState);

/**
 * Return wizard configuration created by customer
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {*} wizard configuration created by customer
 */
export const getCustomerConfiguration = (store: ApplicationStoreType): CustomerConfigurationType =>
    store.wizardMs.customerConfiguration;
