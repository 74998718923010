import {SagaIterator} from 'redux-saga';
import {AxiosResponse} from 'axios';
import {select, put, putResolve, all, call, takeEvery, take, fork} from 'redux-saga/effects';
import {createEECPurchaseEvent, pushDataLayer} from '@optimaxdev/analytics/desktop';

import {ActionType} from 'constants/typescript/types';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {getPageData} from 'selectors/page/getPageData';
import {getCountryData} from 'selectors/country/country';
import {setPage} from 'reducers/route';
import {getCountry, getCountryRegions} from 'reducers/country/country';
import {getCartItems, getCartTotals} from 'reducers/cart';
import {getOrder} from 'reducers/ordersInfo';
import {
    saveOrder,
    setPaymentStep,
    saveOrderAddresses,
    startPaymentStepSaga,
    startPlaceOrderSaga,
    setOrderId,
    setError,
    SaveOrderResponseType,
} from 'reducers/order';
import {getUser} from 'reducers/user';
import {saveShippingMethod} from 'reducers/shipping';
import {getPaymentMethods, savePaymentMethod} from 'reducers/payment';
import {getShippingData} from 'selectors/shipping/shipping';
import {getCartData} from 'selectors/cart/cart';
import {
    getOrderAddressesFormData,
    getPaymentData,
    getCurrentOrder,
    getCategoryMap,
} from 'selectors/order/order';
import {prepareActionField, prepareCoupons, prepareProducts} from 'libs/analytics/eec';
import {clearCard} from 'reducers/creditCard';
import {getAddressesData} from 'selectors/address/address';
import {localStore} from 'libs/storage';
import {deleteAddress} from 'reducers/address';

/**
 * Fetch cart data
 */
export function* getData(): SagaIterator {
    const {page, prevPage}: ReturnType<typeof getPageData> = yield select(getPageData);
    const {selectedCountry, defaultCountry}: ReturnType<typeof getCountryData> = yield select(
        getCountryData,
    );

    if (page !== 'checkout' || prevPage.page === page) return;

    if (prevPage.page !== 'cart') {
        yield put(getCartItems(true));
    }

    yield all([put(getCountry()), put(getCountryRegions(selectedCountry || defaultCountry))]);
}

/**
 * Save order data
 */
export function* saveOrderData(): SagaIterator {
    const {selectedMethod}: ReturnType<typeof getShippingData> = yield select(getShippingData);
    const orderAddressesFromData = yield select(getOrderAddressesFormData);

    yield putResolve(saveOrderAddresses(orderAddressesFromData));
    yield putResolve(saveShippingMethod(selectedMethod));
    yield putResolve(getPaymentMethods());
    yield putResolve(getCartTotals(true));
    yield put(clearCard());
    yield put(setPaymentStep());
}

/**
 * Save order data
 */
export function* placeOrder(): SagaIterator {
    const {selectedMethod}: ReturnType<typeof getShippingData> = yield select(getShippingData);
    const paymentData = yield select(getPaymentData);

    yield putResolve(saveShippingMethod(selectedMethod));
    yield putResolve(savePaymentMethod(paymentData));

    yield put(saveOrder(paymentData));
}

/**
 * Send EEC Event to dataLayer
 *
 * @param {object} categoryMap - map of categories related to sku product
 * @param {string} coupons - store credit coupons
 */
export function* sendEECPurchase(
    categoryMap: {[key: string]: string},
    coupons: string,
): SagaIterator {
    yield take(`${getOrder}${FULFILLED}`);
    const order = yield select(getCurrentOrder);
    if (!order) return;
    const cart = yield select(getCartData);
    if (!cart) return;
    const actionField = yield call(prepareActionField, order, coupons);
    const products = yield call(prepareProducts, order, categoryMap);

    yield call(
        pushDataLayer,
        createEECPurchaseEvent({
            actionField,
            products,
            paymentMethod: order.payment.name,
        }),
    );
}

/**
 * Send GA Event to dataLayer
 *
 */
export function* sendPurchaseEvent(): SagaIterator {
    yield take(`${getOrder}${FULFILLED}`);
    const order = yield select(getCurrentOrder);
    if (!order) return;
    yield call(pushDataLayer, {
        event: 'CheckoutInteraction',
        eventAction: `Step 3 - Review Order`,
        eventCategory: 'Checkout - D',
        eventLabel: `CTA - Place Order - ${order.payment.name}`,
    });
}

/**
 * Send GA Event to dataLayer
 *
 * @param {string} error - error message.
 */
export function* sendPurchaseEventRejected(error): SagaIterator {
    yield take(`${getOrder}${REJECTED}`);
    const order = yield select(getCurrentOrder);
    if (!order) return;
    yield call(pushDataLayer, {
        event: 'CheckoutInteraction',
        eventAction: `Step 3 - ${order.payment.name}`,
        eventCategory: 'Checkout - D',
        eventLabel: `Error - ${error}`,
    });
}

/**
 * Clear customer support agent addresses order successes
 */
export function* clearSupportAgentAddresses(): SagaIterator {
    if (!localStore.get('IS_SUPPORT_AGENT')) return;

    const addresses = yield select(getAddressesData);
    yield all(addresses.map(({id}) => putResolve(deleteAddress(id))));
}

/**
 * Processing order result
 *
 * @param {ActionType<PageType>} action - action
 */
export function* processingOrderResult({
    payload: {data},
}: ActionType<AxiosResponse<SaveOrderResponseType>>): SagaIterator {
    if (data.success) {
        yield call(clearSupportAgentAddresses);
        const {totals} = yield select(getCartData);
        const categoryMap = yield select(getCategoryMap);

        yield put(setOrderId(data.id));
        yield fork(sendPurchaseEvent);
        yield fork(sendEECPurchase, categoryMap, prepareCoupons(totals.certificates));
        yield all([put(getUser()), put(getOrder(Number(data.id))), put(getCartTotals(true))]);
    } else {
        yield put(setError(data.errors.map(error => error.title).join(', ')));
        yield fork(sendPurchaseEventRejected, data.errors.map(error => error.title).join(', '));
    }
}

/**
 * Begin of saga
 */
export function* checkoutSaga(): SagaIterator {
    yield takeEvery(setPage, getData);
    yield takeEvery(startPaymentStepSaga, saveOrderData);
    yield takeEvery(startPlaceOrderSaga, placeOrder);
    yield takeEvery(`${saveOrder}${FULFILLED}`, processingOrderResult);
}
