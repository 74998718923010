import {createAction, createReducer} from 'redux-act';

import {RequestType, ResponseType} from 'constants/typescript/types';
import {FULFILLED} from 'constants/actionSuffix';

import {MyPlanType} from './myPlanType';

export const defaultState: MyPlanType = {
    discounts: {
        frame: {title: '', data: []},
        package: {title: '', data: []},
        packageOption: {title: '', data: []},
        otherBenefits: {title: '', data: []},
        customOption: {title: '', data: []},
    },
    benefits: [],
};

export const getMyPlan = createAction<RequestType>('GET_MY_PLAN', () => ({
    request: {
        method: 'GET',
        url: `/backend/api/rest/uhc/member/v3`,
        headers: {Authorization: ''},
    },
}));

export const clearMyPlan = createAction('CLEAR_MY_PLAN');

/**
 * My plan reducer
 */
export const myPlan = createReducer<MyPlanType>(
    {
        [`${getMyPlan}${FULFILLED}`]: (state, {data}: ResponseType<MyPlanType>) => ({
            ...data,
        }),
        [`${clearMyPlan}`.toString()]: () => ({...defaultState}),
    },
    defaultState,
);
