import {createReducer} from 'redux-act';

import {RMA_STATES} from '../../../constants/common';
import {changeRmaState} from '../../actions/actions';
import {RmaResponseType, RmaDataType} from '../../../types';

export const initialState: RmaDataType['secondStep'] = {
    isCancellation: false,
    reasons: {
        returns: null,
        cancellation: null,
    },
    offerMultifocal: {
        customerName: '',
    },
};

/**
 * Reducer rma improved second step.
 */
export const secondStep = createReducer({}, initialState).on(
    `${changeRmaState}_FULFILLED`,
    // eslint-disable-next-line complexity
    (state, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.RETURNS_REASONS:
            case RMA_STATES.CANCELLATION_REASONS: {
                const {data} = response;
                const reasons = (Array.isArray(data) ? data[0] : data) || {};
                const isCancellation = Object.values(reasons).every(
                    item => typeof item === 'string',
                );
                return {
                    ...state,
                    isCancellation,
                    reasons: {
                        ...state.reasons,
                        [isCancellation ? 'cancellation' : 'returns']: reasons,
                    },
                };
            }
            case RMA_STATES.OFFER_MULTIFOCAL:
                return {...state, offerMultifocal: response.data};
            default:
                return state;
        }
    },
);
