import {pickBy} from 'ramda';
import React, {FC} from 'react';
import {useMutation} from '@tanstack/react-query';

import {getToken} from '../../../api/customerMs/customerMs';
import {uhcLogin} from '../../../api/uhc/uhc';
import {EmailLoginDataType} from '../../../context/loginPopupContext';

import s from './waitForCode.scss';
import {useCountDown} from './timer/timer';
import {sendMFAAnalytic} from '../analytics';

const RESEND_TIME = 60;

export type PropsType = {
    memberLoginData: Record<string, string>;
    isMember: boolean;
    emailLoginData: EmailLoginDataType;
    canEditPhone: boolean;
    phoneNumber: string;
};

export const BottomCodeSection: FC<PropsType> = ({
    isMember,
    memberLoginData,
    emailLoginData,
    canEditPhone,
    phoneNumber,
}) => {
    const [counter, setCountDown] = useCountDown();
    const {mutate} = useMutation(
        () =>
            isMember
                ? uhcLogin(
                      pickBy(value => value !== '', {
                          ...memberLoginData,
                          ...(canEditPhone ? {phone: phoneNumber} : ''),
                      }),
                  )
                : getToken(
                      emailLoginData.email.value,
                      emailLoginData.password.value,
                      canEditPhone ? phoneNumber : '',
                  ),
        {
            onSuccess() {
                setCountDown(RESEND_TIME);
            },
            onError() {
                setCountDown(RESEND_TIME);
            },
        },
    );
    const resendCode = () => {
        mutate();
        sendMFAAnalytic('Resend Code', canEditPhone ? 2 : '2 - Verified Phone Number');
    };
    return (
        <div className={s.resendText}>
            Didn&apos;t get the code?{' '}
            {counter > 0 ? (
                <span className={s.resendTextAgain}>Send it again in {counter}s</span>
            ) : (
                <button className={s.resendButton} onClick={resendCode} type="button">
                    Resend
                </button>
            )}
        </div>
    );
};
