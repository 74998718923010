import {REACTPV} from '@optimaxdev/analytics/desktop';
import {ComponentType, LazyExoticComponent} from 'react';
import {ConnectedComponent} from 'react-redux';

import {LINK_PAGE, LINK_MY_ACCOUNT} from 'constants/links';

import * as Lazy from './lazyComponents';
import {PAGE_PARAM2} from '../../../constants/analytics';

export const StaticRoutesAuth: Array<{
    path: string;
    page: string;
    event?: string;
    page_type_param2?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: LazyExoticComponent<ComponentType | ConnectedComponent<any, any>>;
}> = [
    {
        path: LINK_PAGE.WISH_LIST,
        page: 'wishlist',
        event: REACTPV.WISHLIST,
        component: Lazy.WishListConnected,
        page_type_param2: PAGE_PARAM2.WISHLIST,
    },
    {
        path: LINK_PAGE.CART,
        page: 'cart',
        event: REACTPV.CART,
        component: Lazy.CartConnected,
        page_type_param2: PAGE_PARAM2.CART,
    },
    {
        path: LINK_PAGE.CHECKOUT,
        page: 'checkout',
        event: REACTPV.CHECKOUT,
        component: Lazy.CheckoutConnected,
        page_type_param2: PAGE_PARAM2.CHECKOUT,
    },
    {
        path: LINK_PAGE.THANK_YOU,
        page: 'success',
        event: REACTPV.ORDER_SUCCESS,
        component: Lazy.ThankYouConnected,
        page_type_param2: PAGE_PARAM2.TYP,
    },
    {
        path: LINK_MY_ACCOUNT.PLAN,
        page: 'account',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.ACCOUNT,
        page: 'account',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.SETTINGS,
        page: 'account-edit',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.ADDRESSES,
        page: 'customer-address',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.PASSWORD,
        page: 'customer-address',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: `${LINK_MY_ACCOUNT.ADDRESSES}/new`,
        page: 'customer-address-new',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: `${LINK_MY_ACCOUNT.ADDRESSES}/edit/id/:id`,
        page: 'customer-address-edit',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.PRESCRIPTIONS,
        page: 'customer-glasses-prescription',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.TRY_ON_PHOTOS,
        page: 'customer-mirror',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.ORDERS,
        page: 'orders-list',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: `${LINK_MY_ACCOUNT.ORDERS}/view/order_id/:id`,
        page: 'order-details',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: `${LINK_MY_ACCOUNT.ORDERS}/view/order_id/:id/print`,
        page: 'order-print',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.OrderPrintConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
];
