/* istanbul ignore file */

import React, {useState, SyntheticEvent, useContext} from 'react';
import {Input} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import {pickBy} from 'ramda';
import InputMask from 'react-text-mask';
import {connect} from 'react-redux';
import SessionStore from 'store/storages/sessionStorage';

import {setToken as setTokenAction} from 'reducers/oauth2';
import {USER_MEMBER_TYPE} from 'constants/authorization';
import {validateForm} from 'libs/validation/validateForm';
import {
    getErrorMessageByAxiosErrorWithErrorDetails,
    AxiosErrorWithApiResponseErrorDetails,
} from 'libs/axiosResponceHandling';
import {LOGIN_STATUS, UHC_MEMBER} from 'constants/loginStatus/loginStatus';
import flags from 'constants/flags/flags';
import {
    LoginPopupContext,
    LoginPopupContextType,
} from 'features/loginPopup/context/loginPopupContext';

import s from '../loginPopup.scss';
import {uhcLogin} from '../../api/uhc/uhc';
import {LoginPopupNames} from '../loginPopup';

import {
    sendLoginAsUHCFailedEvent,
    sendLoginAsUHCSuccessEvent,
    sendLoginStatusEvent,
} from './analytics';
import {ButtonsBlock} from './buttonsBlock';
import {getInputsList, PropsType} from './helpers';

/**
 * Credentials for testing
 *
 * Almost free wizard
 * id: 960908098
 * birth: 2010-01-01
 *
 * Discount only options (clear, clear digital block, photochromic and sunglasses)
 * id: 946229570
 * birth: 1984-09-15
 *
 * Discount for all wizard staff by 50% (probably)
 * id: 944991615
 * birth: 1976-09-07
 *
 * id: 801024487
 * birth: 1969-09-14
 */

/**
 * UHC authorization Form.
 *
 * @returns {React.ReactElement} UHC Form
 */

export const MemberLogin: React.FC<PropsType> = ({
    onRequestClose,
    setPopup,
    setToken,
    setIsError,
}) => {
    const [errors, setErrors] = useState<Record<string, string>>({});

    const {
        setIsMember,
        setMemberLoginData,
        memberLoginData,
        setCanEditPhone,
        setAccountUuid,
        isFullNameFrom,
        toggleFormOption,
        setPhoneNumber,
        clearMemberLoginData,
    } = useContext(LoginPopupContext) as LoginPopupContextType;

    const setMfaPopup = data => {
        setCanEditPhone(data.canEditPhone);
        setAccountUuid(data.accountUuid);
        setPhoneNumber(data.phone);
        setPopup(LoginPopupNames.mfa);
    };
    const {isLoading, mutate} = useMutation(
        () => uhcLogin(pickBy(value => value !== '', memberLoginData)),
        {
            onSuccess: data => {
                // FIXME: backend send empty array on UHC login
                if (data && !Array.isArray(data)) {
                    if (flags.mfaUHCGD.isEnabled() && data.phone) {
                        setMfaPopup(data);
                    } else {
                        setToken({
                            ...data,
                            memberType: USER_MEMBER_TYPE.UHC_MEMBER,
                            settingsSafetyMode: false,
                        });
                        onRequestClose();
                        sendLoginAsUHCSuccessEvent(isFullNameFrom);
                        // eslint-disable-next-line max-depth
                        if (SessionStore.read('login_status') !== UHC_MEMBER) {
                            sendLoginStatusEvent();
                            SessionStore.write(LOGIN_STATUS, UHC_MEMBER);
                        }
                    }
                }
            },
            onError: (errorResponse: AxiosErrorWithApiResponseErrorDetails) => {
                if (
                    flags.mfaUHCGD.isEnabled() &&
                    errorResponse.response?.status === 401 &&
                    errorResponse.response?.data?.hint === 'phone'
                ) {
                    setPopup(LoginPopupNames.mfa);
                } else {
                    const backendResponseErrorString = getErrorMessageByAxiosErrorWithErrorDetails(
                        errorResponse,
                    );
                    setErrors(errors => ({
                        ...errors,
                        response: backendResponseErrorString,
                    }));
                    setIsError(true);
                    sendLoginAsUHCFailedEvent(isFullNameFrom, backendResponseErrorString);
                }
            },
            onSettled: () => {
                setIsMember(true);
            },
        },
    );

    const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {hasErrors, errors} = validateForm(getInputsList(isFullNameFrom), memberLoginData);
        setErrors(errors);
        if (!hasErrors) mutate();
    };
    return (
        <section>
            <h2 className={s.title}>Access your vision benefits</h2>
            <p className={s.text}>Check your vision plan coverage and eligibility</p>

            <form onSubmit={onSubmit}>
                {getInputsList(isFullNameFrom).map(({name, placeholder, ...props}) => (
                    <div className={s.input} key={name}>
                        <InputMask
                            value={memberLoginData[name]}
                            onChange={setMemberLoginData}
                            {...props}
                            render={(ref, {defaultValue, ...inputProps}) => (
                                <Input
                                    {...inputProps}
                                    label={placeholder}
                                    value={defaultValue}
                                    message={errors[name]}
                                    isError={Boolean(errors[name])}
                                    name={name}
                                    ref={ref}
                                    isRequired
                                />
                            )}
                        />
                    </div>
                ))}
                <ButtonsBlock
                    errors={errors}
                    isLoading={isLoading}
                    isFullNameFrom={isFullNameFrom}
                    toggleFormOption={toggleFormOption}
                    clearMemberLoginData={clearMemberLoginData}
                />
            </form>
        </section>
    );
};

const mapDispatchToProps = {
    setToken: setTokenAction,
};

export const MemberLoginConnected = connect(null, mapDispatchToProps)(MemberLogin);
