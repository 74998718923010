import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {isUHCMember} from 'selectors/user/user';
import {ApplicationStoreType} from 'constants/typescript/types';
import {Icon} from 'components/__helpers/icon/icon';
import {toggleEligibilityWidget} from 'features/store/eligibilityWidget';
import {getEligibilityWidget, getFirstName} from 'selectors/eligibilityWidget/eligibilityWidget';

import {EligibilityWidgetType} from '../store';

import s from './eligibilityMessages.scss';
import {PlanMessageConnected} from './messages/planMessage/planMessage';
import {isUserLoggedIn} from '../../selectors/oauth2Data/oauth2Data';
import {isDiscountFulfilled} from '../../selectors/customer/customer';
import {MergeAccountMessageConnected} from './messages/mergeAccountMessage/mergeAccountMessage';

export type PropsType = {
    firstName: string;
    eligibilityWidget: EligibilityWidgetType;
    toggleWidget: () => void;
    isUHCMember: boolean;
    isShow: boolean;
    isLoggedIn: boolean;
    isDiscountLoaded: boolean;
};

export const EligibilityMessages: React.FC<PropsType> = ({
    firstName,
    eligibilityWidget,
    toggleWidget,
    isUHCMember,
    isShow,
    isLoggedIn,
    isDiscountLoaded,
}) => {
    const iconName = eligibilityWidget.isOpen ? 'closeCircle' : 'info';

    return (
        <>
            {isShow && isLoggedIn && isDiscountLoaded && (
                <article className={s.wrap}>
                    {eligibilityWidget.isOpen && (
                        <section className={s.eligibilityMessages}>
                            <div className={s.shadow}>
                                <header className={s.header}>
                                    <p>Hi {firstName}</p>
                                    <button
                                        className={s.closeButton}
                                        aria-label="Close eligibility widget"
                                        onClick={toggleWidget}
                                    >
                                        <Icon name="closeGray" />
                                    </button>
                                </header>
                                <div className={s.messagesWrap}>
                                    <PlanMessageConnected
                                        eligibilityWidget={eligibilityWidget}
                                        isUHCMember={isUHCMember}
                                    />
                                    {isUHCMember ? null : <MergeAccountMessageConnected />}
                                </div>
                            </div>
                        </section>
                    )}
                    <button
                        type="button"
                        className={classNames(s.infoButton, {[s.hover]: eligibilityWidget.isOpen})}
                        onClick={toggleWidget}
                        aria-label="Eligibility info"
                    >
                        <Icon name={iconName} />
                    </button>
                </article>
            )}
        </>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    firstName: getFirstName(store),
    eligibilityWidget: getEligibilityWidget(store),
    isUHCMember: isUHCMember(store),
    isShow: ['product', 'home', 'category', 'wizard', 'configureWizard'].includes(store.route.page),
    isLoggedIn: isUserLoggedIn(store),
    isDiscountLoaded: isDiscountFulfilled(store),
});

export const mapDispatchToProps = {
    toggleWidget: toggleEligibilityWidget,
};
export const EligibilityMessagesConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EligibilityMessages);
