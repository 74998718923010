import React from 'react';
import {connect} from 'react-redux';

import {ApplicationStoreType} from 'constants/typescript/types';
import {getCurrency} from 'selectors/customer/customer';
import {formatPrice, toPositive} from 'libs/convertPrice/formatPrice';

export type PropsType = {
    children: number | string;
    currency: ReturnType<typeof getCurrency>;
    withZeros?: boolean;
};

/**
 * Price formatter which get currency from store
 *
 * @param {PropsType} props - component props
 * @returns {string} - formatted price
 */
export const PriceComponent: React.FC<PropsType> = ({children, currency, withZeros = false}) => (
    <>
        {withZeros
            ? `${currency}${parseFloat(String(children)).toFixed(2)}`
            : formatPrice(currency, Number(toPositive(children)))}
    </>
);

export const mapStateToProps = (store: ApplicationStoreType) => ({
    currency: getCurrency(store),
});

export const Price = connect(mapStateToProps)(PriceComponent);
