// @flow
import {createActions, handleActions, combineActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';

import {FULFILLED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';
import {ENDPOINTS} from 'constants/endpoints';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import type {CategoryParamsType} from 'reducers/categoryItems';

import type {CategoryFiltersType} from './categoryFiltersType';

export const defaultState = {
    status: Status.Pending,
    filters: [],
    filtersIds: {},
};

export const {getCategoryFilters, getAllFilters} = createActions({
    GET_CATEGORY_FILTERS: (
        categoryId: number,
        {q, filters}: CategoryParamsType = {},
    ): ActionType<AxiosResponse> => {
        return {
            request: {
                method: 'get',
                baseURL: API_URL_ELASTICSEARCH,
                url: `${ENDPOINTS.CATEGORY_FILTERS}/${categoryId}`,
                params: {
                    ...filters,
                    q,
                },
            },
        };
    },
    GET_ALL_FILTERS: (): ActionType<AxiosResponse> => ({
        request: {
            method: 'get',
            baseURL: API_URL_ELASTICSEARCH,
            url: ENDPOINTS.CATEGORY_FILTERS,
        },
    }),
});

const getFilters = combineActions(getCategoryFilters, getAllFilters);

/**
 * Category filters reducer
 *
 * @returns {CategoryFiltersType} state - next state
 */
export const categoryFilters = handleActions(
    {
        [`${getCategoryFilters}${FULFILLED}`]: (
            state: CategoryFiltersType,
            {payload}: ActionType<typeof getCategoryFilters>,
        ): CategoryFiltersType => ({
            ...state,
            filters: payload.data.filter(({input}) => input.includes('select')),
            status: Status.Fulfilled,
        }),
        [`${getAllFilters}${FULFILLED}`]: (
            state: CategoryFiltersType,
            {payload}: ActionType<typeof getCategoryFilters>,
        ): CategoryFiltersType => ({
            ...state,
            filtersIds: payload.data,
            status: Status.Fulfilled,
        }),
        [getFilters]: (state: CategoryFiltersType): CategoryFiltersType => ({
            ...state,
            status: Status.Pending,
        }),
    },
    defaultState,
);
