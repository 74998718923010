import React, {FC, ReactElement, useState} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {Icon} from 'components/__helpers/icon/icon';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';

import s from './mergeAccountPopup.scss';
import {MergeAccountFormConnected} from './mergeAccountForm/mergeAccountForm';

export type PropsType = {
    closePopup: () => void;
    openPopup: (node: ReactElement) => void;
};

export const MergeAccountPopup: FC<PropsType> = ({openPopup, closePopup}) => {
    const [isDetailsOpen, setDetailsOpen] = useState(false);

    return (
        <section className={s.mergeAccountWrap}>
            <div className={s.mergeAccountDesc}>
                <Icon name="mergeAccountPopUp" />
                <p className={s.descTitle}>Merge your accounts</p>
                <p className={s.mergeAccountText}>
                    Do you have UnitedHealthcare Vision Benefits and a UHCGlasses.com account? Merge
                    them so you don’t miss out on all your member benefits!
                </p>
                <a
                    className={classNames(s.descLink, {[s.opened]: isDetailsOpen})}
                    onClick={() => setDetailsOpen(prevState => !prevState)}
                >
                    Details
                    <Icon name="arrow" />
                </a>
            </div>
            {isDetailsOpen ? (
                <div className={s.expandWrap}>
                    <p className={s.expandTitle}>After merging your accounts, you’ll be able to:</p>
                    <ul>
                        <li>Enjoy your vision benefits.</li>
                        <li>
                            Log in to the unified account with your email and password, or with your
                            UHC member ID and date of birth.
                        </li>
                        <li>
                            Enjoy access to all the info previously saved on your UHCGlasses.com
                            account.
                        </li>
                    </ul>
                </div>
            ) : null}
            <div className={s.mergeAccountConf}>
                <p className={s.mergeAccountText}>Do you want to merge your accounts?</p>
                <button
                    type="button"
                    className={s.mergeAccountButton}
                    onClick={() => {
                        openPopup(<MergeAccountFormConnected />);
                    }}
                >
                    Yes, please
                </button>
                <a className={s.mergeAccountClose} onClick={() => closePopup()}>
                    Close
                </a>
            </div>
        </section>
    );
};

export const mapDispatchToProps = {
    closePopup: () => setCmsPopupVisibility(false, null),
    openPopup: (content: ReactElement) =>
        setCmsPopupVisibility(true, content, {
            className: 'mergeAccountForm',
        }),
};

export const MergeAccountPopupConnected = connect(null, mapDispatchToProps)(MergeAccountPopup);
