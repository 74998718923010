import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';
import {ENDPOINTS} from 'constants/endpoints';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import {clearLocalCart, clearCart} from 'reducers/cart';

import {CartProductsType} from './cartProductsType';
import {RequestType} from '../../constants/typescript/types';

export const defaultState: CartProductsType = {
    status: Status.Idle,
    0: {
        id: 0,
        url: '',
        name: '',
        sku: '',
        children: [],
    },
};

export const getProductsForCart = createAction<number, number, RequestType>(
    'GET_PRODUCTS_FOR_CART',
    (itemId: number, productId: number) => ({
        request: {
            method: 'get',
            baseURL: API_URL_ELASTICSEARCH,
            url: `${ENDPOINTS.CART_PRODUCTS}/${productId}`,
        },
    }),
    itemId => itemId,
);
export const cartProducts = createReducer<CartProductsType>(
    {
        [`${getProductsForCart}${FULFILLED}`]: (
            state: CartProductsType,
            {data},
            {previousAction},
        ): CartProductsType => ({
            ...state,
            status: Status.Fulfilled,
            [previousAction.meta]: data,
        }),
        [`${getProductsForCart}`]: (state: CartProductsType): CartProductsType => ({
            ...state,
            status: Status.Pending,
        }),
        [`${clearLocalCart}`]: (): CartProductsType => defaultState,
        [`${clearCart}`]: (): CartProductsType => defaultState,
    },
    defaultState,
);
