import {createAction, createReducer} from 'redux-act';

export type PdErrorType = {
    right: string;
    left: string;
};

export type ErrorsType = {
    od: OculusPrescriptionType;
    os: OculusPrescriptionType;
    pd?: PdErrorType;
};

export type OculusPrescriptionType = {
    sph?: string;
    cyl?: string;
    axi: string;
    add: string;
};

export type ClearErrorType = {
    eye: 'os' | 'od';
    parameter: string;
};

export const initialState: ErrorsType = {
    od: {
        sph: '',
        cyl: '',
        axi: '',
        add: '',
    },
    os: {
        sph: '',
        cyl: '',
        axi: '',
        add: '',
    },
    pd: {
        right: '',
        left: '',
    },
};

export const setErrors = createAction<ErrorsType>('PRESCRIPTION_SET_ERRORS');
export const clearError = createAction<ClearErrorType>('CLEAR_ERROR');

export const errors = createReducer<ErrorsType>({}, initialState)
    .on(setErrors, (state: ErrorsType, payload: ErrorsType): ErrorsType => payload)
    .on(
        clearError,
        (state, {eye, parameter}: ClearErrorType): ErrorsType => ({
            ...state,
            [eye]: {
                ...state[eye],
                [parameter]: '',
            },
        }),
    );
