import React, {useState, useEffect} from 'react';
import {CtaButton} from '@optimaxdev/design-system-u';

import s from '../loginPopup.scss';

import {getTextForChangeMethodButton} from './helpers';
import {sendLoginTypeEvent} from './analytics';

type PropsType = {
    errors: {[k: string]: string};
    isLoading: boolean;
    isFullNameFrom: boolean;
    toggleFormOption: (isFullNameFrom: boolean) => void;
    clearMemberLoginData: () => void;
};

export const ButtonsBlock: React.FC<PropsType> = ({
    isLoading,
    isFullNameFrom,
    errors,
    toggleFormOption,
    clearMemberLoginData,
}) => {
    const [showError, setShowError] = useState(true);

    const handleChangeLoginType = (isFullNameFrom: boolean) => {
        toggleFormOption(!isFullNameFrom);
        sendLoginTypeEvent(isFullNameFrom);
        clearMemberLoginData();
        setShowError(false);
    };

    useEffect(() => {
        if (errors.response) {
            setShowError(true);
        }
    }, [errors.response]);

    return (
        <>
            <CtaButton type="submit" size="large" isLoading={isLoading} ariaLabel="Sign In">
                Access Benefits
            </CtaButton>
            {errors.response && showError && <p className={s.errorMessage}>{errors.response}</p>}
            <button
                className={s.forgot}
                type="button"
                onClick={() => handleChangeLoginType(isFullNameFrom)}
            >
                {getTextForChangeMethodButton(isFullNameFrom)}
            </button>
        </>
    );
};

export default ButtonsBlock;
