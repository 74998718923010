import FormData from 'form-data';

import {getCreditCardData} from 'selectors/card/card';
import {ApplicationStoreType} from 'constants/flow/flowTypes';
import {OrderType} from 'reducers/order';
import {OrderItemFullType} from 'reducers/ordersInfo';
import {getAffId} from 'libs/analytics/affiliate';

import {filterEmail} from '../../libs/utils/utils';
import {QuoteItemV2Type} from '../../reducers/cart/addToCart/addToCartType';
import {PRODUCT_TYPES_NEW} from '../../constants/productType';
import {createSelector} from 'reselect';
import {PrescriptionMethod} from '../../features/contacts/wizard/constants/wizard';

/**
 * Get current order data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {OrderType} - order data
 */
export const getOrderData = (store: ApplicationStoreType): OrderType => store.order;

/**
 * Get orders info data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {OrderItemFullType} - order data
 */
export const getCurrentOrder = (store: ApplicationStoreType): OrderItemFullType =>
    store.ordersInfo[store.order.orderId];

/**
 * Get selected shipping address id
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {number} selected id or default or first id in addresses list
 */
export const getSelectedShippingAddressId = (store: ApplicationStoreType): number => {
    if (store.user.addresses.length === 0) return 0;

    const isSelectedAddressExist = store.user.addresses.some(
        item => store.order.shippingAddress === item.id,
    );
    const isDefaultAddressExist = store.user.addresses.some(
        item => store.user.shippingAddressId === item.id,
    );
    const defaultAddress = isDefaultAddressExist
        ? store.user.shippingAddressId
        : store.user.addresses[0].id;

    return isSelectedAddressExist ? store.order.shippingAddress : defaultAddress;
};

/**
 * Get selected billing address id
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {number} selected id or default or first id in addresses list
 */
export const getSelectedBillingAddressId = (store: ApplicationStoreType): number => {
    if (store.user.addresses.length === 0) return 0;

    const isSelectedAddressExist = store.user.addresses.some(
        item => store.order.billingAddress === item.id,
    );
    const isDefaultAddressExist = store.user.addresses.some(
        item => store.user.billingAddressId === item.id,
    );
    const defaultAddress = isDefaultAddressExist
        ? store.user.billingAddressId
        : getSelectedShippingAddressId(store);

    return isSelectedAddressExist ? store.order.billingAddress : defaultAddress;
};

/**
 * Get selected billing and shipping address
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {FormData} FormData with selected billing, shipping address and customer form_key
 */
export const getOrderAddressesFormData = (store: ApplicationStoreType): FormData => {
    const orderAddresses = new FormData();
    orderAddresses.append('form_key', store.customer.form_key);
    orderAddresses.append('shipping[email]', filterEmail(store.user.email));
    orderAddresses.append('billing[email]', filterEmail(store.user.email));

    const addresses = {
        shipping: store.user.addresses.find(({id}) => getSelectedShippingAddressId(store) === id),
        billing: store.user.addresses.find(({id}) => getSelectedBillingAddressId(store) === id),
    };

    const dataMap = {
        firstname: '[firstname]',
        lastname: '[lastname]',
        street: '[street][]',
        city: '[city]',
        regionId: '[region_id]',
        region: '[region]',
        postcode: '[postcode]',
        countryCode: '[country_id]',
        telephone: '[telephone]',
        id: '[address_id]',
    };

    ['shipping', 'billing'].forEach((type: string) => {
        Object.keys(dataMap).forEach((key: string) => {
            orderAddresses.append(`${type}${dataMap[key]}`, addresses[type][key]);
        });
    });
    orderAddresses.append('affId', getAffId());
    return orderAddresses;
};

const paymentFields = {
    cardNumber: 'payment[cc_number]',
    cardMonth: 'payment[cc_exp_month]',
    cardYear: 'payment[cc_exp_year]',
    cardCVV: 'payment[cc_cid]',
    cardType: 'payment[cc_type]',
};

const paymentOsTokenField = 'payment[token]';

/**
 * Extend with payment os
 *
 * @param {ApplicationStoreType} store - application storage
 * @param {string} paymentData - payment data
 * @returns {string} - payment data extended with payment os
 */
function addPaymentOsPaymentData(store: ApplicationStoreType, paymentData: string): string {
    const {paymentMethod} = store.order;
    const {paymentOsToken} = getCreditCardData(store);
    let paymentDataWithPaymentOsData = paymentData;

    if (paymentMethod === 'ms_paymentsos') {
        paymentDataWithPaymentOsData = `${paymentData}&${paymentOsTokenField}=${paymentOsToken}`;
    }
    return paymentDataWithPaymentOsData;
}

/**
 * Get payment data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {string} - payment data
 */
export const getPaymentData = (store: ApplicationStoreType): string => {
    const {paymentMethod} = store.order;
    const {form_key: formKey} = store.customer;

    let paymentData = `form_key=${formKey}&payment[method]=${paymentMethod}`;

    if (paymentMethod === 'payeezy') {
        Object.keys(paymentFields).forEach((key: string) => {
            paymentData = `${paymentData}&${paymentFields[key]}=${store.creditCard[key]}`;
        });
    }
    return addPaymentOsPaymentData(store, paymentData);
};

/**
 * Get a map of categories related to sku product
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {object} - map of categories related to sku product
 */
export const getCategoryMap = (store: ApplicationStoreType): Record<string, string> =>
    store.order.categoryMap;

export const getCurrentOrderItems = (store: ApplicationStoreType): QuoteItemV2Type[] => {
    const currentOrder = getCurrentOrder(store);

    return currentOrder?.items || [];
};

export const getCurrentOrderLensesItems = (store: ApplicationStoreType): QuoteItemV2Type[] => {
    const orderItems = getCurrentOrderItems(store);

    return orderItems.filter(({product}) => product.type === PRODUCT_TYPES_NEW.CONTACT_LENSES);
};

export const getOrderProductsNames = (store: ApplicationStoreType) =>
    store.ordersInfo[store.order.orderId]
        ? store.ordersInfo[store.order.orderId].items.map(product => product.name)
        : [];

export const getLensesItemsWithPrescription = (store: ApplicationStoreType): QuoteItemV2Type[] => {
    return getCurrentOrderLensesItems(store);
};

export const getIsPrescriptionUploaded = createSelector(getLensesItemsWithPrescription, items =>
    items.some(item => item.prescription.method === PrescriptionMethod.PrescriptionUpload),
);

export const getIsPrescriptionSendLater = createSelector(getLensesItemsWithPrescription, items =>
    items.some(item => item.prescription.method === PrescriptionMethod.SendByEmailOrFax),
);

export const getIsPrescriptionFillItOnline = createSelector(getLensesItemsWithPrescription, items =>
    items.some(item => item.prescription.method === PrescriptionMethod.FillItOnline),
);
