import {REACTPV} from '@optimaxdev/analytics/desktop';
import {ComponentType, LazyExoticComponent} from 'react';
import {ConnectedComponent} from 'react-redux';

import {LINK_PAGE, LINK_MY_ACCOUNT} from 'constants/links';

import * as Lazy from './lazyComponents';
import {PAGE_PARAM2} from '../../../constants/analytics';

export const StaticRoutes: Array<{
    path: string;
    page: string;
    event?: string;
    page_type_param2?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: LazyExoticComponent<ComponentType | ConnectedComponent<any, any>>;
}> = [
    {
        path: LINK_PAGE.HOME,
        page: 'home',
        event: REACTPV.HOME_PAGE,
        component: Lazy.HomeConnected,
        page_type_param2: PAGE_PARAM2.HOME_PAGE,
    },
    {
        path: '/salesperson/result',
        page: 'search',
        event: REACTPV.CATEGORY,
        component: Lazy.CategoryConnected,
        page_type_param2: PAGE_PARAM2.CATEGORY,
    },
    {
        path: LINK_PAGE.CAREERS,
        page: 'careers',
        component: Lazy.Careers,
    },
    {
        path: LINK_PAGE.ABOUT_US,
        page: 'about-us',
        component: Lazy.AboutUs,
    },
    {
        path: LINK_PAGE.CONTACT_US,
        page: 'contact-us',
        component: Lazy.ContactUsConnected,
        page_type_param2: PAGE_PARAM2.CONTACT_US,
    },
    {
        path: LINK_PAGE.POLICY,
        page: 'privacy-policy',
        component: Lazy.Privacy,
    },
    {
        path: LINK_PAGE.TRY_ON_NOTICE,
        page: 'try-on-notice',
        component: Lazy.TryOnNotice,
    },
    {
        path: LINK_PAGE.TERMS,
        page: 'terms-of-use',
        component: Lazy.TermsOfUse,
    },
    {
        path: LINK_PAGE.ADDITIONAL_PURCHASE,
        page: 'additional-purchase',
        component: Lazy.AdditionalPurchase,
    },
    {
        path: LINK_PAGE.CCPA,
        page: 'ccpa',
        component: Lazy.CmsConnected,
    },
    {
        path: LINK_PAGE.DATA_SUBJECT_REQUEST,
        page: 'data-subject-request',
        component: Lazy.CmsConnected,
    },
    {
        path: LINK_PAGE.TRACKING,
        page: 'tracking',
        component: Lazy.TrackingConnected,
        page_type_param2: PAGE_PARAM2.ORDER_TRACKING,
    },
    {
        path: `${LINK_PAGE.TRACKING}/:id`,
        page: 'tracking',
        component: Lazy.TrackingConnected,
        page_type_param2: PAGE_PARAM2.ORDER_TRACKING,
    },
    {
        path: `${LINK_MY_ACCOUNT.PRESCRIPTIONS}/new`,
        page: 'customer-glasses-prescription-new',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: `${LINK_MY_ACCOUNT.PRESCRIPTIONS}/edit/id/:id`,
        page: 'customer-glasses-prescription-edit',
        event: REACTPV.MY_ACCOUNT,
        component: Lazy.MyAccountConnected,
        page_type_param2: PAGE_PARAM2.MY_ACCOUNT,
    },
    {
        path: LINK_MY_ACCOUNT.RESET_PASSWORD,
        page: 'home',
        event: REACTPV.HOME_PAGE,
        component: Lazy.HomeConnected,
        page_type_param2: PAGE_PARAM2.HOME_PAGE,
    },
    {
        path: LINK_PAGE.PAGE_404,
        page: '404',
        component: Lazy.Page404Component,
    },
    {
        path: LINK_PAGE.ACCESSIBILITY,
        page: 'accessibility',
        component: Lazy.AccessibilityConnected,
    },
    {
        path: LINK_PAGE.FAQ,
        page: 'faq',
        component: Lazy.FaqConnected,
    },
    {
        path: LINK_PAGE.RMA,
        page: 'rma',
        component: Lazy.RmaConnected,
        page_type_param2: PAGE_PARAM2.RMA,
    },
    {
        path: LINK_PAGE.CUSTOMER_SUPPORT_LOGIN,
        page: 'customer-support-login',
        component: Lazy.CustomerSupportLogin,
    },
];
