import {SagaIterator} from 'redux-saga';
import {select, takeEvery, put, putResolve, call} from 'redux-saga/effects';
import {pushDataLayer} from '@optimaxdev/analytics/desktop';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {MAX_AVAIL_PD, MIN_AVAIL_PD, POPUP_STEP} from 'constants/tryOn';
import {
    getFaceSliderPhotos,
    getUploadType,
    getTryOnData,
    getProductData,
} from 'selectors/tryOn/tryOn';
import {
    getImagesTryOn,
    setCurrentImageTryOn,
    sendDataTryon,
    activeStepTryOn,
    removeUserPhoto,
    uploadImageTryon,
} from 'reducers/tryOn';
import {getCategoryItems} from 'reducers/categoryItems';
import {getCategoryItemsData} from 'selectors/category/category';
import {openTryOn} from 'reducers/tryOn/tryOn';
import {getSearchParams} from 'libs/url';
import {oauth2Selector} from 'selectors/oauth2Data/oauth2Data';
import {getPageName} from 'selectors/page/getPageData';

/**
 * Set current image for tryon slider
 */
export function* setSliderImage(): SagaIterator {
    const faceSliderPhotos = yield select(getFaceSliderPhotos);
    if (faceSliderPhotos && faceSliderPhotos[0]) {
        yield put(setCurrentImageTryOn(faceSliderPhotos[0]));
    }
}

/**
 * Update list images and close adjust window
 */
export function* updateListImages(): SagaIterator {
    yield putResolve(getImagesTryOn());
    yield put(activeStepTryOn(POPUP_STEP.INITIAL));
}
/**
 *
 */
export function* sendTryOnAnalyticsEvent(): SagaIterator {
    const uploadType = yield select(getUploadType);
    const page = yield select(getPageName);
    if (page === 'category')
        yield call(pushDataLayer, {
            event: 'CategoryInteraction',
            eventAction: 'Try On',
            eventCategory: 'Category - D',
            eventLabel: `Try On You - ${
                uploadType === POPUP_STEP.UPLOAD_MENU ? 'Upload Photo' : 'Use Webcam'
            } - Success`,
        });
    else
        yield call(pushDataLayer, {
            event: 'PDPInteraction',
            eventAction: 'Product',
            eventCategory: 'PDP - D',
            eventLabel: `Try On You - ${
                uploadType === POPUP_STEP.UPLOAD_MENU ? 'Upload Photo' : 'Use Webcam'
            } - Success`,
        });
}
/**
 * Open tryon with default product on category page if user logged in and redirected with url key param.
 */
export function* openCategoryTryon(): SagaIterator {
    const {access_token: accessToken} = yield select(oauth2Selector);
    const items = yield select(getCategoryItemsData);
    const {openTryon} = yield call(getSearchParams);

    if (accessToken && openTryon && items.products.length > 0) {
        yield put(openTryOn(items.products[0].id, items.products[0].children[0].id));
    }
}

/**
 * Send tryOn data to adjust request
 */
export function* adjustSaveTryOn(): SagaIterator {
    const tryOn = yield select(getTryOnData);
    const productData = yield select(getProductData);
    const pd = Math.min(MAX_AVAIL_PD, Math.max(MIN_AVAIL_PD, tryOn.adjustValues.pd));

    yield put(sendDataTryon(tryOn, pd, productData));
}

/**
 * Begin of saga
 */
export function* tryOnSaga(): SagaIterator {
    yield takeEvery(`${uploadImageTryon}${FULFILLED}`, adjustSaveTryOn);
    yield takeEvery(`${getImagesTryOn}${FULFILLED}`, setSliderImage);
    yield takeEvery(
        [
            `${sendDataTryon}${FULFILLED}`,
            `${removeUserPhoto}${REJECTED}`,
            `${removeUserPhoto}${FULFILLED}`,
        ],
        updateListImages,
    );
    yield takeEvery(`${sendDataTryon}${FULFILLED}`, sendTryOnAnalyticsEvent);
    yield takeEvery(`${getCategoryItems}${FULFILLED}`, openCategoryTryon);
}
