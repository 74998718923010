import {propOr} from 'ramda';
import {createReducer} from 'redux-act';
import {WizardOptionType, WizardTreeType, WizardValueType, getUsages} from '@optimaxdev/wizard-ms';
import {AxiosResponse} from 'axios';
import {createSelector} from 'reselect';

import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {CustomerConfigurationType} from 'features/wizard/store/reducers/customerConfiguration/customerConfigurationType';
// @TODO change import path to constants/typescript/types
// currently it causes a lot of problem :(
import {ApplicationStoreType} from 'constants/flow/flowTypes';
import {fetchWizard} from 'features/wizard/store/reducers/wizardData/wizardData';
import {FULFILLED} from 'constants/actionSuffix';
import {Maybe} from 'constants/typescript/types';
import {SINGLE_VISION} from 'constants/wizard/wizard';
import {
    DISTANCE,
    NOPRESCRIPTION,
    PROGRESSIVE_OFFICE_NEAR,
    PROGRESSIVES,
} from 'features/wizard/constants/usages';
export type UsageItemType = WizardValueType & {parentNodeId: number; tooltipText?: string};
export type UsageNodeType = WizardOptionType & {error?: string};

export const initialState: UsageNodeType = {
    sort: 1,
    type: 'option',
    alias: 'usage',
    title: 'What do you use your glasses for?',
    required: 1,
    node: 0,
    items: [],
    error: '',
};

/**
 * Usages reducer
 */
export const usages = createReducer<WizardOptionType>({}, initialState)
    /**
     * Subscribe actions
     * https://www.npmjs.com/package/redux-act#typescript
     */
    .on(
        `${fetchWizard}${FULFILLED}`,
        (state: UsageNodeType, {data}: AxiosResponse<{tree: WizardTreeType}>): UsageNodeType => {
            const usageNode =
                data.tree.items.find(options => options.alias === 'usage') || initialState;
            return {
                ...usageNode,
                items: getUsages(data.tree),
            };
        },
    );
/**
 * Provides the usage node
 *
 * @param {ApplicationStoreType} store - store
 * @returns {UsageNodeType} node
 */
export const getUsageNode = (store: ApplicationStoreType): UsageNodeType => store.wizardMs.usages;

/**
 * Provides the usage items
 *
 * @param {ApplicationStoreType} store - store
 * @returns {UsageItemType[]} node items
 */
export const getUsagesItems = (store: ApplicationStoreType): UsageItemType[] =>
    store.wizardMs.usages.items;

/**
 * Provides the usage items for new wizard
 *
 * @param {ApplicationStoreType} store - store
 * @returns {UsageItemType[]} node items
 */
export const getSortedUsagesItems = (store: ApplicationStoreType): UsageItemType[] =>
    [...store.wizardMs.usages.items].sort((a, b) => {
        return a.sort - b.sort;
    });

/**
 * Provides selected usage node
 */
export const getActiveUsage = createSelector<
    ApplicationStoreType,
    UsageNodeType,
    CustomerConfigurationType,
    Maybe<UsageItemType>
>(getUsageNode, getCustomerConfiguration, (usageNode, configuration) => {
    return (
        (usageNode.items &&
            (usageNode.items.find(
                usageItem => usageItem.node === configuration.options[usageNode.node],
            ) as UsageItemType)) ||
        null
    );
});
/**
 * Provides selected usage title
 */
export const getActiveUsageName = createSelector<
    ApplicationStoreType,
    Maybe<UsageItemType>,
    string
>(getActiveUsage, usageNode => {
    return usageNode?.title === 'Distance & Reading'
        ? `${usageNode.title}: ${usageNode.usageName}`
        : usageNode?.title || '';
});
/**
 * Get active usage sku
 */
export const getActiveUsageSku = createSelector<ApplicationStoreType, UsageItemType | null, string>(
    getActiveUsage,
    propOr('', 'sku'),
);

/**
 * if active usage is single vision it returns boolean true/false
 */
export const isUsageSingleVision = createSelector(
    getActiveUsageSku,
    activeUsageSku => activeUsageSku === DISTANCE,
);

/**
 * if active usage is fashion it returns boolean true/false
 */
export const isFashionUsage = createSelector(
    getActiveUsageSku,
    activeUsageSku => activeUsageSku === NOPRESCRIPTION,
);

/**
 * Return status "single vision is active"
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - Status "single vision is active"
 */
export const isSingleVisionActive = createSelector(
    getActiveUsage,
    (item): boolean => item?.sku === SINGLE_VISION,
);

export const getProgressiveUsages = createSelector(getUsagesItems, items => {
    return items.filter(item => [PROGRESSIVE_OFFICE_NEAR, PROGRESSIVES].includes(item.sku));
});
