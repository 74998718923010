// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import {assocPath, mergeDeepRight} from 'ramda';

import {FULFILLED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import type {
    PrescriptionSchemeType,
    SchemeType,
    SchemeWithoutValueType,
} from './prescriptionSchemeType';

export const defaultState = {
    id: 0,
    name: '',
    comment: '',
    isPrism: false,
    pd: {
        singular: {
            value: '62',
        },
        right: {
            value: 'None',
            isError: false,
        },
        left: {
            value: 'None',
            isError: false,
        },
        near: {
            value: 'None',
        },
        isTwoPd: {
            value: false,
        },
    },
    od: {
        sph: {
            value: 'None',
            isDisable: false,
        },
        cyl: {
            value: 'None',
            isDisable: false,
        },
        axi: {
            value: 'None',
            isDisable: true,
        },
        add: {
            value: 'None',
            isDisable: false,
        },
    },
    os: {
        sph: {
            value: 'None',
            isDisable: false,
        },
        cyl: {
            value: 'None',
            isDisable: false,
        },
        axi: {
            value: 'None',
            isDisable: true,
        },
        add: {
            value: 'None',
            isDisable: false,
        },
    },
};

export const {
    wizard: {
        updateScheme,
        updateField,
        getPrescriptions,
        savePrescription,
        updatePrescription,
        clearPrescription,
        resetPrescription,
        setPrismSelection,
    },
} = createActions({
    WIZARD: {
        UPDATE_SCHEME: (scheme: SchemeType) => scheme,
        UPDATE_FIELD: (updatedField: {value: string, name: string}) => updatedField,
        GET_PRESCRIPTIONS: () => ({
            request: {
                method: 'get',
                headers: {Authorization: ''},
                url: `/backend/${ENDPOINTS.WIZARD.GET_PRESCRIPTIONS}`,
            },
        }),
        SAVE_PRESCRIPTION: (prescription: SchemeWithoutValueType) => ({
            request: {
                method: 'post',
                headers: {Authorization: ''},
                url: `/backend/${ENDPOINTS.WIZARD.SAVE_PRESCRIPTION}`,
                data: prescription,
            },
        }),
        UPDATE_PRESCRIPTION: (id: number, prescription: PrescriptionSchemeType) => ({
            request: {
                method: 'put',
                headers: {Authorization: ''},
                url: `/backend/${ENDPOINTS.WIZARD.GET_PRESCRIPTIONS}/${id}`,
                data: prescription,
            },
        }),
        CLEAR_PRESCRIPTION: () => {},
        RESET_PRESCRIPTION: () => {},
        SET_PRISM_SELECTION: (selection: boolean): boolean => selection,
    },
});

/**
 * Prescriptions scheme reducer
 *
 * @returns {PrescriptionSchemeType} state - New state
 */
export const prescriptionsScheme = handleActions(
    {
        [updateScheme]: (
            state: PrescriptionSchemeType,
            {payload}: ActionType<SchemeType>,
        ): PrescriptionSchemeType => ({
            ...state,
            ...mergeDeepRight(state, payload),
        }),
        [updateField]: (
            state: SchemeType,
            {payload: {name, value}}: ActionType<typeof updateScheme>,
        ): SchemeType => ({
            ...assocPath(name.split('.'), value, state),
        }),
        [`${getPrescriptions}${FULFILLED}`]: (
            state: PrescriptionSchemeType,
            {payload}: ActionType<>,
        ): PrescriptionSchemeType => ({
            ...state,
            saved: payload.data,
        }),
        [clearPrescription]: () => defaultState,
        [resetPrescription]: () => defaultState,
        [setPrismSelection]: (state: SchemeType, {payload: selection}): SchemeType => ({
            ...state,
            isPrism: selection,
        }),
    },
    defaultState,
);
