import {createSelector} from 'reselect';
import {propEq, propOr, whereEq} from 'ramda';
import {WizardValueType} from '@optimaxdev/wizard-ms';

import {ApplicationStoreType} from 'constants/flow/flowTypes';
import {
    SUNGLASSES_GROUP_IDS,
    SUNGLASSES_COLORS_TITLES,
} from 'features/wizard/store/constants/upsells';
import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {FormattedColorType, ColorsType} from 'features/wizard/store/reducers/upsells/upsells';
import {Maybe} from 'constants/typescript/types';
import {selectedEyeglassesColor} from 'features/wizard/store/selectors/upsells/upsells';
import {getActiveProduct, isActiveProductSunglasses} from 'selectors/product/product';
import {ProductChildrenType} from 'reducers/product';

/**
 * Take colors field from upsells
 *
 * @param {ApplicationStoreType} store - Current store
 * @returns {ColorsType} - ColorType
 */
export const getColors = (store: ApplicationStoreType): ColorsType => store.wizardMs.upsells.colors;
/**
 * it gets colors and does formatting for UI
 */
export const getSunglassesFormattedColors = createSelector<
    ApplicationStoreType,
    ColorsType,
    Maybe<FormattedColorType>
>(getColors, upsellColors => {
    if (upsellColors && 'items' in upsellColors) {
        const {items} = upsellColors;
        const colors = (element: string) =>
            items.filter(item => propOr('', 'groupId', item) === element);
        return {
            title: upsellColors.title,
            items: SUNGLASSES_GROUP_IDS.map(element => ({
                title: SUNGLASSES_COLORS_TITLES[element].title,
                description: SUNGLASSES_COLORS_TITLES[element].description,
                colors: colors(element),
            })),
            parentNodeId: upsellColors.parentNodeId,
        } as FormattedColorType;
    }

    return null;
});

/**
 * Getting parentNodeId for formatted color
 */
export const getSunglassesColorParentNodeId = createSelector(
    getSunglassesFormattedColors,
    colors => {
        if (colors) {
            return 'parentNodeId' in colors ? colors.parentNodeId : null;
        }
        return null;
    },
);

/**
 * Returns the default color tint NODE id, because backend now return to us sku instead of id((
 */
export const getDefaultTintId = createSelector(
    getColors,
    getActiveProduct,
    (colors, {defaultTintSku}) => {
        if (!colors?.items) return null;
        const defaultTint = colors.items.find(
            item => (item as WizardValueType).sku === defaultTintSku,
        );
        return defaultTint?.node || null;
    },
);

/**
 * Picks ID of default lens tint
 */
export const getDefaultLensTint = createSelector(
    getColors,
    getDefaultTintId,
    (colors, defaultTintId: Maybe<number>): Maybe<number> => {
        if (!colors?.items) return null;

        if (defaultTintId !== null) {
            const defaultOption = colors.items.find(propEq('node', defaultTintId));

            if (defaultOption && defaultOption.enabled) {
                return defaultOption.node;
            }
        }

        const firstAvailableOption = colors.items.find(whereEq({type: 'value', enabled: true}));

        return (propOr(null, 'node', firstAvailableOption) as unknown) as number;
    },
);

/**
 * Selected color which chooses the user
 */
export const selectedColor = createSelector(
    getCustomerConfiguration,
    getColors,
    getDefaultLensTint,
    ({options}, upsellColors, defaultColor) => {
        if (upsellColors && upsellColors.items) {
            const item =
                upsellColors.items.find(({node}) => options[upsellColors.parentNodeId] === node) ||
                null;

            if (options[upsellColors.parentNodeId] === defaultColor) {
                return {
                    ...item,
                    price: 0,
                    finalPrice: 0,
                } as WizardValueType;
            }

            return item;
        }

        return null;
    },
);

/**
 * Get the Default tint color for glasses
 */
export const getDefaultTintColor = createSelector<
    ApplicationStoreType,
    ProductChildrenType,
    Maybe<FormattedColorType>,
    Maybe<number>
>(getActiveProduct, getSunglassesFormattedColors, ({defaultTintSku}, colors) => {
    if (defaultTintSku && colors) {
        const {
            items: [{colors: colorsList}],
        } = colors;
        const defaultTintColor = colorsList.filter(color => color.sku === defaultTintSku);
        return defaultTintColor[0]?.node || colorsList[0]?.node || null;
    }
    if (colors) {
        const {
            items: [
                {
                    colors: [{node: firstColorNode}],
                },
            ],
        } = colors as FormattedColorType;
        return firstColorNode;
    }
    return null;
});

/**
 * if we on eyeglasses we need to return color from it
 * if we on sunglasses we need to return color from it
 */
export const getSelectedColorSunglassesOrEyeglasses = createSelector(
    selectedColor,
    selectedEyeglassesColor,
    isActiveProductSunglasses,
    (selectedSunglassesColor, selectedEyeglassesColor, isSunglassesFrame) => {
        if (isSunglassesFrame) {
            return selectedSunglassesColor;
        }

        return selectedEyeglassesColor;
    },
);
