import {createAction, createReducer} from 'redux-act';
import {MID} from '@optimaxdev/wizard-ms';
import {equals, not, or} from 'ramda';
import {AxiosResponse} from 'axios';

import {
    FILL_IT_ONLINE_TAB,
    UPLOAD_OR_SEND_LATER_TAB,
    FILL_IT_ONLINE,
    PRESCRIPTION_SEND_EMAIL_OR_FAX,
    PRESCRIPTION_UPLOAD,
} from 'constants/wizard/wizard';

import {FULFILLED} from 'constants/actionSuffix';
import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';
import {fetchQuoteItemV2} from '../configure/configure';
import {
    ValueType,
    OculusPrescriptionType,
    PrescriptionType,
    PrescriptionMethodType,
} from './prescriptionTypes';
import {initialState, NONE} from './constants';

/**
 * Returns active tab for configure page based on selected method
 *
 * @param {number} method - prescription method
 * @returns {number} tab
 */
export const getActiveTab = (method: number): number => {
    if (method === PRESCRIPTION_SEND_EMAIL_OR_FAX || method === PRESCRIPTION_UPLOAD) {
        return UPLOAD_OR_SEND_LATER_TAB;
    }
    return FILL_IT_ONLINE_TAB;
};

export const validatePrescription = createAction('VALIDATE_PRESCRIPTION');
export const setStrength = createAction<string>('SET_STRENGTH', payload => payload);

/**
 * List of actions
 */
export const changePrescriptionMethod = createAction<number>('WIZARD_CHANGE_PRESCRIPTION_METHOD');
/**
 * Action to change the prescription method in cases where we need to preserve prescription values
 */
export const changePrescriptionMethodOnly = createAction<PrescriptionMethodType>(
    'WIZARD_CHANGE_PRESCRIPTION_METHOD_ONLY',
);
export const setPrescription = createAction<
    {
        name: string;
        value: ValueType;
    },
    {field: string; value: string}
>(
    'WIZARD_SET_PRESCRIPTION',
    payload => payload,
    (payload, meta) => meta,
);

export const setActiveTab = createAction<number>('WIZARD_SET_ACTIVE_TAB');

export const setSavedPrescription = createAction<PrescriptionType>('WIZARD_SET_SAVED_PRESCRIPTION');

export const prescription = createReducer({}, initialState)
    .on(setStrength, (state, payload) => ({
        ...state,
        strength: payload,
    }))
    .on(setSavedPrescription, (state, payload) => ({...state, ...payload}))
    .on(setPrescription, (state, {name, value}: {name: string; value: ValueType}) => {
        const isOdOrOs = name === 'os' || name === 'od';

        return {
            ...state,
            [name]: isOdOrOs
                ? {
                      ...(value as OculusPrescriptionType),
                      axi: MID.includes((value as OculusPrescriptionType).cyl)
                          ? NONE
                          : (value as OculusPrescriptionType).axi,
                  }
                : value,
        };
    })
    .on(setActiveTab, (state, payload) => ({
        ...state,
        activeTab: payload,
        method: payload === FILL_IT_ONLINE_TAB ? FILL_IT_ONLINE : PRESCRIPTION_SEND_EMAIL_OR_FAX,
        file: initialState.file,
    }))
    .on(changePrescriptionMethod, (state, payload) => ({
        ...state,
        method: payload,
        file: initialState.file,
        fileName: initialState.fileName,
        name: initialState.name,
        link: initialState.link,
    }))
    .on(changePrescriptionMethodOnly, (state, payload) => ({
        ...state,
        method: payload,
    }))
    .on(
        `${fetchQuoteItemV2}${FULFILLED}`,
        (state, {data: {prescription}}: AxiosResponse<QuoteItemV2Type>) => {
            if (!prescription) return state;
            const fileLink = (prescription.link || '').split('/');
            return {
                ...state,
                ...prescription,
                ...(prescription.pd
                    ? {
                          pd: {
                              singular: prescription.pd.singular,
                              right: equals(prescription.pd.right, NONE)
                                  ? initialState.pd.right
                                  : prescription.pd.right,
                              left: equals(prescription.pd.left, NONE)
                                  ? initialState.pd.left
                                  : prescription.pd.left,
                              near: prescription.pd.near,
                          },
                      }
                    : {}),

                ...(prescription.pd
                    ? {
                          isMultiPD: or(
                              not(equals(prescription.pd.right, NONE)),
                              not(equals(prescription.pd.left, NONE)),
                          ),
                      }
                    : {}),
                file: prescription.link || '',
                fileName: fileLink[fileLink.length - 1],
                activeTab: getActiveTab(prescription.method),
            };
        },
    );
