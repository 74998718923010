import {UhcLoginFormParams} from 'features/loginPopup/api/uhc/uhcType';
import {getFormattedBirthday} from 'features/loginPopup/api/uhc/uhc';
import {axiosClient} from 'libs/configureStore';
import {ENDPOINTS} from 'constants/endpoints';
import {API_URL_CUSTOMER} from 'constants/api';
import {CLIENT_ID_UHC} from 'constants/clientId';
import {Oauth2Type} from 'reducers/oauth2';

type RefreshType = {
    refreshToken: string;
};

type MergeResponseType = Oauth2Type & {
    code: number;
    message: string;
};

/**
 * Log In UHC user and response result
 *
 * @param {UhcLoginFormParams} formData - value of form's inputs (similar uhc login form)
 * @returns {Promise<ResponseType>} result response not specified
 */
export const mergeAcc = async ({
    id,
    birthday,
    first_name,
    last_name,
    zip_code,
    refreshToken,
}: UhcLoginFormParams & RefreshType): Promise<MergeResponseType> => {
    await axiosClient.post(
        ENDPOINTS.MERGE_ACCOUNT,
        {
            id,
            birthday: birthday ? getFormattedBirthday(birthday) : '',
            first_name,
            last_name,
            zip_code,
        },
        {
            headers: {Authorization: ''},
        },
    );
    const {data} = await axiosClient('token', {
        method: 'POST',
        baseURL: API_URL_CUSTOMER,
        headers: {'Content-Type': 'application/x-www-form-urlencoded', Authorization: ''},
        data: `grant_type=refresh_token&client_id=${CLIENT_ID_UHC}&refresh_token=${refreshToken}`,
    });

    return data;
};
