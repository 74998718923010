import {PrescriptionFixture} from 'features/wizard/store/reducers/prescription/prescriptionFixture';

import {QuoteItemV2Type, AddToCartType} from './addToCartType';

export const AddToCartFixtureSendLater: AddToCartType = {
    productId: 1,
    options: {7: 6},
    prescription: {method: 1},
    coupon: '',
    quantity: 1,
};

export const UpdateToCartFixtureSendLater: AddToCartType = {
    productId: 1,
    quoteItemId: 100,
    options: {7: 6},
    prescription: {method: 1},
    coupon: '',
    quantity: 1,
};

export const AddToCartNonRX = {
    productId: 2,
    quantity: 1,
};

export const QuoteItemV2: QuoteItemV2Type = {
    id: 3516,
    name: 'Muse X Hilary Duff Clara Wine w/Red',
    sku: '24-000040-Single-Vision-svregpak-Gold-Index1.5-HMC',
    qty: 1,
    total: 147,
    totalFinalPrice: 125.4,
    isAppliedBenefits: false,
    isEligibleForApplyBenefits: false,
    discountedPrice: 99,
    product: {
        id: 15245,
        name: 'Muse x Hilary Duff Clara Wine w/Red',
        sku: '24-000040',
        image:
            '/media/catalog/product/cache/thumbnail/1200x600/9df78eab33525d08d6e5fb8d27136e95/0/0/000040_f_1.jpg',
        qty: 597,
        isInStock: true,
        wizardId: 11,
        color: 'Red / Wine',
        size: 'Medium',
        price: 108,
        finalPrice: 108,
        badge: {id: 127, title: 'Premium Frames'},
        type: '',
        urlPath: '',
        brand: {
            image: 'brand/Muse_x_Hilary_Duff_1_image.png',
            label: 'Muse x Hilary Duff',
            url: '/hilaryduff',
        },
    },
    glassesType: 0,
    productAsHTO: false,
    active: true,
    prescription: {...PrescriptionFixture, file: 'fileSrc', type: 'online'},
    options: {
        '7': {
            finalPrice: 0,
            id: 6,
            sku: 'Single-Vision',
            title: 'Single vision',
            price: 0,
            description: 'description',
            option: {
                sort: 1,
                alias: 'usage',
                title: 'What do you use your glasses for?',
                id: 7,
            },
        },
        '219': {
            finalPrice: 39,
            id: 246,
            sku: 'svregpak-Gold-Index1.5-HMC',
            title: 'Gold',
            price: 39,
            items: [
                {sort: 1, title: '1.5 index ClearViz©️ Lenses'}, // not full info about wizard option item
                {sort: 2, title: 'Anti-scratch coating'},
                {sort: 3, title: '100% UV-Block coating'},
                {sort: 4, title: 'Anti-reflective coating'},
            ],
            option: {
                alias: 'package',
                id: 219,
                sort: 100,
                title: 'Lens Package',
            },
            description:
                '1.59 Index high impact resistant safety lenses, Scratch Resistant Coating, UV Protective Coating, Anti-Reflective Coating',
        },
    },
    planId: 5,
};
