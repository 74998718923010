import {createAction} from 'redux-act';

import {ENDPOINTS} from 'constants/endpoints';
import {RequestType} from 'constants/typescript/types';
import {PrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {API_URL_CUSTOMER} from 'constants/api';
import {PrescriptionAdapterV2Type} from 'sagas/prescription/prescriptionMappers';

export type SavedPrescriptionsType = {
    status: '' | 'pending' | 'fulfilled' | 'rejected';
    prescriptions: PrescriptionType[];
};

export const initialState: SavedPrescriptionsType = {
    status: '',
    prescriptions: [],
};

export const fetchSavedCustomerPrescriptionsMS = createAction<RequestType>(
    'FETCH_SAVED_CUSTOMER_PRESCRIPTIONS_MS',
    () => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            baseURL: API_URL_CUSTOMER,
            url: ENDPOINTS.PRESCRIPTION,
        },
    }),
);

export const saveCustomerPrescriptionMS = createAction<PrescriptionAdapterV2Type, RequestType>(
    'SAVE_CUSTOMER_PRESCRIPTION_MS',
    (prescription: PrescriptionAdapterV2Type) => ({
        request: {
            method: 'POST',
            headers: {Authorization: ''},
            baseURL: API_URL_CUSTOMER,
            url: ENDPOINTS.PRESCRIPTION,
            data: prescription,
        },
    }),
);

export const updateCustomerPrescriptionMS = createAction<PrescriptionAdapterV2Type, RequestType>(
    'UPDATE_CUSTOMER_PRESCRIPTION_MS',
    (prescription: PrescriptionAdapterV2Type) => ({
        request: {
            method: 'PUT',
            headers: {Authorization: ''},
            baseURL: API_URL_CUSTOMER,
            url: `${ENDPOINTS.PRESCRIPTION}/${prescription.id}`,
            data: prescription,
        },
    }),
);

export const startSavePrescriptionSagaMS = createAction<{
    prescription: PrescriptionType;
    isUpdate: boolean;
}>(
    'START_SAVE_CUSTOMER_PRESCRIPTION_SAGA_MS',
    ({prescription, isUpdate}: {prescription: PrescriptionType; isUpdate: boolean}) => ({
        prescription,
        isUpdate,
    }),
);

export const fetchSavedCustomerPrescriptions = createAction<RequestType>(
    'FETCH_SAVED_CUSTOMER_PRESCRIPTIONS',
    () => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.WIZARD.SAVE_PRESCRIPTION}`,
        },
    }),
);

export const saveCustomerPrescription = createAction<PrescriptionType, RequestType>(
    'SAVE_CUSTOMER_PRESCRIPTION',
    (prescription: PrescriptionType) => ({
        request: {
            method: 'POST',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.WIZARD.SAVE_PRESCRIPTION}`,
            data: prescription,
        },
    }),
);

export const startSavePrescriptionSaga = createAction<{
    prescription: PrescriptionType;
    isUpdate: boolean;
}>(
    'START_SAVE_CUSTOMER_PRESCRIPTION_SAGA',
    ({prescription, isUpdate}: {prescription: PrescriptionType; isUpdate: boolean}) => ({
        prescription,
        isUpdate,
    }),
);

export const updateCustomerPrescription = createAction<PrescriptionType, RequestType>(
    'UPDATE_CUSTOMER_PRESCRIPTION',
    (prescription: PrescriptionType) => ({
        request: {
            method: 'PUT',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.WIZARD.GET_PRESCRIPTIONS}/${prescription.id}`,
            data: prescription,
        },
    }),
);
