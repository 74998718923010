import React, {useState, useRef, useEffect, ChangeEvent, RefObject} from 'react';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {Tooltip} from '@optimaxdev/design-system-u';

import {getQuickSearchResults} from 'libs/quickSearch/quickSearch';
import {useOnClickOutside} from 'libs/hooks/useOnClickOutside';
import {Icon} from 'components/__helpers/icon/icon';
import {ApplicationStoreType, SearchItemType} from 'constants/typescript/types';
import {LINK_SEARCH} from 'constants/links';
import {SEARCH_CATEGORY_ID} from 'constants/category';
import {PageType, setPage} from 'reducers/route';
import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';

import {SearchResults} from './searchResults/searchResults';
import s from './searchBox.scss';

/**
 * Side effect to query search results from celebros
 *
 * @param {string} keyword - word to search
 * @returns {Array<SuggestionType>} - data
 */
const useSearchResultsFromCelebros = (keyword: string): Array<SearchItemType> => {
    const [searchedItems, setSearchItems] = useState<Array<SearchItemType>>([]);
    const {mutate} = useMutation<Array<SearchItemType>, AxiosError>(
        () => getQuickSearchResults(keyword),
        {
            onSuccess: (data: Array<SearchItemType>) => {
                setSearchItems(data);
            },
        },
    );

    useEffect(() => {
        if (keyword.length > 0) {
            mutate();
        }
    }, [keyword, mutate]);

    return searchedItems;
};

/**
 * Provides the active status for popover content
 *
 * @param {RefObject<HTMLDivElement>} searchForm - ref to container
 * @returns {useState} - state
 */
export const useActiveState = (
    searchForm: RefObject<HTMLDivElement>,
): [boolean, (setActive: boolean) => void] => {
    const [isActive, setIsActive] = React.useState(false);
    // TODO: Test those hooks
    /* istanbul ignore next */
    useOnClickOutside(searchForm, () => setIsActive(false));

    /* istanbul ignore next */
    const setActiveFalse = () => {
        /* istanbul ignore next */
        setIsActive(false);
    };

    useEffect(() => {
        document.addEventListener('scroll', setActiveFalse);

        return () => {
            document.removeEventListener('scroll', setActiveFalse);
        };
    }, []);

    return [isActive, setIsActive];
};

const getPlaceholderText = () => {
    if (
        matchMedia('(max-width: 1028px)').matches &&
        matchMedia('(max-width: 1028px)').media === '(max-width: 1028px)'
    ) {
        return 'Search...';
    } else if (
        matchMedia('(max-width: 1280px)').matches &&
        matchMedia('(min-width: 1028px)').matches
    ) {
        return 'Searching...';
    }
    return 'I’m Searching For...';
};

export type PropsType = {
    setCategoryPage: (page: PageType) => void;
    page: string;
    isLoggedIn: boolean;
};

/**
 * Provides the input field in main header
 *
 * @param {PropsType} props props
 * @returns {React.FC} react component
 */
export const SearchBox: React.FC<PropsType> = ({setCategoryPage, page, isLoggedIn}) => {
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');
    const searchForm = useRef<HTMLDivElement>(null);
    const searchedItems = useSearchResultsFromCelebros(keyword);
    const [isActive, setIsActive] = useActiveState(searchForm);

    /**
     * Handles click on search suggestion keyword
     *
     * @param {string} query - searching keyword
     */
    const keywordSearchingHandler = (query: string) => {
        setKeyword(query);
        setIsActive(false);
    };

    /**
     * Keyword submit handler
     *
     * @param {string} query - searching keyword
     */
    const proceedToSearchResults = (query: string) => {
        if (query.length !== 0) {
            if (searchedItems.length === 1) {
                navigate(searchedItems[0].link);
            } else {
                navigate(`${LINK_SEARCH}?q=${keyword}`);
                setCategoryPage({
                    page: 'category',
                    params: {id: SEARCH_CATEGORY_ID},
                });
            }

            setIsActive(false);
        }
    };

    return (
        <div ref={searchForm} className={s.container}>
            <Tooltip
                isOpen={searchedItems.length > 0 && isActive}
                renderAs="div"
                position="bottom"
                openTrigger="none"
                closeTrigger="none"
                content={
                    <SearchResults
                        keywordSearching={keywordSearchingHandler}
                        searchedItems={searchedItems}
                        keyword={keyword}
                        page={page}
                        isLoggedIn={isLoggedIn}
                    />
                }
            >
                <form
                    className={s.form}
                    aria-label="form"
                    onSubmit={event => {
                        event.preventDefault();
                        proceedToSearchResults(keyword);
                    }}
                >
                    <input
                        className={classNames(s.input, {[s.active]: isActive})}
                        placeholder={getPlaceholderText()}
                        value={keyword}
                        onChange={({target: {value}}: ChangeEvent<HTMLInputElement>) => {
                            setKeyword(value);
                            setIsActive(true);
                        }}
                        onFocus={() => setIsActive(true)}
                        onClick={() => setIsActive(true)}
                        aria-label="Search"
                    />
                    <button type="submit" className={s.button} aria-label="Submit Search">
                        <Icon name="magnifier" />
                    </button>
                </form>
            </Tooltip>
        </div>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    page: store.route.page,
    isLoggedIn: isUserLoggedIn(store),
});

const mapDispatchToProps = {
    setCategoryPage: setPage,
};

export const SearchBoxConnected = connect(mapStateToProps, mapDispatchToProps)(SearchBox);
