import React from 'react';

import {SIZE_LARGE, SIZE_MEDIUM, SIZE_RESPONSIVE, SIZE_SMALL} from './baseIcon/constants';
import {Loader} from './loader/loader';
import {IconsType, SizeType} from './baseIcon/baseIcon';

export type PropsType = {
    name: IconsType;
    role?: string;
    size?: SizeType;
};

/**
 * Proxy component that loads the BaseIcon.
 * It force the Webpack create the own chunk for all SVG icons.
 *
 * @param {PropsType} props - Component props
 * @returns {JSX.Element} Element
 */
function Icon(props: PropsType): JSX.Element {
    return <Loader {...props} />;
}

Icon.defaultProps = {
    size: SIZE_RESPONSIVE,
};

export {Icon, SIZE_LARGE, SIZE_MEDIUM, SIZE_RESPONSIVE, SIZE_SMALL};
