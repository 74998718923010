/* eslint-disable max-lines */
import {
    ProductBrandType,
    ProductChildrenColorType,
    ProductChildrenType,
    ProductFrameType,
    ProductMediaType,
    ProductType,
} from './productType';

export const ProductBrandFixture: ProductBrandType = {
    id: 10,
    categoryId: 1,
    blockId: 468,
    image: '/brandLogoSrc.svg',
    imageCase: '/imageCaseSrc.png',
    imageHeight: 50,
    label: 'Ray-Ban',
    popular: 1,
    url: '/ray-ban',
};

export const ProductFrameFixture: ProductFrameType = {
    src: '/product/7134/width/135/isOnlyFrame/1/r/1162659703/',
    materials: ['Metal'],
    shapes: ['Square', 'Browline'],
    types: ['Full Frame'],
    isSpringHinges: true,
    isNosePads: true,
    width: 135,
    lensWidth: 51,
    lensHeight: 42,
    bridgeWidth: 18,
    templeLength: 145,
    mirror: 'mirror_image_url',
    size: {
        id: 1,
        label: 'Medium',
        shortLabel: 'M',
    },
    sgMirror: '',
    id: 12,
};

export const ProductMediaFixture: ProductMediaType = {
    angle: {
        position: 1,
        src: '/media/catalog/product/3/1/31-m1862_u_1.jpg',
    },
    front: {
        position: 2,
        src: '/media/catalog/product/3/1/31-m1862_f.jpg',
    },
    side: {
        position: 3,
        src: '/media/catalog/product/3/1/31-m1862_s.jpg',
    },
    whiteBg: {
        position: 4,
        src: '001373_f_1.jpg',
    },
    greyBg: {
        position: 5,
        src: '001373_f_1.jpg',
    },
    sgWhiteBg: {
        position: 6,
        src: '001373_f_1.jpg',
    },
    sgGreyBg: {
        position: 7,
        src: '001373_f_1.jpg',
    },
    '360rotation': {
        src: '/catalog/product/view/3416/311862_360.jpg',
        qty: 20,
        first: 20,
    },
    '180men': {
        src: '/catalog/product/view/3416/1862_m_1.jpg',
        qty: 5,
        first: 3,
    },
    '180women': {
        src: '/catalog/product/view/3416/1862_w.jpg',
        qty: 5,
        first: 3,
    },
    '180rotation': {
        src: '/catalog_product_view_3416/1/1862_w.jpg',
        qty: 5,
        first: 3,
    },
    videos: [],
};

export const ProductColorFixtureRed: ProductChildrenColorType = {
    id: '14',
    label: 'Red',
    src: 'catalog/filter/color/14.jpg',
};

export const ProductColorFixtureBlack: ProductChildrenColorType = {
    id: '19',
    label: 'Black',
    src: '/catalog/filter/color/19.jpg',
};

export const ProductContactsFixture: ProductType = {
    id: 9465,
    url: '1-day-acuvue-moist/121-002513.html',
    name: '1 Day Acuvue Moist',
    sku: '121-002513',
    brand: null,
    children: [
        ({
            id: 9465,
            url: '1-day-acuvue-moist/121-002513.html',
            name: '1 Day Acuvue Moist',
            sku: '121-002513',
            type: 'contact_lenses',
            media: ProductMediaFixture,
            stock: {
                status: 'in_stock',
                qty: 810,
            },
            contactLensTypeLabel: 'Daily',
            contactLensPerBox: 90,
        } as unknown) as ProductChildrenType,
    ],
};

export const ProductChildrenFixture: ProductChildrenType = {
    defaultTintSku: '',
    id: 7134,
    url: 'blackgold-medium/elliot/31-m10033.html',
    sku: '31-M10033',
    name: 'Elliot Black w/Gunmetal',
    shortDescription: 'The Elliot is a modern take on the iconic 1950’s',
    description:
        'The Elliot is a modern take on the iconic 1950’s Browline style. Crafted from premium acetate and sporting sleek arms, silver accents and a retro model bridge, this frame exudes a classic cool.',
    price: 94,
    finalPrice: 94,
    badge: {
        id: 1,
        label: 'super',
        position: 1,
    },
    lensFeatures: [],
    lensTypeIds: [7, 6, 10],
    genders: ['Men', 'Women'],
    type: 'eyeglasses',
    frame: ProductFrameFixture,
    color: ProductColorFixtureRed,
    media: {
        '180rotation': {
            src: '/catalog_product_view_3416/1/1862_w.jpg',
            qty: 5,
            first: 3,
        },
        front: {
            position: 2,
            src: '/media/catalog/product/3/1/31-m1862_f.jpg',
        },
    },
    brand: ProductBrandFixture,
    stock: {status: 'in_stock', qty: 2},
    isDsco: false,
    isEligibleForRx: true,
    glassesTypeIds: [225, 227, 226],
    isKids: false,
    templateId: 1,
    wizardId: 1,
    defaultDarknessSku: '',
    is3dModelAvailable: false,
};

export const ProductSecondChildrenFixture: ProductChildrenType = {
    defaultTintSku: '',
    id: 13131,
    url: 'burgundy-large/muse-curry/39-p9407.html',
    brand: {
        id: 145,
        label: 'Muse',
        popular: 23,
        url: '/muse-eyewear',
        image: '/brand/1/Muse_0_image.png',
        imageHeight: 20,
        categoryId: 120,
        imageCase: '/brand/1/Muse_0_case_image.png',
    },
    description: '',
    shortDescription: '',
    name: 'Muse Curry',
    sku: '39-P9407',
    price: 84,
    finalPrice: 84,
    type: 'eyeglasses',
    badge: {
        id: 1,
        label: 'Premium Frames',
        position: 1,
    },
    media: {
        front: {
            src: '/catalog_product/1/9407_f.jpg',
            position: 2,
        },
    },
    genders: ['Women'],
    stock: {
        status: 'in_stock',
        qty: 739,
    },
    isDsco: false,
    isKids: false,
    color: {
        id: '20',
        label: 'Burgundy',
        src: '/catalog_filter_color/1/20.jpg',
    },
    lensFeatures: [],
    lensTypeIds: [7, 6, 10],
    glassesTypeIds: [225, 227, 226],
    frame: {
        id: 9981,
        size: {
            id: 1,
            label: 'Large',
            shortLabel: 'L',
        },
        materials: ['Plastic'],
        shapes: ['Rectangle'],
        types: ['Full Frame'],
        isSpringHinges: false,
        isNosePads: true,
        width: 139,
        lensWidth: 55,
        lensHeight: 39,
        bridgeWidth: 16,
        templeLength: 140,
        mirror: '/mirror_frame/1/13131.png',
        sgMirror: '/mirror_frame/1/13131_sg.png',
    },
    isEligibleForRx: true,
    templateId: 1,
    wizardId: 2,
    defaultDarknessSku: '',
    is3dModelAvailable: false,
};

export const ProductFixture: ProductType = {
    id: 4776,
    url: '/rafter.html',
    brand: ProductBrandFixture,
    name: 'Elliot',
    sku: 'elliot-window',
    children: [
        ProductChildrenFixture,
        {
            ...ProductChildrenFixture,
            id: 7135,
            color: ProductColorFixtureBlack,
            type: 'sunglasses',
            url: 'tortoisegold-medium/elliot/31-m1863.html',
            media: {
                front: {
                    position: 2,
                    src: '/media/catalog/product/3/1/different_product_img.jpg',
                },
            },
        },
    ],
};

export const ProductSunglassesFixture: ProductType = {
    id: 4781,
    url: 'ray-ban-2140-wayfarer.html',
    name: 'RB2140 Wayfarer',
    sku: 'ray-ban-2140-sunglasses-window',
    brand: {
        id: 36,
        label: 'Ray-Ban',
        popular: 1,
        url: '/ray-ban',
        image: '/brand/1/Ray-Ban_0_image.png',
        imageHeight: 20,
        imageCase: '/brand/1/Ray-Ban_0_case_image.png',
        categoryId: 1,
    },
    children: [
        {
            id: 4303,
            url: 'shiny-black-large/ray-ban-2140-wayfarer/46-p6718.html',
            name: 'RB2140 Wayfarer ',
            sku: '46-P6718',
            brand: {
                id: 36,
                label: 'Ray-Ban',
                popular: 1,
                url: '/ray-ban',
                image: '/brand/1/Ray-Ban_0_image.png',
                imageHeight: 20,
                imageCase: '/brand/1/Ray-Ban_0_case_image.png',
                categoryId: 1,
            },
            shortDescription:
                "This is an iconic wayframe that's still going strong. Crafted in premium acetate, it features wing-like edges and bulky arms with a flattering polished hue.",
            description:
                "This is an iconic wayframe that's still going strong. Crafted in premium acetate, it features wing-like edges and bulky arms with a flattering polished hue.",
            price: 153,
            finalPrice: 153,
            type: 'sunglasses',
            badge: {
                label: 'Premium Frames',
                id: 127,
                position: 9,
            },
            media: {
                angle: {
                    src: '/catalog_product/1/46-6718_u.jpg',
                    position: 1,
                },
                front: {
                    src: '/catalog_product/1/46-6718_f.jpg',
                    position: 2,
                },
                side: {
                    src: '/catalog_product/1/46-6718_s.jpg',
                    position: 3,
                },
                greyBg: {
                    src: '/catalog_product/1/6718_g.jpg',
                    position: 5,
                },
                whiteBg: {
                    src: '/catalog_product/1/6718_f.jpg',
                    position: 6,
                },
                '180sunglasses': {
                    src: '/catalog_product_view_4303/1/6718_strip.jpg',
                    qty: 5,
                    first: 3,
                },
                '180men': {
                    src: '/catalog_product_view_4303/1/466718_m.jpg',
                    qty: 5,
                    first: 3,
                },
            },
            genders: ['Men', 'Women'],
            stock: {
                status: 'in_stock',
                qty: 2,
            },
            isDsco: false,
            isKids: false,
            color: {
                id: '204',
                label: 'Shiny Black',
                src: '/catalog_filter_color/1/204.jpg',
            },
            lensFeatures: [],
            lensTypeIds: [7, 6, 10],
            glassesTypeIds: [227, 226],
            frame: {
                id: 3870,
                size: {
                    id: 1,
                    label: 'Large',
                    shortLabel: 'L',
                },
                materials: ['Plastic'],
                shapes: ['Classic Wayframe'],
                types: ['Full Frame'],
                isSpringHinges: false,
                isNosePads: true,
                width: 142,
                lensWidth: 50,
                lensHeight: 42,
                bridgeWidth: 22,
                templeLength: 150,
                mirror: '/mirror_frame/1/4303.png',
                sgMirror: '',
            },
            isEligibleForRx: true,
            templateId: 4,
            wizardId: 1004,
            defaultTintSku: '',
            defaultDarknessSku: '',
            is3dModelAvailable: false,
        },
    ],
};
