import React, {memo, MouseEvent, ReactElement} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import s from './button.scss';

export type PropsType = {
    btnType?: 'link' | 'a' | '';
    link?: string;
    className?: string;
    value?: string;
    onClick?: (event: MouseEvent) => void;
    color?:
        | 'transparent'
        | 'gray'
        | 'easternBlue'
        | 'portGore'
        | 'wildSand'
        | 'concrete'
        | 'fuscousGray'
        | 'persianIndigo'
        | 'white'
        | 'mulledWine'
        | 'catalinaBlue';
    isLoading?: boolean;
    isDisabled?: boolean;
    children?: ReactElement | string;
};

/**
 * Component for button element
 *
 * @returns {React.Element} badge element
 */
export const ButtonComponent: React.FC<PropsType> = ({
    btnType = '',
    link = '',
    className,
    value = '',
    onClick,
    color = 'transparent',
    isLoading = false,
    isDisabled = false,
    children,
}) => {
    const classNamesString = classNames(s.btn, s[color], className, {
        [s.loading]: isLoading,
        [s.disabled]: isDisabled,
    });
    const handlerObj = onClick ? {onClick} : {};
    const loader = isLoading && <span className={s.loader} />;
    const content = value || children || null;

    switch (btnType) {
        case 'link':
            return (
                <Link to={link} className={classNamesString} {...handlerObj}>
                    {content}
                    {loader}
                </Link>
            );
        case 'a':
            return (
                <a href={link} className={classNamesString} {...handlerObj}>
                    {content}
                    {loader}
                </a>
            );
        default:
            return (
                <button
                    disabled={isDisabled || isLoading}
                    className={classNamesString}
                    {...handlerObj}
                >
                    {content}
                    {loader}
                </button>
            );
    }
};

export const Button = memo<PropsType>(ButtonComponent);
