import React, {FC, ReactElement, useState} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

import {Icon} from 'components/__helpers/icon/icon';
import {MergeAccountPopupConnected} from 'features/mergeAccountsPopup/mergeAccountPopup';
import {ApplicationStoreType} from 'constants/typescript/types';
import {setReadMergeMessage} from 'features/store/eligibilityWidget';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';

import s from './mergeAccountMessage.scss';

export type PropsType = {
    isMergeMessageRead: boolean;
    markMergeMessage: (state: boolean) => void;
    openPopup: (node: ReactElement) => void;
};

export const MergeAccountMessage: FC<PropsType> = ({
    isMergeMessageRead,
    markMergeMessage,
    openPopup,
}) => {
    const [isCollapsed, setCollapsed] = useState(isMergeMessageRead || false);

    const collapseHandler = () => {
        markMergeMessage(true);
        setCollapsed(prevState => !prevState);
    };

    return (
        <article>
            <div className={classNames(s.main, {[s.notRead]: !isMergeMessageRead})}>
                <div className={classNames(s.titleWrap, {[s.titleWrapCollapsed]: isCollapsed})}>
                    <p className={s.articleTitle}>Are you a UHC member?</p>
                    <button
                        type="button"
                        className={classNames(s.collapseButton, {[s.collapsed]: isCollapsed})}
                        onClick={() => collapseHandler()}
                    >
                        <Icon name="arrow" size="small" />
                    </button>
                </div>
                {isCollapsed ? null : (
                    <div className={s.messageWrap}>
                        <span>Click here to use your vision benefits from UHC.</span>
                        <a
                            className={s.mergeAccountLink}
                            onClick={() => openPopup(<MergeAccountPopupConnected />)}
                        >
                            Use your benefits
                        </a>
                    </div>
                )}
            </div>
        </article>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    isMergeMessageRead: store.widgets.eligibilityWidget.isMergeAccountRead,
});

export const mapDispatchToProps = {
    markMergeMessage: setReadMergeMessage,
    openPopup: (content: ReactElement) =>
        setCmsPopupVisibility(true, content, {
            className: 'mergeAccounts',
        }),
};

export const MergeAccountMessageConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MergeAccountMessage);
