import {createAction, createReducer} from 'redux-act';

import {FULFILLED} from 'constants/actionSuffix';
import {RequestType, ResponseType} from 'constants/typescript/types';
import {Status} from 'constants/reducerStatus';
import {ENDPOINTS} from 'constants/endpoints';

import {CustomerType, CustomerTypeReducer} from './customerType';

export const defaultState: CustomerTypeReducer = {
    is_logged_in: false,
    form_key: '',
    subscriber_id: '',
    subscription_date: '',
    simple_subscription_date: '',
    login_in_status: '',
    login_method: '',
    email: '',
    md5: '',
    sha1: '',
    sha256: '',
    currency: {symbol: '$', code: 'USD'},
    previous_category_id: '',
    previous_category_name: '',
    acceptGDPR: false,
    cookiesGDPR: false,
    id: '',
    status: Status.Fulfilled,
};

export const getCustomer = createAction<RequestType>('GET_CUSTOMER', () => ({
    request: {
        method: 'GET',
        url: `/backend/${ENDPOINTS.CUSTOMER_CURRENT}`,
        headers: {Authorization: ''},
    },
}));
export const logoutCustomer = createAction<RequestType>('LOGOUT_CUSTOMER', () => ({
    request: {
        method: 'GET',
        url: `/backend/${ENDPOINTS.MAGENTO_LOGOUT}`,
        headers: {Authorization: ''},
    },
}));

/**
 * Customer data reducer
 */
export const customer = createReducer<CustomerTypeReducer>({}, defaultState)
    .on(`${getCustomer}${FULFILLED}`, (state, {data}: ResponseType<CustomerType>) => ({
        ...data,
        status: Status.Fulfilled,
    }))
    .on(getCustomer, state => ({
        ...state,
        status: Status.Pending,
    }));
