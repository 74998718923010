import {CtaButton} from '@optimaxdev/design-system-u';
import React, {FC} from 'react';

import {LoginPopupNames} from '../loginPopup';
import s from '../loginPopup.scss';

import createAccountFormStyles from './createAccountForm.scss';
import {sendAccessLogEvent} from '../memberLogin/analytics';

type PropsType = {
    setPopup: (popup: LoginPopupNames) => void;
};

export const AccessBenefits: FC<PropsType> = ({setPopup}) => {
    const handleAccess = () => {
        sendAccessLogEvent();
        setPopup(LoginPopupNames.member);
    };

    return (
        <>
            <hr className={createAccountFormStyles.pageDelimeter} />

            <div className={s.ctaButton}>
                <CtaButton variant="ghost" size="large" isFull onClick={handleAccess}>
                    Access Benefits
                </CtaButton>
            </div>
        </>
    );
};
