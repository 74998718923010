import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';

import {ApplicationStoreType} from 'constants/typescript/types';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {isPopupVisible} from 'selectors/popup/popup';

import flags from '../../../constants/flags/flags';
import {closeMFAPopup, setCanOpenWP as setCanOpenWPAction} from '../../../reducers/oauth2';

import {LoginPopup, LoginPopupNames} from './loginPopup';

export type LoginPopupWrapperPropsType = {
    isOpen?: boolean;
    currentPopupName?: LoginPopupNames;
    onRequestClose?: () => void;
    closeMFAPopup: () => void;
    setCanOpenWP: (canOpen: boolean) => void;
};

/**
 * Login popup wrapper, for rendering the login popup with all props as optional
 *
 * @param {LoginPopupWrapperPropsType} props - component props
 * @returns {React.ReactElement} - login popup
 */
export function LoginPopupWrapper({
    isOpen = true,
    currentPopupName,
    onRequestClose,
    closeMFAPopup,
    setCanOpenWP,
}: LoginPopupWrapperPropsType): React.ReactElement {
    const [isPopupOpenState, setPopupIsOpenState] = useState(Boolean(isOpen));
    const closeLoginPopup = useCallback(() => {
        setPopupIsOpenState(false);
        if (onRequestClose) {
            onRequestClose();
        }
        if (flags.mfaUHCGD.isEnabled()) {
            setCanOpenWP(true);
            closeMFAPopup();
        }
    }, [onRequestClose, closeMFAPopup, setCanOpenWP]);
    useEffect(() => {
        setPopupIsOpenState(isOpen);
    }, [isOpen]);
    return (
        <LoginPopup
            isOpen={isPopupOpenState}
            currentPopupName={currentPopupName}
            onRequestClose={closeLoginPopup}
        />
    );
}

export const mapStateToProps = (
    store: ApplicationStoreType,
): {isOpen: boolean; currentPage: string} => ({
    isOpen: isPopupVisible(store),
    currentPage: store.route.page,
});

export const mapDispatchToProps: Pick<
    LoginPopupWrapperPropsType,
    'onRequestClose' | 'closeMFAPopup' | 'setCanOpenWP'
> = {
    onRequestClose: () => setCmsPopupVisibility(false, null, {}),
    closeMFAPopup,
    setCanOpenWP: setCanOpenWPAction,
};

export const LoginPopupWrapperConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPopupWrapper);
