import React, {useEffect, useState, FC} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import SessionStore from 'store/storages/sessionStorage';

import flags from 'constants/flags/flags';
import {
    logOut as logOutAction,
    closeMFAPopup as closePopupAction,
    setCanOpenWP as setCanOpenWPAction,
} from 'reducers/oauth2';
import {isUserLoggedIn} from 'selectors/oauth2Data/oauth2Data';
import {LoginPopup} from 'features/loginPopup/components/loginPopup';
import {ApplicationStoreType} from 'constants/typescript/types';
import {GUEST, LOGIN_STATUS} from 'constants/loginStatus/loginStatus';
import AccessibilityButton from 'features/accessibility/components/accessibilityButton/accessibilityButton';

import s from './topStripMenu.scss';
import {MyAccountConnected} from './myAccount/myAccount';
import {CustomerCare} from './customerCare/customerCare';
import {PropsType} from './topStripMenuTypes';
import {TopStripPhoneNumber} from './topStripPhoneNumber/topStripPhoneNumber';

const sendGuestStatusEvent = () => {
    pushDataLayer({
        event: 'GeneralNonInteraction',
        eventAction: 'Login Status',
        eventCategory: 'Login',
        eventLabel: 'Guest',
    });
    SessionStore.write(LOGIN_STATUS, GUEST);
};

export const TopStripMenu: FC<PropsType> = ({
    phone,
    isLoggedIn,
    logOut,
    closeMFAPopup,
    setCanOpenWP,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const logOutForAccount = () => {
        sendGuestStatusEvent();
        logOut();
    };

    useEffect(() => {
        if (!SessionStore.read('login_status') && !isLoggedIn) {
            sendGuestStatusEvent();
        }
    }, [isLoggedIn]);

    return (
        <>
            {isOpen && (
                <LoginPopup
                    isOpen={isOpen}
                    onRequestClose={() => {
                        setIsOpen(false);
                        if (flags.mfaUHCGD.isEnabled()) {
                            setCanOpenWP(true);
                            closeMFAPopup();
                        }
                    }}
                />
            )}
            <div className={classNames(s.menu, s.dHelpCenterUHCG)}>
                <TopStripPhoneNumber phone={phone} />
                <>
                    <AccessibilityButton />
                    <CustomerCare />
                    <MyAccountConnected
                        setIsOpen={setIsOpen}
                        logOutForAccount={logOutForAccount}
                        isLoggedIn={isLoggedIn}
                    />
                </>
            </div>
        </>
    );
};

/* istanbul ignore next */
export const mapStateToProps = (store: ApplicationStoreType) => ({
    phone: store.storeInfo.phones.default,
    isLoggedIn: isUserLoggedIn(store),
});

export const mapDispatchToProps = {
    logOut: logOutAction,
    closeMFAPopup: closePopupAction,
    setCanOpenWP: setCanOpenWPAction,
};

export const TopStripMenuConnected = connect(mapStateToProps, mapDispatchToProps)(TopStripMenu);
