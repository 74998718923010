// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';

import {FULFILLED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import type {PageType} from './pageType';

const defaultState = {};

export const {getCmsPage} = createActions({
    GET_CMS_PAGE: (id: string): ActionType<AxiosResponse> => ({
        request: {
            method: 'get',
            url: `/backend/${ENDPOINTS.CMS_PAGE}/${id}`,
        },
        meta: id,
    }),
});

/**
 * Cms page reducer
 */
export const cmsPage = handleActions(
    {
        [`${getCmsPage}${FULFILLED}`]: (
            state: PageType,
            {payload: {data}, meta}: ActionType<PageType, string>,
        ) => ({
            ...state,
            [meta.previousAction.payload.meta]: data,
        }),
    },
    defaultState,
);
