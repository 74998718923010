import {createReducer} from 'redux-act';
import {AxiosResponse} from 'axios';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ResponseErrorType} from 'constants/typescript/types';
import {USER_MEMBER_TYPE} from 'constants/authorization';
import {
    LogInType,
    MemberAuthorizationMetaData,
} from 'features/loginPopup/api/customerMs/customerMsType';

import {Oauth2TokenErrorType, Oauth2Type, Oauth2TypeReducerType} from './oauth2Type';
import {
    clearOathError,
    clearTokens,
    getTokens,
    putRefreshToken,
    setCanOpenWP,
    setToken,
} from './oauth2Actions';

export const defaultState: Oauth2TypeReducerType = {
    access_token: '',
    refresh_token: '',
    expires_in: 0,
    expiresDate: 0,
    error: {type: '', message: ''},
    /* By default the user is decided as non-meber */
    memberType: USER_MEMBER_TYPE.NOT_UHC_MEMBER,
    settingsSafetyMode: false,
    canOpenWP: true,
};

/**
 * Oauth2 reducer
 *
 * @returns {Reducer<Oauth2Type>} state - next state
 */
export const oauth2Data = createReducer<Oauth2TypeReducerType>(
    {
        [`${getTokens}${FULFILLED}`]: (state, {data}: AxiosResponse<Oauth2Type>) => ({
            ...state,
            ...data,
            error: {type: '', message: ''},
        }),
        [`${setToken}`]: (
            state,
            tokenWithMemberAuthMetaData: LogInType & MemberAuthorizationMetaData,
        ) => ({
            ...state,
            ...tokenWithMemberAuthMetaData,
            error: {type: '', message: ''},
        }),
        [`${putRefreshToken}${FULFILLED}`]: (state, {data}: AxiosResponse<Oauth2Type>) => ({
            ...state,
            ...data,
            error: {type: '', message: ''},
        }),
        [`${getTokens}${REJECTED}`]: (state, {error}: ResponseErrorType<Oauth2TokenErrorType>) => ({
            ...state,
            error: {
                type: (error && error.response?.data.error) || '',
                message: (error && error.response?.data.message) || '',
            },
            memberType: defaultState.memberType,
        }),
        [`${putRefreshToken}${REJECTED}`]: (
            state,
            {error}: ResponseErrorType<Oauth2TokenErrorType>,
        ) => ({
            ...state,
            error: {
                type: error.response?.data.error || '',
                message: error.response?.data.message || '',
            },
            memberType: defaultState.memberType,
        }),
        [setCanOpenWP.toString()]: (state, payload) => ({
            ...state,
            canOpenWP: payload,
        }),
        [clearTokens.toString()]: () => defaultState,
        [clearOathError.toString()]: state => ({
            ...state,
            error: {
                type: '',
                message: '',
            },
        }),
    },
    defaultState,
);
