// @flow
import type {ApplicationStoreType} from 'constants/flow/flowTypes';
import flags from 'constants/flags/flags';
import type {CategoryType} from 'reducers/category';
import type {SortType} from 'reducers/categorySort';
import type {CategoryItemsType} from 'reducers/categoryItems';
import type {ProductType} from 'reducers/product';
import type {CategoryFiltersType} from 'reducers/categoryFilters';

/**
 * Get category data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {CategoryType} - product
 */
export const getCategoryData = (store: ApplicationStoreType): CategoryType => store.category;

/**
 * Get product from category items
 *
 * @param {ApplicationStoreType} store - Application state
 * @param {number} productId - product id
 * @returns {ProductType | null} - product
 */
export const getProductFromCategory = (
    store: ApplicationStoreType,
    productId: number,
): ProductType | null =>
    store.categoryItems.products.find((product: ProductType) =>
        product.children.some(({id}) => id === productId),
    ) || null;

/**
 * Get product from product reducer
 *
 * @param {ApplicationStoreType} store - Application state
 * @param {url} productUrl - product url
 * @returns {ProductType | null} - product
 */
export const getProductFromProductPageByUrl = (
    store: ApplicationStoreType,
    productUrl: string,
): ProductType | null =>
    store.product.item.children &&
    (store.product.item.children.find(({url}) => productUrl.includes(url)) || null);

/**
 * Get product from category items
 *
 * @param {ApplicationStoreType} store - Application state
 * @param {url} productUrl - product url
 * @returns {ProductType | null} - product
 */
export const getProductFromCategoryByUrl = (
    store: ApplicationStoreType,
    productUrl: string,
): ProductType | null => {
    const windowProduct = store.categoryItems.products.find((product: ProductType) =>
        product.children.some(({url}) => productUrl.includes(url)),
    );

    return windowProduct
        ? windowProduct.children.find(({url}) => productUrl.includes(url))
        : getProductFromProductPageByUrl(store, productUrl);
};

/**
 * Get category items data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {CategoryItemsType} - category items
 */
export const getCategoryItemsData = (store: ApplicationStoreType): CategoryItemsType =>
    store.categoryItems;

/**
 * Get category filters data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {CategoryItemsType} - category items
 */
export const getCategoryFiltersData = (store: ApplicationStoreType): CategoryFiltersType =>
    store.categoryFilters;

/**
 * Get category sort data
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {SortType} - category items
 */
export const getCategorySortData = (store: ApplicationStoreType): SortType => store.categorySort;

/**
 * Get category filters
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {CategoryItemsType} - category items
 */
export const getCategoryFilters = (store: ApplicationStoreType): CategoryFiltersType =>
    store.categoryFilters.filters.filter(item => item.items);

export const getCategoryTitle = (store: ApplicationStoreType): CategoryFiltersType =>
    store.category.title;

export const getCategoryApplyFilters = (store: ApplicationStoreType): CategoryFiltersType =>
    store.categoryAppliedFilters;

export const getIsContactLensesCategory = (store: ApplicationStoreType): boolean =>
    flags.dUHCCL.isEnabled() && store.category.is_contact_lenses;
