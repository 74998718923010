import {createAction, createReducer} from 'redux-act';

import {RequestType, ResponseType} from 'constants/typescript/types';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';

import {OrdersType, OrderItemType} from './ordersInfoType';

export const defaultState: OrdersType = {
    list: [],
    status: Status.Pending,
};

export const getOrders = createAction<RequestType>('GET_ORDERS', () => ({
    request: {
        headers: {version: 2, Authorization: ''},
        method: 'GET',
        url: '/backend/api/rest/sales/order',
    },
}));
export const getOrder = createAction<number, RequestType>('GET_ORDER', id => ({
    request: {
        headers: {version: 2, Authorization: ''},
        method: 'GET',
        url: `/backend/api/rest/sales/order/${id}`,
    },
}));
export const getOrderByInvoice = createAction<string, RequestType>(
    'GET_ORDER_BY_INVOICE',
    (invoiceId: string) => ({
        request: {
            headers: {version: 2, Authorization: ''},
            method: 'GET',
            url: `/backend/api/rest/sales/order/invoice/${invoiceId}`,
        },
    }),
);
export const clearOrdersInfo = createAction('CLEAR_ORDERS_INFO');

/**
 * Orders info reducer
 *
 * @returns {OrdersType} state - next state
 */
export const ordersInfo = createReducer<OrdersType>({}, defaultState)
    .on(
        `${getOrders}${FULFILLED}`,
        (state: OrdersType, {data}: ResponseType<OrderItemType[]>): OrdersType => ({
            ...state,
            list: data,
            status: Status.Fulfilled,
        }),
    )
    .on(
        `${getOrders}${REJECTED}`,
        (state: OrdersType): OrdersType => ({
            ...state,
            status: Status.Rejected,
        }),
    )
    .on(
        `${getOrder}${FULFILLED}`,
        (state: OrdersType, {data}: ResponseType<OrderItemType>): OrdersType => ({
            ...state,
            [data.id]: data,
            status: Status.Fulfilled,
        }),
    )
    .on(
        `${getOrder}${REJECTED}`,
        (state: OrdersType): OrdersType => ({
            ...state,
            status: Status.Rejected,
        }),
    )
    .on(clearOrdersInfo, (): OrdersType => defaultState);
