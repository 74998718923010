// @flow
import {type SagaIterator} from 'redux-saga';
import {type ActionType} from 'redux-actions';
import {put, select, takeEvery, all} from 'redux-saga/effects';

import {FULFILLED} from 'constants/actionSuffix';
import {
    getWishList,
    addToWishList,
    removeFromWishList,
    addToWishListApi,
    removeFromWishListApi,
} from 'reducers/wishList';
import {getWishListItems} from 'reducers/wishListItems';
import {getWishListData} from 'selectors/wishList/wishList';

/**
 * Fetch wish list product
 *
 * @param {ActionType<number>} action - action
 */
export function* getWishListProduct(action: ActionType<number>): SagaIterator {
    const productId = action.payload;

    yield all([put(addToWishListApi(productId)), put(getWishListItems([productId]))]);
}

/**
 * Fetch wish list products
 */
export function* getWishListProducts(): SagaIterator {
    const {ids} = yield select(getWishListData);

    if (Object.keys(ids).length === 0) return;

    yield put(getWishListItems(Object.keys(ids).map(id => Number(id))));
}

/**
 * Remove wish list product
 *
 * @param {ActionType<number>} action - action
 */
export function* removeFromWishListProduct(action: ActionType<number>): SagaIterator {
    const productId = action.payload;

    yield put(removeFromWishListApi(productId));
}

/**
 * Begin of saga
 */
export function* wishListSaga(): SagaIterator {
    yield takeEvery(addToWishList, getWishListProduct);
    yield takeEvery(removeFromWishList, removeFromWishListProduct);
    yield takeEvery(`${getWishList}${FULFILLED}`, getWishListProducts);
}
