import {createAction, createReducer} from 'redux-act';

import {RequestType, ResponseType} from 'constants/typescript/types';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import {PaymentType, PaymentResponseType} from './paymentType';

export const defaultState: PaymentType = {
    list: [],
    isLoading: false,
};

export const getPaymentMethods = createAction<RequestType>(
    'GET_PAYMENT_METHODS',
    (): RequestType => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            url: '/backend/optimaxcheckout/payment',
        },
    }),
);

export const savePaymentMethod = createAction<RequestType>(
    'SAVE_PAYMENT_METHOD',
    (data: string): RequestType => ({
        request: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: '',
            },
            url: ENDPOINTS.SAVE_PAYMENT_METHOD,
            data,
        },
    }),
);

/**
 * Payment reducer
 */
export const payment = createReducer<PaymentType>(
    {
        [`${getPaymentMethods}${FULFILLED}`]: (
            state: PaymentType,
            {data}: ResponseType<PaymentResponseType>,
        ): PaymentType => ({
            ...state,
            list: Object.keys(data).map(key => data[key]),
            isLoading: false,
        }),
        [`${savePaymentMethod}${FULFILLED}`]: (state: PaymentType): PaymentType => ({
            ...state,
            isLoading: false,
        }),
        [savePaymentMethod.toString()]: (state: PaymentType): PaymentType => ({
            ...state,
            isLoading: true,
        }),
        [`${savePaymentMethod}${REJECTED}`]: (state: PaymentType): PaymentType => ({
            ...state,
            isLoading: false,
        }),
        [`${getPaymentMethods}${REJECTED}`]: (state: PaymentType): PaymentType => ({
            ...state,
            isLoading: false,
        }),
    },
    defaultState,
);
