/* eslint-disable max-lines */
import {createSelector} from 'reselect';
import {WizardOptionType, WizardValueType} from '@optimaxdev/wizard-ms';

import {getWizardMs} from 'features/wizard/store/selectors/wizard/wizard';
import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {CustomerConfigurationType} from 'features/wizard/store/reducers/customerConfiguration/customerConfigurationType';
import {LENS_TYPE_OPTIONS_KEYS, LENS_TYPE_OPTIONS_KEYS as OPTIONS} from 'constants/wizard/wizard';
import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {UpsellsType} from 'features/wizard/store/reducers/upsells/upsells';
import {upsellsAlias} from 'constants/upsellOptions';
import {MULTIPLE_SELECTION_UPSELL_ALIASES} from 'features/wizard/constants/lensTypes';

import {
    EYEGLASSES_COLORS_TITLES,
    EYEGLASSES_GROUP_IDS,
    TRANSITION_COLLECTIONS_GROUP_IDS,
} from 'features/wizard/store/constants/upsells';
import {sortUpsells} from './helpers/helpers';

export type FormattedColors = Maybe<
    Array<{
        groupId: string;
        colors: Array<WizardValueType>;
        title: string;
        description: string;
        node: number;
        price: number;
        finalPrice: number;
    }>
>;

type FormattedTabsType = Array<{
    name: string;
    singleSelectionUpsells: Array<WizardOptionType>;
    multipleSelectionUpsells: Array<WizardOptionType>;
}>;

/**
 * Get upsells field
 */
export const getUpsells = createSelector(getWizardMs, ({upsells}) => upsells);
/**
 * filtered upsell for tabs excluded insurance b/c it in fifth step
 * if we need exclude some option - use items.filter item => !['example'].includes(item.alias)
 */
export const filteredUpsellsForTabs = createSelector(getUpsells, ({items}) => items);

/**
 * prepare upsells in tabs
 */
export const prepareUpsellsInTabs = createSelector<
    ApplicationStoreType,
    Array<WizardOptionType>,
    Array<WizardOptionType>
>(filteredUpsellsForTabs, items =>
    items.filter(({alias}) => MULTIPLE_SELECTION_UPSELL_ALIASES.includes(alias)),
);

export const getTransitionUpsell = createSelector<
    ApplicationStoreType,
    Array<WizardOptionType>,
    Maybe<WizardOptionType>
>(filteredUpsellsForTabs, items => items.find(({alias}) => alias === OPTIONS.PHOTOCHROMIC) || null);
/**
 * filtered upsell for tabs excluded insurance b/c it in fifth step
 * if we need exclude some option - use items.filter item => !['example'].includes(item.alias)
 */
export const getHydrophobic = createSelector(getUpsells, ({items}) =>
    items.find(item => item.alias === 'hydrophobic'),
);

/**
 * These colors only for eyeglasses
 */
export const eyeglassesColors = createSelector(
    filteredUpsellsForTabs,
    items => items.find(({alias}) => alias === OPTIONS.SUNGLASSES) || null,
);

/**
 * These colors only for eyeglasses
 */
export const getEyeglassesColors = createSelector<
    ApplicationStoreType,
    Array<WizardOptionType>,
    Array<WizardOptionType>
>(filteredUpsellsForTabs, items => items.filter(({alias}) => alias === OPTIONS.SUNGLASSES));

/**
 * Get digital protection upsell
 */
export const getDigitalProtection = createSelector<
    ApplicationStoreType,
    Array<WizardOptionType>,
    Array<WizardOptionType>
>(filteredUpsellsForTabs, items => items.filter(({alias}) => alias === OPTIONS.DIGITAL));

/**
 * These colors only for transition eyeglasses
 */
export const transitionColors = createSelector(
    filteredUpsellsForTabs,
    items => items.find(({alias}) => alias === OPTIONS.PHOTOCHROMIC) || null,
);

/**
 * Get active tab as string
 */
export const getActiveTab = createSelector(
    filteredUpsellsForTabs,
    getCustomerConfiguration,
    (items, {options}) => {
        const tabs = items
            .filter(({alias}) => MULTIPLE_SELECTION_UPSELL_ALIASES.includes(alias))
            .filter(({node, alias}) => options[node] && alias)
            .sort(
                (a, b) =>
                    MULTIPLE_SELECTION_UPSELL_ALIASES.indexOf(a.alias) -
                    MULTIPLE_SELECTION_UPSELL_ALIASES.indexOf(b.alias),
            );
        if (tabs.length === 0) return OPTIONS.CLEAR;
        return tabs[tabs.length - 1].alias;
    },
);

export const getFormattedColors = createSelector(eyeglassesColors, upsell => {
    if (!upsell) return null;

    const colors = element =>
        (upsell.items?.filter(({groupId}) => groupId === element) || []).sort(
            (a, b) => Number(a.sort) - Number(b.sort),
        );

    const getMinByPrice = items => [...items].sort((a, b) => Number(a.price) - Number(b.price))[0];

    return EYEGLASSES_GROUP_IDS.map(element => ({
        ...EYEGLASSES_COLORS_TITLES[element],
        groupId: element,
        colors: colors(element),
        node: (colors(element)[0] || {node: null}).node,
        price: (getMinByPrice(colors(element)) || {price: null}).price,
        finalPrice: (getMinByPrice(colors(element)) || {finalPrice: null}).finalPrice,
    })).filter(({node}) => Boolean(node));
});

export const getFormattedTransitionColors = createSelector(transitionColors, upsell => {
    if (!upsell) return null;

    const colors = element =>
        (upsell.items?.filter(({groupId, enabled}) => groupId === element && enabled) || []).sort(
            (a, b) => Number(a.sort) - Number(b.sort),
        );

    const getMinByPrice = items => [...items].sort((a, b) => Number(a.price) - Number(b.price))[0];

    return TRANSITION_COLLECTIONS_GROUP_IDS.map(element => ({
        ...EYEGLASSES_COLORS_TITLES[element],
        groupId: element,
        colors: colors(element),
        node: (colors(element)[0] || {node: null}).node,
        price: (getMinByPrice(colors(element)) || {price: null}).price,
        finalPrice: (getMinByPrice(colors(element)) || {finalPrice: null}).finalPrice,
        title: (colors(element)[0] || {title: null}).title,
    })).filter(({node}) => Boolean(node));
});

export const selectedEyeglassesColor = createSelector(
    eyeglassesColors,
    getCustomerConfiguration,
    (eyeglassesColors, {options}) => {
        if (!eyeglassesColors) return null;
        const id = options[eyeglassesColors.node];

        return eyeglassesColors.items?.find(({node}) => node === id) || null;
    },
);

export const getSelectedTransitionColor = createSelector(
    transitionColors,
    getCustomerConfiguration,
    (wizardEyeglassesColors, {options}) => {
        if (!wizardEyeglassesColors) return null;
        const id = options[wizardEyeglassesColors.node];

        return wizardEyeglassesColors.items?.find(({node}) => node === id) || null;
    },
);

/**
 * It returns selected upsell except hydro option
 */
export const getSelectedUpsell = createSelector<
    ApplicationStoreType,
    UpsellsType,
    CustomerConfigurationType,
    Maybe<WizardValueType>
>(getUpsells, getCustomerConfiguration, ({items}, {options}) => {
    if (!items.length) return null;
    const foundUpsell = items.find(
        ({node, alias}) => options[node] && alias !== upsellsAlias.HYDROPHOBIC,
    );
    if (!foundUpsell) return null;
    return foundUpsell.items?.find(({node}) => node === options[foundUpsell.node]) || null;
});

export const getSelectedWizardItems = createSelector<
    ApplicationStoreType,
    UpsellsType,
    CustomerConfigurationType,
    WizardValueType[] | []
>(getUpsells, getCustomerConfiguration, ({items}, {options}) => {
    if (!items.length) return [];

    const sortedItems = sortUpsells(items);
    const upsellsToReturn: WizardValueType[] = [];
    sortedItems.forEach(upsell => {
        const selectedItem = upsell.items?.find(({node}) => node === options[upsell.node]);
        if (selectedItem) upsellsToReturn.push(selectedItem);
    });
    return upsellsToReturn;
});

const UPGRADES_SKUS = ['HMX', 'digital-block'];
/**
 * Returns upsells related to upgrades section in summary
 */
export const getUpgradesUpsells = createSelector<
    ApplicationStoreType,
    WizardValueType[],
    WizardValueType[]
>(getSelectedWizardItems, upsells => upsells.filter(upsell => UPGRADES_SKUS.includes(upsell.sku)));

/**
 * Returns upsells related to lens section in summary
 */
export const getLensUpsells = createSelector<
    ApplicationStoreType,
    WizardValueType[],
    WizardValueType[]
>(getSelectedWizardItems, upsells => upsells.filter(upsell => !UPGRADES_SKUS.includes(upsell.sku)));

/**
 * Checks whether the Blue Light upsell is added on the lens step
 */
export const getIsDigitalBlockOnLensStep = createSelector<
    ApplicationStoreType,
    WizardValueType[],
    WizardValueType[],
    boolean
>(getLensUpsells, getUpgradesUpsells, (lensUpsells, upgradesUpsells) => {
    const isTransitionOrPhotochromic = lensUpsells.some(({groupId}) =>
        groupId?.match(/(transition|photochromic)/gi),
    );
    const isDigitalBlock = upgradesUpsells.some(({sku}) => sku === 'digital-block');

    return !isTransitionOrPhotochromic && isDigitalBlock;
});
/**
 * It returns selected hydro option
 */
export const getHydrophobicOption = createSelector<
    ApplicationStoreType,
    UpsellsType,
    CustomerConfigurationType,
    Maybe<WizardValueType>
>(getUpsells, getCustomerConfiguration, ({items}, {options}) => {
    if (!items.length) return null;
    const foundHydroOption = items.find(
        ({node, alias}) => options[node] && alias === upsellsAlias.HYDROPHOBIC,
    );
    if (!foundHydroOption) return null;
    if (foundHydroOption.items) return foundHydroOption.items[0];
    return null;
});

export const getFormattedTabsWithUpsells = createSelector<
    ApplicationStoreType,
    Array<WizardOptionType>,
    Array<WizardOptionType>,
    Array<WizardOptionType>,
    FormattedTabsType
>(
    filteredUpsellsForTabs,
    getEyeglassesColors,
    getDigitalProtection,
    (items, colors, digitalProtection) => {
        if (!items.length) return [];

        const singleSelectionUpsells = items.filter(
            item => LENS_TYPE_OPTIONS_KEYS.PHOTOCHROMIC === item.alias,
        );
        const multipleSelectionUpsells = items.filter(
            item => item.items && !MULTIPLE_SELECTION_UPSELL_ALIASES.includes(item.alias),
        );
        const clear = items.filter(item => item.alias === 'clear');

        return [
            {
                name: OPTIONS.CLEAR,
                singleSelectionUpsells: clear,
                multipleSelectionUpsells,
            },
            {
                name: OPTIONS.DIGITAL,
                singleSelectionUpsells: digitalProtection,
                multipleSelectionUpsells,
            },
            {
                name: OPTIONS.PHOTOCHROMIC,
                singleSelectionUpsells,
                multipleSelectionUpsells: [...multipleSelectionUpsells, ...digitalProtection],
            },
            {
                name: OPTIONS.SUNGLASSES,
                singleSelectionUpsells: colors,
                multipleSelectionUpsells,
            },
        ];
    },
);

export const getActiveMultipleSelectionUpsells = createSelector<
    ApplicationStoreType,
    FormattedTabsType,
    string,
    Maybe<Array<WizardOptionType>>
>(getFormattedTabsWithUpsells, getActiveTab, (tabs, activeTab) => {
    return tabs.find(tab => tab.name === activeTab)?.multipleSelectionUpsells || null;
});
