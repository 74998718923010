import {QuoteItemV2OptionType} from 'reducers/cart/addToCart/addToCartType';
import {ProductChildrenType} from 'reducers/product';
import {STOCK_STATUSES_FOR_ANALYTICS} from 'constants/product/product';

/**
 * Return product lens type
 *
 * @param {Record<string, QuoteItemV2OptionType>} options - Product options list
 * @returns {string} Lens type
 */
export const getLensType = (options: Record<string, QuoteItemV2OptionType> = {}): string => {
    const option = Object.values(options).find(item => item.option.alias === 'usage');
    if (!option || !option.usageName) return 'N/A';
    return option.usageName;
};

/**
 * Returns name of chosen usage
 *
 * @param {Record<string, QuoteItemV2OptionType>} options - cart item options
 * @returns {string} name of the chosen usage
 * */
export const getUsageSku = (options: Record<string, QuoteItemV2OptionType>) => {
    const usageOptionData = Object.values(options).find(item => item.option.alias === 'usage');

    if (!usageOptionData) return '';

    const {sku} = usageOptionData;

    if (sku.includes('Single-Vision')) {
        return 'Single Vision';
    }

    return sku === '' ? 'Frame' : sku;
};

export const formatStringFromUrl = (url: string) => {
    if (url) {
        return url.split('.')[1] ? url : `${url}.html`;
    }
    return '';
};

export const getFrameUrl = (product: ProductChildrenType) => formatStringFromUrl(product.url);
export const getStockLevel = (product: ProductChildrenType) => {
    const {stock} = product;

    if (!stock) return 'N/A';
    return STOCK_STATUSES_FOR_ANALYTICS[stock.status];
};

export const getRotation = (product: ProductChildrenType) => {
    const rotationsMap = {
        '180rotation': '180',
        '360rotation': '360',
    };
    const rotation = Object.keys(product.media).find(item => rotationsMap[item]);
    return rotation ? rotationsMap[rotation] : '';
};
