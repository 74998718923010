import React, {FC} from 'react';

import flags from 'constants/flags/flags';
import {PLAN_FRAMES_TYPE} from 'constants/plan';
import {FilteredPlanItemType} from 'libs/hooks/useFilteredPlan';

import {PlanTableRow} from '../planTableRow';
import s from './planTable.scss';

export type PropsType = {
    title: string;
    rows: FilteredPlanItemType;
};

export const PlanTable: FC<PropsType> = ({title, rows}) => {
    const framesWithoutCL = rows.filter(
        ({description}) => description !== PLAN_FRAMES_TYPE.contacts,
    );

    const frames = flags.dUHCCL.isEnabled() ? rows : framesWithoutCL;

    return frames.length ? (
        <div className={s.table}>
            <p className={s.title}>{title}</p>
            <table>
                <tbody>
                    {frames.map(row =>
                        row ? <PlanTableRow key={row.description} {...row} /> : null,
                    )}
                </tbody>
            </table>
        </div>
    ) : null;
};
