import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';
import {AxiosError} from 'axios';

import {
    RMA_STATES,
    RMA_PAGES,
    RMA_REQUEST_STATUSES,
    RMA_ERROR_STATUSES,
} from '../../constants/common';
import {changeRmaState, changeRmaStep, changeRmaPage} from '../actions/actions';
import {RmaResponseType} from '../../types';
import {firstStep} from './firstStep/firstStep';
import {secondStep} from './secondStep/secondStep';
import {thirdStep} from './thirdStep/thirdStep';
import {fourthStep} from './fourthStep/fourthStep';
import {summaryStep} from './summaryStep/summaryStep';

/**
 * Reducer, which save rma order increment id.
 */
export const orderId = createReducer({}, '').on(
    `${changeRmaState}_FULFILLED`,
    (_, {data: response}: RmaResponseType) => response.incrementId,
);

/**
 * Reducer, which change current rma state.
 */
export const currentState = createReducer({}, RMA_STATES.IDLE)
    .on(`${changeRmaState}_FULFILLED`, (state, {data: response}: RmaResponseType) =>
        RMA_ERROR_STATUSES.includes(response.state) ? state : response.state,
    )
    .on(`${changeRmaStep}`, (_, state: RMA_STATES) => state);

/**
 * Reducer, which change previous rma state.
 */
export const previousState = createReducer({}, RMA_STATES.IDLE).on(
    `${changeRmaState}_FULFILLED`,
    (state, {data: response}: RmaResponseType) => response.previousState,
);

/**
 * Reducer, which change current rma page.
 */
export const page = createReducer({}, RMA_PAGES.MAIN)
    .on(`${changeRmaState}_FULFILLED`, (state, {data: response}: RmaResponseType) =>
        RMA_ERROR_STATUSES.includes(response.state) ? state : response.page,
    )
    .on(`${changeRmaPage}`, (_, state: RMA_PAGES) => state);

/**
 * Reducer, which save error message when request is rejected.
 */
export const errorMessages = createReducer({}, '')
    .on(
        `${changeRmaState}_REJECTED`,
        (_, {error: {response}}: {error: AxiosError<{message?: string}>}) => {
            // This is necessary for borderline situations, when, for unknown reasons, a full php tracelog comes from the request response
            if (!response?.data?.message || response.data.message.length > 65)
                return 'Something went wrong. Please try again later.';
            return response.data.message;
        },
    )
    .on(`${changeRmaState}_FULFILLED`, (_, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.ERROR_ORDER_RETURNED:
            case RMA_STATES.ERROR_ORDER_NOT_FOUND:
            case RMA_STATES.ERROR_ITEM_RETURNED:
            case RMA_STATES.ERROR_STATUS_HAVE_PROBLEM:
            case RMA_STATES.ERROR_ITEMS_HAS_DIFFERENT_TYPES:
                return response.data.message;
            default:
                return '';
        }
    });

/**
 * Reducer, which change status of rma request.
 */
export const requestStatus = createReducer({}, RMA_REQUEST_STATUSES.IDLE)
    .on(`${changeRmaState}_FULFILLED`, () => RMA_REQUEST_STATUSES.FULFILLED)
    .on(`${changeRmaState}`, () => RMA_REQUEST_STATUSES.PENDING)
    .on(`${changeRmaState}_REJECTED`, () => RMA_REQUEST_STATUSES.REJECTED);

/**
 * General rma improved reducer.
 */
export const rmaReducer = combineReducers({
    orderId,
    currentState,
    previousState,
    page,
    errorMessages,
    requestStatus,
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    summaryStep,
});

export const rma = (state, action) =>
    // reset rma on SET_PAGE
    // eslint-disable-next-line no-undefined
    rmaReducer(action.type === 'SET_PAGE' ? undefined : state, action);
