import {ELIGIBLE_MESSAGE} from 'features/constants';
import {renderMessage} from './planMessage';
import React, {FC} from 'react';
import s from './planMessage.scss';
import {formatString} from '@optimaxdev/utils';

export type PropsType = {
    isUHCBenefits: boolean;
    allowance: number | undefined;
    message: string;
    isFlatBenefits: boolean;
};

export const MemberMessage: FC<PropsType> = ({
    isUHCBenefits,
    allowance,
    isFlatBenefits,
    message,
}) => {
    return isUHCBenefits ? (
        <>
            {isFlatBenefits && allowance ? (
                <span>
                    {formatString(ELIGIBLE_MESSAGE.withFlatBenefit, {
                        allowance: allowance.toString(),
                    })}
                </span>
            ) : (
                renderMessage(message)
            )}
        </>
    ) : (
        <span className={s.withoutEligible}>
            {renderMessage(ELIGIBLE_MESSAGE.withoutEligibleUhcMember)}
        </span>
    );
};
