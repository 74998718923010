/* eslint-disable max-lines */
// @flow
import {createActions, handleActions, combineActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';
import {assocPath} from 'ramda';
import FormData from 'form-data';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import {adapterTryOnData} from './utils';
import type {TryOnType} from './tryOnType';

export const defaultState = {
    leftPupilCord: {
        x: 110,
        y: 130,
    },
    rightPupilCord: {
        x: 185,
        y: 130,
    },
    isOpen: false,
    parentId: 0,
    productId: 0,
    activeAllFrames: false,
    currentImage: {
        id: '',
    },
    imagesTryOn: [],
    image: '',
    activeStep: '',
    adjustValues: {
        pd: 62,
        zoom: 1,
    },
    isProcessing: false,
};

/**
 * TryOn actions
 */
export const {
    openTryOn,
    closeTryOn,
    setFramesMode,
    getImagesTryOn,
    setCurrentImageTryOn,
    saveImageTryOn,
    activeStepTryOn,
    updateAdjustValue,
    setLeftPupilCord,
    setRightPupilCord,
    sendDataTryon,
    removeUserPhoto,
    setUploadType,
    uploadImageTryon,
} = createActions({
    OPEN_TRY_ON: (
        parentId: number,
        productId: number,
    ): ActionType<{parentId: number, productId: number}> => ({parentId, productId}),
    CLOSE_TRY_ON: (): ActionType<> => {},
    SET_UPLOAD_TYPE: (uploadType: string) => uploadType,
    SET_FRAMES_MODE: (activeAllFrames: boolean): ActionType<boolean> => activeAllFrames,
    GET_IMAGES_TRY_ON: (): ActionType<AxiosResponse> => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.GET_IMAGES_TRY_ON}`,
        },
    }),
    REMOVE_USER_PHOTO: (photoId: number): ActionType<AxiosResponse> => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.REMOVE_USER_PHOTO}/${photoId}`,
        },
    }),
    SET_CURRENT_IMAGE_TRY_ON: currentImage => currentImage,
    SAVE_IMAGE_TRY_ON: image => image,
    ACTIVE_STEP_TRY_ON: step => step,
    UPDATE_ADJUST_VALUE: (key, value) => ({key, value}),
    SET_LEFT_PUPIL_CORD: cord => cord,
    SET_RIGHT_PUPIL_CORD: cord => cord,

    SEND_DATA_TRYON: (
        tryonData: TryOnType,
        pd: number,
        productData: {categoryId: number, productId: number},
    ): ActionType<AxiosResponse> => {
        const data = new FormData();

        Object.entries(adapterTryOnData(tryonData, productData, pd)).forEach(([key, value]) => {
            data.append(key, value);
        });

        return {
            request: {
                method: 'POST',
                headers: {'Content-Type': 'multipart/form-data', Authorization: ''},
                url: `/backend/${ENDPOINTS.TRYON_SET_ADJUST}`,
                data,
            },
        };
    },
    UPLOAD_IMAGE_TRYON: (image: string): ActionType<AxiosResponse> => {
        const formData = new FormData();

        formData.append('image', image);
        formData.append('send', '2');

        return {
            request: {
                method: 'POST',
                headers: {'Content-Type': 'multipart/form-data', Authorization: ''},
                url: `/backend/${ENDPOINTS.TRYON_UPLOAD_IMAGE}`,
                data: formData,
            },
        };
    },
});

/**
 * TryOn reducer
 */
export const tryOn = handleActions(
    {
        [openTryOn]: (state: TryOnType, {payload}): ActionType<typeof openTryOn> => ({
            ...state,
            parentId: payload.parentId,
            productId: payload.productId,
            isOpen: true,
        }),
        [closeTryOn]: (state: TryOnType): ActionType<typeof closeTryOn> => ({
            ...state,
            isOpen: false,
        }),
        [setFramesMode]: (state: TryOnType, {payload: activeAllFrames}): TryOnType => ({
            ...state,
            activeAllFrames,
        }),
        [setUploadType]: (state: TryOnType, {payload: uploadType}): TryOnType => ({
            ...state,
            uploadType,
        }),
        [getImagesTryOn]: (state): TryOnType => ({...state, isProcessing: true}),
        [`${getImagesTryOn}${REJECTED}`]: (state): TryOnType => ({...state, isProcessing: false}),
        [`${getImagesTryOn}${FULFILLED}`]: (
            state,
            {payload: {data}}: ActionType<typeof getImagesTryOn>,
        ) => ({
            ...state,
            imagesTryOn: data || [],
            isProcessing: false,
        }),
        [setCurrentImageTryOn]: (state, {payload: currentImage}): TryOnType => ({
            ...state,
            currentImage,
        }),
        [saveImageTryOn]: (state, {payload: image}): TryOnType => ({
            ...state,
            image,
        }),
        [activeStepTryOn]: (state, {payload: activeStep}): TryOnType => ({
            ...state,
            activeStep,
        }),
        [updateAdjustValue]: (state, {payload}) => ({
            ...state,
            adjustValues: {...assocPath(payload.key.split('.'), payload.value, state.adjustValues)},
        }),
        [setLeftPupilCord]: (state, {payload: leftPupilCord}): TryOnType => ({
            ...state,
            leftPupilCord: {...leftPupilCord},
        }),
        [setRightPupilCord]: (state, {payload: rightPupilCord}): TryOnType => ({
            ...state,
            rightPupilCord: {...rightPupilCord},
        }),
        [sendDataTryon]: (state): TryOnType => ({...state, isProcessing: true}),
        [combineActions(`${sendDataTryon}${REJECTED}`, `${sendDataTryon}${FULFILLED}`)]: (
            state,
        ): TryOnType => ({...state, isProcessing: false}),
        [uploadImageTryon]: (state): TryOnType => ({...state, isProcessing: true}),
    },
    defaultState,
);
