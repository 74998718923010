import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ApplicationStoreType} from 'constants/typescript/types';
import {LINK_PAGE} from 'constants/links';
import {NewPasswordType} from 'reducers/newPassword';
import {getTokens} from 'reducers/oauth2';

import s from './newPasswordSetPopup.scss';

export type PropsType = {
    newPassword: NewPasswordType;
    getTokens: typeof getTokens;
};

export const NewPasswordSetPopup: FC<PropsType> = ({newPassword, getTokens}) => {
    const navigate = useNavigate();
    /**
     * Clear url token data and login with new password after close popup
     */
    useEffect(() => {
        return () => {
            navigate(LINK_PAGE.HOME);
            getTokens(newPassword.email, newPassword.password, false);
        };
    });

    return (
        <div className={s.wrap}>
            <p className={s.message}>Thank you, your password has been change.</p>
        </div>
    );
};

/* istanbul ignore next */
export const mapStateToProps = (store: ApplicationStoreType) => ({
    newPassword: store.newPassword,
});

export const mapDispatchToProps = {getTokens};

export const NewPasswordSetPopupConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewPasswordSetPopup);
