// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';

import {SITE_ID} from 'constants/store';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import type {ActionCreatorType} from 'constants/flow/flowTypes';
import {Status} from 'constants/reducerStatus';
import {ENDPOINTS} from 'constants/endpoints';
import {API_URL_CUSTOMER} from 'constants/api';

type PasswordType = {errorMessage: string, status: string};
export const defaultState = {errorMessage: '', status: ''};

export const {forgotPassword, clearError} = createActions({
    FORGOT_PASSWORD: (email: string): ActionType<AxiosResponse> => {
        return {
            request: {
                method: 'POST',
                baseURL: API_URL_CUSTOMER,
                url: ENDPOINTS.FORGOT_PASSWORD,
                data: {
                    email,
                    websiteId: SITE_ID,
                },
            },
        };
    },
    CLEAR_ERROR: (): ActionType<> => {},
});

/**
 * Password reducer
 */
export const password = handleActions(
    {
        [`${forgotPassword}${REJECTED}`]: (
            state: PasswordType,
            {
                payload: {
                    error: {response},
                },
            }: ActionCreatorType<typeof forgotPassword>,
        ): PasswordType => ({
            ...state,
            errorMessage: response.data.message,
            status: Status.Rejected,
        }),
        [`${forgotPassword}${FULFILLED}`]: (state: PasswordType): PasswordType => ({
            ...state,
            errorMessage: '',
            status: Status.Fulfilled,
        }),
        [clearError]: (): PasswordType => defaultState,
    },
    defaultState,
);
