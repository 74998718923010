import React, {PureComponent, ReactElement, SyntheticEvent} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

import {
    LINK_PAGE,
    LINK_MOST_POPULAR,
    LINK_PROGRESSIVE_LENSES,
    LINK_EYEGLASSES_COLLECTION,
    LINK_PRESCRIPTION_SUNGLASSES,
    LINK_DESIGNER_GLASSES,
} from 'constants/links';
import {DropDownItems} from 'components/__helpers/header/dropDownItems/dropDownItems';
import {WishListBtnConnected} from 'components/__helpers/header/wishListBtn/wishListBtn';
import {CartBtnConnected} from 'components/__helpers/header/cartBtn/cartBtn';
import {SearchBoxConnected} from 'components/__helpers/header/searchBox/searchBox';
import {Icon} from 'components/__helpers/icon/icon';
import {LoginPopUpShownEvent} from 'libs/analytics/loginPopUp/GeneralInteractionEvents';
import flags from 'constants/flags/flags';

import s from './mainMenu.scss';

export type PropsType = {
    isSticky: boolean;
    isLoggedIn: boolean;
};

export type StateType = {
    dropDownName: string;
};

const MAIN_MENU_WITH_CONTACTS = [
    {name: 'Eyeglasses', dropdownMenuLink: LINK_EYEGLASSES_COLLECTION},
    {name: 'Sunglasses', dropdownMenuLink: LINK_PRESCRIPTION_SUNGLASSES},
    {name: 'Progressive', link: LINK_PROGRESSIVE_LENSES},
    {name: 'Contacts', dropdownMenuLink: LINK_PAGE.CONTACT_LENSES},
    {name: 'Most Popular', link: LINK_MOST_POPULAR},
    {name: 'Brands', dropdownMenuLink: LINK_DESIGNER_GLASSES},
];

const MAIN_MENU = [
    {name: 'Eyeglasses', dropdownMenuLink: LINK_EYEGLASSES_COLLECTION},
    {name: 'Sunglasses', dropdownMenuLink: LINK_PRESCRIPTION_SUNGLASSES},
    {name: 'Progressive', link: LINK_PROGRESSIVE_LENSES},
    {name: 'Most Popular', link: LINK_MOST_POPULAR},
    {name: 'Brands', dropdownMenuLink: LINK_DESIGNER_GLASSES},
];

/**
 * Component MainMenu
 *
 * @param {menuItemName} menuItemName menu item name for analytics
 */
export class MainMenu extends PureComponent<PropsType, StateType> {
    state = {
        dropDownName: '',
    };

    actualCloseTimer: number | null = null;

    /**
     * Unset timer
     *
     * @memberof MainMenu
     */
    resetActualMenuCloseTimer() {
        const {actualCloseTimer} = this;
        if (actualCloseTimer !== null) {
            clearTimeout(actualCloseTimer);
            this.actualCloseTimer = null;
        }
    }

    /**
     * Set the current opened menu item name
     * and reset timer used for closing of menu
     *
     * @param {string} name - name of a menu item
     * @memberof MainMenu
     */
    setDropDownMenuOpenNameAndResetTimer(name: string) {
        this.setState({dropDownName: name});
        this.resetActualMenuCloseTimer();
    }

    /**
     * Set "name" state
     *
     * @param {SyntheticEvent<HTMLElement>} event - onMouseOver event
     */
    setDropDownMenu = (event: SyntheticEvent<HTMLElement>) => {
        const {name} = event.currentTarget.dataset;
        this.setDropDownMenuOpenNameAndResetTimer(name || '');
    };

    /**
     * Set "name" to empty string
     */
    closeDropDownMenu = () => {
        if (!this.actualCloseTimer) {
            this.actualCloseTimer = window.setTimeout(() => {
                this.setDropDownMenuOpenNameAndResetTimer('');
            }, 200);
        }
    };

    /**
     * Triggered on component is going to unmout from the DOM
     *
     * @memberof MainMenu
     */
    componentWillUnmount() {
        this.setDropDownMenuOpenNameAndResetTimer('');
    }

    analyticsLoginPopUpHandler = (menuItemName: string) => {
        if (!this.props.isLoggedIn) {
            LoginPopUpShownEvent(['Category_Name'], menuItemName);
        }
    };

    /**
     * Render jsx to html
     *
     * @returns {ReactElement} Rendered react component
     */
    render(): ReactElement {
        const {isSticky} = this.props;
        const {dropDownName} = this.state;

        const menuItems = flags.dUHCCL.isEnabled() ? MAIN_MENU_WITH_CONTACTS : MAIN_MENU;

        return (
            <div className={classNames(s.container, {[s.fixed]: isSticky})}>
                <div className={s.logoBox}>
                    <Link className={s.logo} to={LINK_PAGE.HOME} aria-label="home page">
                        <Icon name="logo" />
                    </Link>
                </div>
                <nav className={s.menu} onMouseLeave={this.closeDropDownMenu}>
                    {menuItems.map(item => (
                        <div
                            className={s.item}
                            key={item.name}
                            onClick={() => this.analyticsLoginPopUpHandler(item.name)}
                        >
                            {item.link ? (
                                <p
                                    className={s.link}
                                    onMouseOver={this.closeDropDownMenu}
                                    onFocus={this.closeDropDownMenu}
                                >
                                    <Link to={item.link}>{item.name}</Link>
                                </p>
                            ) : (
                                <p
                                    className={s.link}
                                    data-name={item.name}
                                    onMouseOver={this.setDropDownMenu}
                                    onFocus={this.setDropDownMenu}
                                >
                                    <Link to={item.dropdownMenuLink || ''}>{item.name}</Link>
                                </p>
                            )}
                        </div>
                    ))}
                    {menuItems.map(item =>
                        item.link ? null : (
                            <div
                                key={item.name}
                                data-name={item.name}
                                onMouseEnter={this.setDropDownMenu}
                                onClick={() => this.analyticsLoginPopUpHandler(item.name)}
                            >
                                <AnimateHeight
                                    key={item.name}
                                    duration={300}
                                    height={dropDownName === item.name ? 'auto' : 0}
                                    className={s.dropDownItem}
                                    animateOpacity
                                >
                                    <DropDownItems name={item.name} />
                                </AnimateHeight>
                            </div>
                        ),
                    )}
                </nav>
                <SearchBoxConnected />
                <div className={s.buttons}>
                    <Link to={LINK_PAGE.WISH_LIST} aria-label="wish list">
                        <WishListBtnConnected />
                    </Link>
                    <Link to={LINK_PAGE.CART} aria-label="cart page">
                        <CartBtnConnected className={s.cartBtn} />
                    </Link>
                </div>
            </div>
        );
    }
}
