// @flow
import {createActions, handleActions, combineActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';

import {API_URL} from 'constants/api';
import {FULFILLED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import type {WishListType} from './wishListType';

export const defaultState = {
    ids: {},
    activeTab: 'frame',
};

export const {
    getWishList,
    addToWishList,
    removeFromWishList,
    addToWishListApi,
    removeFromWishListApi,
    clearWishList,
    clearWishListApi,
    setActiveTab,
} = createActions({
    GET_WISH_LIST: (): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            baseURL: API_URL,
            method: 'GET',
            url: `${ENDPOINTS.WISH_LIST}/`,
        },
    }),
    ADD_TO_WISH_LIST: (id: number): ActionType<number> => id,
    REMOVE_FROM_WISH_LIST: (id: number): ActionType<number> => id,
    ADD_TO_WISH_LIST_API: (id: number): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            baseURL: API_URL,
            method: 'POST',
            url: `${ENDPOINTS.WISH_LIST}`,
            data: [id],
        },
    }),
    REMOVE_FROM_WISH_LIST_API: (id: number): ActionType<AxiosResponse> => ({
        request: {
            baseURL: API_URL,
            headers: {Authorization: ''},
            method: 'DELETE',
            url: `${ENDPOINTS.WISH_LIST}/${id}`,
        },
    }),
    CLEAR_WISH_LIST: (): ActionType<> => {},
    CLEAR_WISH_LIST_API: (): ActionType<AxiosResponse> => ({
        request: {
            headers: {Authorization: ''},
            baseURL: API_URL,
            method: 'DELETE',
            url: `${ENDPOINTS.WISH_LIST}/`,
        },
    }),
    SET_ACTIVE_TAB: (activeTab: boolean): ActionType<string> => activeTab,
});

export const clearWishListAction = combineActions(clearWishList, clearWishListApi);

/**
 * Wish list reducer
 *
 * @returns {WishListType} state - next state
 */
export const wishList = handleActions(
    {
        [`${getWishList}${FULFILLED}`]: (
            state: WishListType,
            {payload}: ActionType<typeof getWishList>,
        ): WishListType => ({
            ...state,
            ids: {...payload.data.reduce((obj, item) => ({...obj, [item.productId]: true}), {})},
        }),
        [addToWishList]: (
            state: WishListType,
            {payload: productId}: ActionType<typeof addToWishList>,
        ): WishListType => ({
            ...state,
            ids: {...state.ids, [productId]: true},
        }),
        [removeFromWishList]: (
            state: WishListType,
            {payload: productId}: ActionType<typeof removeFromWishList>,
        ): WishListType => ({
            ...state,
            ids: {
                ...Object.keys(state.ids)
                    .filter(key => key !== String(productId))
                    .reduce((obj, id) => ({...obj, [id]: true}), {}),
            },
        }),
        [clearWishListAction]: (): WishListType => defaultState,
        [setActiveTab]: (state: WishListType, {payload: activeTab}): WishListType => ({
            ...state,
            activeTab,
        }),
    },
    defaultState,
);
