// @flow
import {combineReducers} from 'redux';

import {prescriptionsScheme} from './prescriptionScheme';
import {prescriptionSendBy} from './prescriptionSendBy';
import {prescriptionOptions} from './prescriptionOptions';
import {configure} from './configure';
import {wizardFeature} from './wizardFeature';

export const wizard = combineReducers({
    prescriptionsScheme,
    prescriptionSendBy,
    prescriptionOptions,
    configure,
    wizardFeature,
});
