import {createReducer} from 'redux-act';

import {summaryDataAdapter} from '../../../libs/';
import {RMA_STATES} from '../../../constants/common';
import {changeRmaState} from '../../actions/actions';
import {RmaResponseType, RmaDataType} from '../../../types';

export const initialState: Partial<RmaDataType['summaryStep']> = {};

/**
 * Reducer rma improved summary.
 */
export const summaryStep = createReducer({}, initialState).on(
    `${changeRmaState}_FULFILLED`,
    (state, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.SUMMARY_RETURNS:
            case RMA_STATES.SUMMARY_CANCELLATION:
                return {...state, ...summaryDataAdapter(response.data)};

            default:
                return state;
        }
    },
);
