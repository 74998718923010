import React, {FC} from 'react';

import {Feature} from 'components/feature/feature';
import {BenefitType} from 'libs/hooks/usePlanRequest';

import s from './planAllowanceRow.scss';

export type PropsType = {
    flatAllowance: boolean;
    frameAllowanceForCL?: BenefitType | null;
};

export const PlanAllowanceTitle: FC<PropsType> = ({flatAllowance, frameAllowanceForCL}) => {
    return (
        <p className={s.tableTitle}>
            <Feature
                flag="dUHCCL"
                fallback={flatAllowance ? 'Allowance Balance' : 'Frame Allowance'}
            >
                {flatAllowance && !frameAllowanceForCL
                    ? 'Allowance Balance'
                    : 'Frames and Contacts Allowance'}
            </Feature>
        </p>
    );
};
