import {persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

// wizard ms
import {wizardMs} from 'features/wizard/store/reducers';
import {rma} from 'pages/rma/store/reducers';
// information about current page
import {contacts} from 'features/contacts/reducers';

import {route} from './route/route';
// app
import {storeInfo} from './store/storeInfo';
// CMS
import {cmsPopup} from './cms/cmsPopup/cmsPopup';
import {dialog} from './dialog';
import {cmsPage} from './cms/page/page';
// Customer
import {customer} from './customer/customer';
// Wizard
import {wizard} from './wizard';
// oauth2
import {oauth2Data} from './oauth2/oauth2';
// user
import {user} from './user/user';
import {address} from './address/address';
import {addressEdit} from './addressEdit/addressEdit';
import {prescription} from './prescription';
import {prescriptionEdit} from './prescriptionEdit';
// category
import {category} from './category/category';
import {categoryItems} from './categoryItems/categoryItems';
import {categoryColors} from './categoryColors/categoryColors';
import {categoryFilters} from './categoryFilters/categoryFilters';
import {categoryAppliedFilters} from './categoryAppliedFilters';
import {categorySort} from './categorySort/categorySort';
import {sizeCalculator} from './sizeCalculator/sizeCalculator';
// product
import {product} from './product';
// wish list
import {wishList} from './wishList/wishList';
import {wishListItems} from './wishListItems/wishListItems';
// try on
import {tryOn} from './tryOn/tryOn';
// contact us
import {contactUsForm} from './contactUsForm/contactUsForm';
// cart
import {cart} from './cart/cart';
import {cartProducts} from './cartProducts/cartProducts';
// checkout
import {country} from './country/country';
import {order} from './order';
import {shipping} from './shipping/shipping';
import {payment} from './payment/payment';
import {creditCard} from './creditCard/creditCard';
// recommended and also products
import {additionalProducts} from './additionalProducts';
// my account
import {myPlan} from './myPlan';
import {ordersInfo} from './ordersInfo';
import {tracking} from './tracking';
import {accountSettings} from './accountSettings';
import {formData} from './formData';
import {password} from './password';
import {newPassword} from './newPassword';
import {offsetTop} from './offsetTop';
import {bestSellersItems} from './bestSellersItems';
import {widgets} from './widgets';
import {storeCredit} from './storeCredit';
import {screenReducer} from './thankYou/thankYou';

// contact lenses

export const persistSessionConfig = (name: string) => ({
    key: `uvp-session-${name}`,
    storage: storageSession,
});

export const sessionReducers = {
    addressEdit,
    categoryAppliedFilters,
    category,
    categoryItems,
    categoryColors,
    categoryFilters,
    order,
    payment,
    prescriptionEdit,
    shipping,
    wishList,
};

export type SessionReducersType = typeof sessionReducers;

export const persistedReducers = (): SessionReducersType => {
    Object.keys(sessionReducers).forEach(
        key =>
            (sessionReducers[key] = persistReducer(
                persistSessionConfig(key),
                sessionReducers[key],
            )),
    );
    return sessionReducers;
};

export const reducers = {
    accountSettings,
    additionalProducts,
    address,
    bestSellersItems,
    cart,
    cartProducts,
    categorySort,
    cmsPage,
    cmsPopup,
    contactUsForm,
    country,
    creditCard,
    customer,
    dialog,
    formData,
    myPlan,
    newPassword,
    oauth2Data,
    offsetTop,
    ordersInfo,
    password,
    prescription,
    product,
    rma,
    route,
    sizeCalculator,
    storeInfo,
    tracking,
    tryOn,
    user,
    widgets,
    wishListItems,
    wizard,
    wizardMs,
    storeCredit,
    contacts,
    ...persistedReducers(),
    screenReducer,
};

export type ReducersType = typeof reducers;

export const rootReducer = reducers;
