import React from 'react';
import {SagaIterator} from 'redux-saga';
import {put, call, select, takeEvery, cancel} from 'redux-saga/effects';

import {FULFILLED} from 'constants/actionSuffix';
import {setPage} from 'reducers/route/index';
import {getPageData} from 'selectors/page/getPageData';
import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {canOpenWP, oauth2Selector} from 'selectors/oauth2Data/oauth2Data';
import {isUHCMember, isUHCBenefitsEligible, isUserStatusFulfilled} from 'selectors/user/user';
import {getUserDiscount} from 'reducers/user/user';
import {LoginPopupWrapperConnected} from 'features/loginPopup/components/loginPopupWrapper';
import {WelcomePopupConnected} from 'components/home/welcomePopup/welcomePopup';
import {logOutActions} from 'sagas/oauth2/oauth2';
import {
    getLogoutCookie,
    deleteLogoutCookie,
    setLogoutCookie,
} from 'libs/utils/logoutCookie/logoutCookie';
import {getWishListItems} from 'reducers/wishListItems';
import {getWishListIds} from 'selectors/wishList/wishList';

import flags from '../../constants/flags/flags';
import {closeMFAPopup} from '../../reducers/oauth2';

/**
 * Scroll page to top
 */
export function* scrollToTop(): SagaIterator {
    const {page, prevPage} = yield select(getPageData);

    if (page !== prevPage) {
        yield call([window, 'scrollTo'], 0, 0);
    }
}

/**
 * Open auth in popup
 */
export function* openAuthPopUp(): SagaIterator {
    const {access_token: accessToken} = yield select(oauth2Selector);

    if (window.location.hash.includes('login') && accessToken === '') {
        yield put(
            setCmsPopupVisibility(true, <LoginPopupWrapperConnected />, {
                withWrapper: false,
            }),
        );
    }
}

export const setPopUpWasShown = () => localStorage.setItem('popUpWasShown', '1');
/**
 * Trigger to render welcome popup
 */
export function* renderWelcomePopup(): SagaIterator {
    const canOpenWelcomePopup = yield select(canOpenWP);
    const {page} = yield select(getPageData);
    if (canOpenWelcomePopup && page !== 'cart') {
        yield put(
            setCmsPopupVisibility(true, <WelcomePopupConnected />, {
                className: 'welcomePopup',
            }),
        );
        yield call(setPopUpWasShown);
    }
}

/**
 * Open welcome popup after log in
 */
export function* openWelcomePopup(): SagaIterator {
    const isMFAFlagEnabled = flags.mfaUHCGD.isEnabled();
    if (localStorage.getItem('popUpWasShown') || isMFAFlagEnabled) return;
    yield call(renderWelcomePopup);
}

/**
 * Open login popup through MFA flow
 */
export function* openMFAWelcomePopup(): SagaIterator {
    if (localStorage.getItem('popUpWasShown')) return;
    const isUserdataAvailable = yield select(isUserStatusFulfilled);
    if (isUserdataAvailable) {
        yield call(renderWelcomePopup);
    } else {
        yield takeEvery(`${getUserDiscount}${FULFILLED}`, renderWelcomePopup);
    }
}

/**
 * Temporary measure, Logout after success page for UHC members
 */
export function* logOutAfterUHCSuccess() {
    const isUhcMember = yield select(isUHCMember);
    const isUHCBenefits = yield select(isUHCBenefitsEligible);
    if (!isUhcMember || !isUHCBenefits) yield cancel();

    const cookieValue = yield select(getLogoutCookie);
    const {page} = yield select(getPageData);

    if (cookieValue) {
        yield call(logOutActions, null, true);
        yield call(deleteLogoutCookie);
    } else if (page === 'success') yield call(setLogoutCookie);
}

/**
 * Updates wishlist data only on wishlist page
 */
export function* updateWishList() {
    const {page} = yield select(getPageData);
    const wishListArray = yield select(getWishListIds);

    if (wishListArray.length && page === 'wishlist') {
        yield put(getWishListItems(wishListArray));
    }
}

/**
 * Begin of saga
 */
export function* appSaga(): SagaIterator {
    yield takeEvery(`${getUserDiscount}${FULFILLED}`, openWelcomePopup);
    yield takeEvery(closeMFAPopup, openMFAWelcomePopup);
    yield takeEvery(setPage, scrollToTop);
    yield takeEvery(setPage, openAuthPopUp);
    yield takeEvery(setPage, updateWishList);
    yield takeEvery(setPage, logOutAfterUHCSuccess);
}
