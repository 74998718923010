import {createAction, createReducer} from 'redux-act';
import {WizardTreeType} from '@optimaxdev/wizard-ms';

import {API_URL_WIZARD} from 'constants/api';
import {FULFILLED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';
import {ApplicationStoreType, RequestType} from 'constants/typescript/types';
import {setProduct} from 'reducers/product/product';

export type WizardType = {tree?: WizardTreeType; status: Status; previousProductId?: number};

export const initialState: WizardType = {status: Status.Idle};

/**
 * An action to fetch wizard by id and plan id and productId
 *
 * @returns {WizardTreeType} wizard - tree
 */
export const fetchWizard = createAction<number, number, number, RequestType>(
    'FETCH_WIZARD_MS',
    (wizardId: number, planId: number, productId: number): RequestType => ({
        request: {
            method: 'get',
            headers: {Authorization: ''},
            url: `/${wizardId}`,
            baseURL: API_URL_WIZARD,
            params: {planId, productId},
        },
    }),
);

/**
 * Wizard reducer (just cache) to store last fetched wizard by wizard id and plan id
 */
export const wizardData = createReducer<WizardType>(
    {
        [`${fetchWizard}${FULFILLED}`]: (state: WizardType): WizardType => ({
            ...state,
            status: Status.Fulfilled,
        }),
        [`${setProduct}`]: (state, {id}): WizardType => ({
            previousProductId: id,
            ...(id === state.previousProductId ? state : initialState),
        }),
    },
    initialState,
);

/**
 * Determines loaded wizard
 *
 * @param {ApplicationStoreType} store - store
 * @returns {boolean} flag is it loaded
 */
export const isWizardFulfilled = (store: ApplicationStoreType): boolean =>
    store.wizardMs.wizardData.status === Status.Fulfilled;
