import React from 'react';
import classnames from 'classnames';

import {Icon} from 'components/__helpers/icon/icon';

import s from '../loginPopup.scss';

import forgotPasswordStyles from './forgotPassword.scss';

/**
 * The component rendes success message for the user
 * after successfull submitting the recovery form.
 *
 * @export
 * @param {PropsType} props - props
 * @returns {React.ReactElement} - react component
 */
export function ForgotPasswordCongratulationsPopup(): React.ReactElement {
    return (
        <section className={forgotPasswordStyles.container}>
            <div className={forgotPasswordStyles.emailSentIconContainer}>
                <Icon name="emailSent" />
            </div>
            <h2 className={classnames(s.title, forgotPasswordStyles.header)}>
                Check your inbox and click the link we just sent you.
            </h2>
            <p className={classnames(s.text, forgotPasswordStyles.sentLinkNotification)}>
                The link will only be valid for 24 hours.
            </p>
        </section>
    );
}
