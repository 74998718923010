import {createAction, createReducer} from 'redux-act';

import {API_URL} from 'constants/api';
import {FULFILLED} from 'constants/actionSuffix';
import {RequestType, ResponseType} from 'constants/typescript/types';

import {CountryType, CountryItemType, CountryRegionItemType} from './countryType';

export const defaultState: CountryType = {
    list: [],
    regions: [],
    defaultCountry: 'US',
    selectedCountry: '',
    selectedRegion: 0,
};

export const getCountry = createAction<RequestType>('GET_COUNTRY', () => ({
    request: {
        baseURL: API_URL,
        method: 'get',
        url: 'directory/country',
    },
}));
export const getCountryRegions = createAction<string, RequestType>(
    'GET_COUNTRY_REGIONS',
    (id: string) => ({
        request: {
            baseURL: API_URL,
            method: 'get',
            url: `directory/country/${id}/region`,
        },
    }),
);
export const setCountry = createAction<string, string>('SET_COUNTRY', (id: string) => id);
export const setRegion = createAction<number, number>('SET_REGION', (id: number) => id);

/**
 * Country reducer
 */
export const country = createReducer<CountryType>(
    {
        [`${getCountry}${FULFILLED}`]: (
            state: CountryType,
            {data}: ResponseType<CountryItemType[]>,
        ): CountryType => ({
            ...state,
            list: data,
        }),
        [`${getCountryRegions}${FULFILLED}`]: (
            state: CountryType,
            {data}: ResponseType<CountryRegionItemType[] | Record<string, unknown>>,
        ): CountryType => ({
            ...state,
            list: state.list,
            regions: Array.isArray(data) ? data : [],
            selectedRegion: 0,
        }),
        [`${setCountry}`]: (state: CountryType, id: string): CountryType => ({
            ...state,
            selectedCountry: id,
            selectedRegion: 0,
            regions: [],
        }),
        [`${setRegion}`]: (state: CountryType, code: number): CountryType => ({
            ...state,
            selectedRegion: code,
        }),
    },
    defaultState,
);
