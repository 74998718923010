import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';

import flags from 'constants/flags/flags';
import {axiosClient} from 'libs/configureStore';
import {API_URL_CUSTOMER} from 'constants/api';
import {SITE_ID} from 'constants/store';
import {CLIENT_ID_UHC} from 'constants/clientId';
import {addExpiresDate} from 'reducers/oauth2';
import {ENDPOINTS} from 'constants/endpoints';
import {convertObjectToFormData} from 'libs/utils/utils';

import {LogInType, NonMemberRegistrationResponse} from './customerMsType';

export interface ICustomerMSUserSignUpCredentials {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
}

/**
 * Request for log in
 *
 * @param {string} email - user email
 * @param {string} password - user password
 * @param {string} phone - phone number
 * @returns {Promise<LogInType>} void response
 */
export const getToken = async (
    email: string,
    password: string,
    phone?: string,
): Promise<LogInType> => {
    const {data} = await axiosClient.post<LogInType>(
        `${API_URL_CUSTOMER}token`,
        convertObjectToFormData({
            grant_type: 'password',
            website_id: String(SITE_ID),
            client_id: CLIENT_ID_UHC,
            username: email,
            password,
            ...(phone && {phone}),
        }),
        {
            method: 'POST',
            url: 'token',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            transformResponse: [addExpiresDate],
            skipAuthRefresh: true,
            params: {
                '2fa': flags.mfaUHCGD.isEnabled() ? 'sms' : null,
            },
        } as AxiosAuthRefreshRequestConfig,
    );
    return data;
};

export const sendCode = async (code: string, account_uuid: string): Promise<LogInType> => {
    const {data} = await axiosClient.post<LogInType>(
        `${API_URL_CUSTOMER}token`,
        convertObjectToFormData({
            grant_type: 'verification_code',
            client_id: CLIENT_ID_UHC,
            website_id: String(SITE_ID),
            code,
            account_uuid,
        }),
        {
            method: 'POST',
            url: 'token',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            transformResponse: [addExpiresDate],
            skipAuthRefresh: true,
            params: {
                '2fa': flags.mfaUHCGD.isEnabled() ? 'sms' : null,
            },
        } as AxiosAuthRefreshRequestConfig,
    );
    return data;
};

/**
 * Request for sign up
 *
 * @param {ICustomerMSUserSignUpCredentials }customerSignUpCredentials - credentials for user registration
 * @returns {Promise<LogInType>} void response
 */
export const signUpCustomer = async (
    customerSignUpCredentials: ICustomerMSUserSignUpCredentials,
): Promise<NonMemberRegistrationResponse> => {
    const {data} = await axiosClient.post<NonMemberRegistrationResponse>(
        `${API_URL_CUSTOMER}registration`,
        {
            ...customerSignUpCredentials,
            registrationMethod: 'default',
            websiteId: SITE_ID,
            clientId: CLIENT_ID_UHC,
        },
        {
            params: {
                '2fa': flags.mfaUHCGD.isEnabled() ? 'sms' : null,
            },
        },
    );

    return data;
};

export interface PasswordRecoveryParametersType {
    email: string;
}

/**
 * Send a passwrod recovery request on the backend API
 *
 * @param {PasswordRecoveryParametersType} - parameters for recovering
 * @param {string} email - user email
 * @returns {Promise<void>} -response
 */
export const passwordRecovery = async ({email}: PasswordRecoveryParametersType): Promise<void> => {
    await axiosClient.post<void>(`${API_URL_CUSTOMER}${ENDPOINTS.FORGOT_PASSWORD}`, {
        email,
        websiteId: SITE_ID,
        clientId: CLIENT_ID_UHC,
    });
};
