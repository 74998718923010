// @flow
import {type SagaIterator} from 'redux-saga';
import {type ActionType} from 'redux-actions';
import {select, call, put, all, putResolve, takeEvery, throttle} from 'redux-saga/effects';

import {
    addAppliedFilter,
    removeAppliedFilter,
    clearAppliedFilters,
    setAppliedFilters,
    type AppliedFilterType,
} from 'reducers/categoryAppliedFilters';
import {getCategory} from 'reducers/category';
import {getCategoryItems, setCategoryPage, startGetCategoryData} from 'reducers/categoryItems';
import {getCategoryFilters, getAllFilters} from 'reducers/categoryFilters';
import {setSortOrder} from 'reducers/categorySort';
import {getPageData} from 'selectors/page/getPageData';
import {
    getCategoryData,
    getCategoryItemsData,
    getCategoryFiltersData,
    getCategorySortData,
} from 'selectors/category/category';
import {setPage} from 'reducers/route';
import {replaceAppliedFilter} from 'reducers/categoryAppliedFilters/categoryAppliedFilters';
import {
    addParamToUrl,
    removeParamFromUrl,
    replaceParamInUrl,
    getCategoryParams,
    removeAllCategoryParamsFromUrl,
    getUrlParameter,
} from 'libs/url';
import {SEARCH_CATEGORY_ID} from 'constants/category';

/**
 * Get all filters for category
 *
 * @returns {{}} category params
 */
export function* getAllCategoryFiltersIds(): SagaIterator {
    const {filtersIds: idsObj} = yield select(getCategoryFiltersData);

    if (Object.keys(idsObj).length === 0) yield putResolve(getAllFilters());

    const {filtersIds} = yield select(getCategoryFiltersData);

    return filtersIds;
}

/**
 * Get category params data
 *
 * @returns {{}} category params
 */
export function* getCategoryParamsData(): SagaIterator {
    const {dir, order} = yield select(getCategorySortData);

    yield all([call(replaceParamInUrl, 'dir', dir), call(replaceParamInUrl, 'order', order)]);

    const filtersIds = yield call(getAllCategoryFiltersIds);
    const {categoryParams, appliedFilters} = yield call(getCategoryParams, filtersIds);

    yield put(setAppliedFilters(appliedFilters));

    return categoryParams;
}

/**
 * Fetch category sort data
 */
export function* fetchCategorySortData(): SagaIterator {
    const categoryParams = yield call(getCategoryParamsData);
    const q = yield call(getUrlParameter, 'q');

    yield put(getCategoryItems(SEARCH_CATEGORY_ID, {...categoryParams, q}));
}

/**
 * Fetch category data
 */
export function* fetchCategoryData(): SagaIterator {
    const {params} = yield select(getPageData);
    const {url} = yield select(getCategoryData);
    const categoryParams = yield call(getCategoryParamsData);
    const categoryId = params.id || SEARCH_CATEGORY_ID;

    if (url !== window.location.pathname) {
        yield put(getCategory(categoryId));
    }

    const q = yield call(getUrlParameter, 'q');
    yield all([
        put(getCategoryItems(categoryId, {...categoryParams, q})),
        put(getCategoryFilters(categoryId, {...categoryParams, q})),
    ]);
}

/**
 * Fetch page data
 */
export function* getData(): SagaIterator {
    const {page} = yield select(getPageData);
    const {pathname, search} = window.location;
    const {categoryUrl} = yield select(getCategoryItemsData);

    if (['category', 'search'].includes(page) && categoryUrl !== `${pathname}${search}`) {
        yield put(startGetCategoryData());
    }
}

/**
 * Update page param data and call put getCategoryItems action
 */
export function* getNextCategoryPage(): SagaIterator {
    const {page} = yield select(getCategoryItemsData);
    const {params} = yield select(getPageData);

    yield call(replaceParamInUrl, 'p', String(page));

    const categoryParams = yield call(getCategoryParamsData);
    const q = yield call(getUrlParameter, 'q');
    yield put(getCategoryItems(params.id || SEARCH_CATEGORY_ID, {...categoryParams, q}));
}

/**
 * Update sort param data and put getCategoryItems action
 */
export function* changeCategorySort(): SagaIterator {
    const {params} = yield select(getPageData);
    const {dir, order} = yield select(getCategorySortData);

    yield all([
        call(removeParamFromUrl, 'p'),
        call(replaceParamInUrl, 'dir', dir),
        call(replaceParamInUrl, 'order', order),
    ]);

    const categoryParams = yield call(getCategoryParamsData);

    const q = yield call(getUrlParameter, 'q');
    yield put(getCategoryItems(params.id, {...categoryParams, q}));
}

/**
 * Update page param data and call category data saga
 */
export function* clearAllCategoryFilters(): SagaIterator {
    const {filtersIds} = yield select(getCategoryFiltersData);

    yield call(removeAllCategoryParamsFromUrl, filtersIds);
    yield put(startGetCategoryData());
}

/**
 * Update filters param data and call category data saga
 *
 * @param {ActionType<AppliedFilterType>} action - action
 */
export function* changeFilters(action: ActionType<AppliedFilterType>): SagaIterator {
    const {code, value} = action.payload;
    let paramAction;
    switch (action.type) {
        case String(addAppliedFilter):
            paramAction = addParamToUrl;
            break;
        case String(replaceAppliedFilter):
            paramAction = replaceParamInUrl;
            break;
        default:
            paramAction = removeParamFromUrl;
    }

    yield call(paramAction, code, String(value));
    yield call(removeParamFromUrl, 'p');
    yield put(startGetCategoryData());
}

/**
 * Begin of saga
 */
export function* categorySaga(): SagaIterator {
    yield takeEvery(setPage, getData);
    yield takeEvery(setCategoryPage, getNextCategoryPage);
    yield takeEvery([addAppliedFilter, replaceAppliedFilter, removeAppliedFilter], changeFilters);
    yield takeEvery(clearAppliedFilters, clearAllCategoryFilters);
    yield takeEvery(setSortOrder, changeCategorySort);
    yield throttle(2000, startGetCategoryData, fetchCategoryData);
}
