// @flow
import React, {PureComponent, type Node, createRef} from 'react';
import {connect} from 'react-redux';
import {Sticky, StickyContainer} from 'react-sticky';
import classNames from 'classnames';

import {type ApplicationStoreType} from 'constants/flow/flowTypes';
import {Icon} from 'components/__helpers/icon/icon';
import {closeDialog, DialogType} from 'reducers/dialog';
import {disableScroll} from 'libs/utils/disableScroll/disableScroll';

import s from './dialog.scss';

export type PropsType = {
    dialog: DialogType,
    closeDialog: typeof closeDialog,
};

const TOP_OFFSET = 74;

/**
 * Dialog component
 */
export class Dialog extends PureComponent<PropsType> {
    /**
     * Called after component was mounted for the first time
     */
    componentDidUpdate() {
        if (this.ref.current) {
            this.ref.current.addEventListener('scroll', this.dispatchScroll);
        }

        disableScroll(this.props.dialog.options.visibility);
    }

    ref = createRef<HTMLDivElement>();

    /**
     * Close dialog
     */
    closeDialog = () => {
        this.props.closeDialog();
        disableScroll(false);
        if (this.ref.current) {
            this.ref.current.removeEventListener('scroll', this.dispatchScroll);
        }
    };

    /**
     * Dispatch event scroll
     */
    dispatchScroll = () => {
        window.dispatchEvent(new Event('scroll'));
    };

    /**
     * Render jsx to html
     *
     * @returns {Node} Rendered react component
     */
    render(): Node {
        const {
            options: {title, isFull, visibility, isStickyHeader},
            content,
        } = this.props.dialog;

        if (!visibility) return null;

        return (
            <div
                className={classNames(s.wrap, {
                    [s.full]: isFull,
                })}
                role="dialog"
                ref={this.ref}
            >
                <StickyContainer>
                    {isStickyHeader && (
                        <Sticky topOffset={TOP_OFFSET}>
                            {({isSticky}) => (
                                <header
                                    className={classNames(s.header, {
                                        [s.sticky]: isSticky,
                                        [s.shadow]: isSticky && title.length > 0,
                                        [s.border]: title.length > 0 && !isSticky,
                                    })}
                                >
                                    {title.length > 0 && <h1 className={s.title}>{title}</h1>}
                                    <button className={s.close} onClick={this.closeDialog}>
                                        <Icon name="close" />
                                    </button>
                                </header>
                            )}
                        </Sticky>
                    )}
                    <main>{content}</main>
                </StickyContainer>
            </div>
        );
    }
}

export const mapStateToProps = (store: ApplicationStoreType) => ({
    dialog: store.dialog,
});

export const mapDispatchToProps = {
    closeDialog,
};

export const DialogConnected = connect(mapStateToProps, mapDispatchToProps)(Dialog);
