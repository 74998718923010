import React, {FC, useContext, useState} from 'react';

import {LoginPopupContext, LoginPopupContextType} from '../../context/loginPopupContext';
import {LoginPopupNames} from '../loginPopup';

import {SuccessLogin} from './successLogin/successLogin';
import {WaitForPhoneNumber} from './waitForPhoneNumber/waitForPhoneNumber';
import {WaitForCode} from './waitForCode/waitForCode';

export enum AUTH_STEPS {
    WAIT_FOR_PHONE_NUMBER = 'WAIT_FOR_PHONE_NUMBER',
    WAIT_FOR_CODE = 'WAIT_FOR_CODE',
    SUCCESS_LOGIN = 'SUCCESS_LOGIN',
}

type PropsType = {
    onRequestClose: () => void;
    setAnalyticsStep: (step: string) => void;
    setPopup: (popup: LoginPopupNames) => void;
};

export const TwoFactorAuth: FC<PropsType> = ({onRequestClose, setPopup, setAnalyticsStep}) => {
    const {canEditPhone} = useContext(LoginPopupContext) as LoginPopupContextType;
    const [step, setStep] = useState<AUTH_STEPS>(
        canEditPhone ? AUTH_STEPS.WAIT_FOR_PHONE_NUMBER : AUTH_STEPS.WAIT_FOR_CODE,
    );
    const authScreen = {
        WAIT_FOR_PHONE_NUMBER: (
            <WaitForPhoneNumber
                setPopup={setPopup}
                setStep={setStep}
                setAnalyticsStep={setAnalyticsStep}
            />
        ),
        WAIT_FOR_CODE: (
            <WaitForCode
                setStep={setStep}
                onRequestClose={onRequestClose}
                setAnalyticsStep={setAnalyticsStep}
            />
        ),
        SUCCESS_LOGIN: <SuccessLogin onRequestClose={onRequestClose} />,
    };
    return <>{authScreen[step]}</>;
};
