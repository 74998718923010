// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import {type AxiosPromise} from 'axios';
import {type Reducer} from 'redux';

import {FULFILLED, PENDING} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';

import type {ConfigureType} from './configureType';

export const defaultState = {
    isConfigure: false,
    status: PENDING,
    quoteItemId: 0,
    name: '',
    sku: '',
    qty: 0,
    total: 0,
    productId: 0,
    hydrophobic: false,
    insurance: false,
};

/**
 * Configure actions
 */
export const {
    wizard: {getConfigureData, clearConfigureData},
} = createActions({
    WIZARD: {
        /**
         * Get configure data
         *
         * @param {number} id - quote id
         * @returns {AxiosPromise} Action data
         */
        GET_CONFIGURE_DATA: (id: number): AxiosPromise => ({
            request: {
                method: 'GET',
                headers: {Authorization: ''},
                url: `/backend/${ENDPOINTS.CART_ITEM}/${id}`,
            },
        }),
        CLEAR_CONFIGURE_DATA: () => {},
    },
});

/**
 * Configure data for product page
 */
export const configure: Reducer<ConfigureType, string> = handleActions(
    {
        [`${getConfigureData}${FULFILLED}`]: (
            state: ConfigureType,
            {payload: {data}}: ActionType<typeof getConfigureData>,
        ): ConfigureType => ({
            ...state,
            ...data,
            isConfigure: true,
            status: FULFILLED,
        }),
        [getConfigureData]: (state: ConfigureType): ConfigureType => ({
            ...state,
            status: PENDING,
        }),
        [clearConfigureData]: () => defaultState,
    },
    defaultState,
);
