import {createAction, createReducer} from 'redux-act';
import {AxiosResponse} from 'axios';
import {createSelector} from 'reselect';

import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';
import {ApplicationStoreType, RequestType} from 'constants/typescript/types';
import {ENDPOINTS} from 'constants/endpoints';
import {Status} from 'constants/reducerStatus';
import {getPageData} from 'selectors/page/getPageData';
import {PageType} from 'reducers/route';
import {setPage} from 'reducers/route/route';

export const fetchQuoteItemV2 = createAction<number, RequestType>(
    'FETCH_QUOTE_ITEM_V2',
    (id: number) => ({
        request: {
            method: 'GET',
            headers: {Authorization: ''},
            url: `/backend/${ENDPOINTS.CART_ITEM_V2}/${id}`,
        },
    }),
);

export type ConfigureType = {
    status: Status;
    item?: QuoteItemV2Type;
    isConfigure: boolean;
};

export const initialState: ConfigureType = {status: Status.Idle, isConfigure: false};

/**
 * This reducer needs only to track pending status of add/update cart action.
 */
export const configure = createReducer<ConfigureType>({}, initialState)
    .on(
        `${fetchQuoteItemV2}_FULFILLED`,
        (state: ConfigureType, {data}: AxiosResponse<QuoteItemV2Type>) => ({
            ...state,
            item: data,
            status: Status.Fulfilled,
            isConfigure: true,
            planId: data.planId,
        }),
    )
    .on(`${setPage}`, () => initialState)
    .on(`${fetchQuoteItemV2}_PENDING`, (state: ConfigureType) => ({
        ...state,
        status: Status.Pending,
    }))
    .on(`${fetchQuoteItemV2}_REJECTED`, (state: ConfigureType) => ({
        ...state,
        status: Status.Rejected,
    }));

/**
 * Returns configure page state
 *
 * @param {ApplicationStoreType} store - redux store
 * @returns {ConfigureType} configure page state
 */
export const getConfigure = (store: ApplicationStoreType): ConfigureType =>
    store.wizardMs.configure;

/**
 * Return is configure page
 *
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - Is configure page
 */
export const isConfigurePage = createSelector(
    getPageData,
    ({page}: PageType): boolean => page === 'configureWizard',
);
