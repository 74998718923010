import {createAction, createReducer} from 'redux-act';
import FormData from 'form-data';

import {RequestType} from 'constants/typescript/types';
import {FULFILLED, REJECTED} from 'constants/actionSuffix';

import {CategoryMapType, OrderType} from './orderType';

export enum CHECKOUT_STEPS {
    'Shipping' = 1,
    'Payment' = 2,
    'Review' = 3,
}

export const defaultState: OrderType = {
    isProcessing: false,
    isPaymentStep: false,
    isAgree: false,
    shippingAddress: 0,
    billingAddress: 0,
    paymentMethod: '',
    orderId: '',
    errorMessage: '',
    categoryMap: {},
    currentStep: 0,
};

export const saveOrder = createAction<string, RequestType>('SAVE_ORDER', (data: string) => ({
    request: {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: '',
        },
        url: '/backend/checkout/onepage/saveOrder',
        data: `agreement[4]=true&payment[terms]=1&${data}`,
    },
}));
export const saveOrderAddresses = createAction<FormData, RequestType>(
    'SAVE_ORDER_ADDRESSES',
    (data: FormData) => ({
        request: {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: '',
            },
            url: '/backend/optimaxcheckout/address/save',
            data,
        },
    }),
);
export const setShippingAddress = createAction<number>('SET_SHIPPING_ADDRESS', (id: number) => id);
export const setBillingAddress = createAction<number>('SET_BILLING_ADDRESS', (id: number) => id);
export const setPaymentMethod = createAction<string>('SET_PAYMENT_METHOD', (code: string) => code);
export const setPaymentStep = createAction('SET_PAYMENT_STEP');
export const setShippingStep = createAction('SET_SHIPPING_STEP');
export const setAgreeFlag = createAction<boolean>('SET_AGREE_FLAG', (isAgree: boolean) => isAgree);
export const setOrderId = createAction<string>('SET_ORDER_ID', (orderId: string) => orderId);
export const setError = createAction<string>('SET_ERROR', (message: string) => message);
export const startPaymentStepSaga = createAction('START_PAYMENT_STEP_SAGA');
export const startPlaceOrderSaga = createAction('START_PLACE_ORDER_SAGA');
export const setProductCategories = createAction<CategoryMapType>(
    'SET_PRODUCT_CATEGORIES',
    (categoryMap: CategoryMapType) => categoryMap,
);
export const clearOrder = createAction('CLEAR_ORDER');

/**
 * Order reducer
 *
 * @returns {OrderType} state - next state
 */
export const order = createReducer<OrderType>(
    {
        [setShippingAddress.toString()]: (state, id): OrderType => ({
            ...state,
            shippingAddress: id,
            isProcessing: false,
        }),
        [setBillingAddress.toString()]: (state, id): OrderType => ({
            ...state,
            billingAddress: id,
        }),
        [setPaymentStep.toString()]: (state): OrderType => ({
            ...state,
            isPaymentStep: true,
            isProcessing: false,
            currentStep: CHECKOUT_STEPS.Payment,
        }),
        [setShippingStep.toString()]: (state): OrderType => ({
            ...state,
            isPaymentStep: false,
            isProcessing: false,
            isAgree: false,
            errorMessage: '',
            currentStep: CHECKOUT_STEPS.Shipping,
        }),
        [startPaymentStepSaga.toString()]: (state): OrderType => ({
            ...state,
            isProcessing: true,
        }),
        [startPlaceOrderSaga.toString()]: (state): OrderType => ({
            ...state,
            isProcessing: true,
        }),
        [setAgreeFlag.toString()]: (state, isAgree): OrderType => ({
            ...state,
            isAgree,
        }),
        [setPaymentMethod.toString()]: (state: OrderType, paymentMethod): OrderType => ({
            ...state,
            paymentMethod,
        }),
        [`${saveOrder}${FULFILLED}`]: (state: OrderType): OrderType => ({
            ...state,
            isProcessing: false,
        }),
        [saveOrder.toString()]: (state): OrderType => ({
            ...state,
            isProcessing: true,
            errorMessage: '',
        }),
        [`${saveOrder}${REJECTED}`]: (state): OrderType => ({
            ...state,
            isProcessing: false,
        }),
        [setOrderId.toString()]: (state, orderId): OrderType => ({
            ...defaultState,
            orderId,
        }),
        [setError.toString()]: (state, errorMessage): OrderType => ({
            ...state,
            errorMessage,
            isProcessing: false,
        }),
        [setProductCategories.toString()]: (state, categoryMap): OrderType => ({
            ...state,
            categoryMap,
            isProcessing: false,
        }),
        [clearOrder.toString()]: () => defaultState,
    },
    defaultState,
);
