import {combineReducers} from 'redux';

import {savedCustomerPrescriptions} from 'features/wizard/store/reducers/prescription/getSavedCustomerPrescriptions';
import {errors} from 'features/wizard/store/reducers/prescription/errors/errors';

import {prescription as values} from './prescription';

export const reducers = {
    values,
    errors,
    savedCustomerPrescriptions,
};

export const prescription = combineReducers(reducers);

export type PrescriptionReducerType = ReturnType<typeof prescription>;
