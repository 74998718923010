import {createAction, createReducer} from 'redux-act';
import {WizardValueType} from '@optimaxdev/wizard-ms';
import {createSelector} from 'reselect';

import {FULFILLED} from 'constants/actionSuffix';
import {Status} from 'constants/reducerStatus';
import {ApplicationStoreType, Maybe, RequestType} from 'constants/typescript/types';
import {ENDPOINTS} from 'constants/endpoints';
import {getSelectedColorSunglassesOrEyeglasses} from 'features/wizard/store/selectors/sunglassesUpsells/sunglassesUpsells';
import {getActiveProduct} from 'selectors/product/product';
import {MEDIA_URL} from 'constants/api';
import {ProductChildrenType} from 'reducers/product';

export type LensImagesType = {
    status: Status;
    list: Array<{image: string; id: number; sku: string}>;
};

export const initialState: LensImagesType = {status: Status.Idle, list: []};

/**
 * An action to fetch wizard by id and plan id
 *
 * @returns {LensImagesType} lens images - list
 */
export const fetchLensImages = createAction<number, RequestType>(
    'GET_LENS_IMAGES',
    (id: number): RequestType => ({
        request: {
            method: 'get',
            url: `/backend/${ENDPOINTS.WIZARD.GET_LENS_IMAGES}${id}/lens-images`,
        },
    }),
);

/**
 * Sunglasses or eyeglasses lens images
 */
export const lensImages = createReducer<LensImagesType>(
    {
        [`${fetchLensImages}${FULFILLED}`]: (state, {data}) => ({
            ...state,
            list: data,
            status: Status.Fulfilled,
        }),
    },
    initialState,
);

/**
 * Getting lens images from store
 *
 * @param {ApplicationStoreType} store - store
 * @returns {LensImagesType} LensImagesType - LensImagesType
 */
/* istanbul ignore next */
export const getLensImages = (store: ApplicationStoreType): LensImagesType['list'] =>
    store.wizardMs.lensImages.list;

/**
 * If user does not select any color or in list that we got from backend about each color does not contain image byt sku
 * we will return default url that we have in product media from front side
 * If user selects color we will return url selected image
 */
export const getActiveLensImageUrl = createSelector<
    ApplicationStoreType,
    LensImagesType['list'],
    Maybe<WizardValueType>,
    ProductChildrenType,
    string
>(
    getLensImages,
    getSelectedColorSunglassesOrEyeglasses,
    getActiveProduct,
    (images, colorSelected, activeProduct) => {
        const url = activeProduct.media.front ? `${MEDIA_URL}${activeProduct.media.front.src}` : '';
        if (activeProduct && !colorSelected) return url;
        return (
            images.find(element => element.sku === (colorSelected as WizardValueType).sku)?.image ||
            url
        );
    },
);
