/* eslint-disable max-lines */

// TODO Airat create folders for each event with related helpers
import {pathOr, isEmpty, pick, path, uniq} from 'ramda';
import {
    PurchaseActionFieldType,
    PurchaseProductType,
    getPrescriptionType,
    getProductType,
    emptyByDefault,
    EecBaseImpressionsType,
    EecProductImpressionsTypes,
    EecAddToCartUVPProductType,
    EecPurchaseProductType,
    pushDataLayer,
} from '@optimaxdev/analytics/desktop';
import {convertPriceEEC} from '@optimaxdev/utils';

import {
    getUsageSku,
    getLensType,
    getFrameUrl,
    getStockLevel,
    getRotation,
} from 'libs/analytics/helpers';
import {OrderItemFullType} from 'reducers/ordersInfo/ordersInfoType';
import {AdditionalProductType} from 'reducers/additionalProducts';
import {CategoryMapType} from 'reducers/order/orderType';
import {QuoteItemV2Type} from 'reducers/cart/addToCart/addToCartType';
import {CTP_SIMILAR_FRAMES} from 'features/similarFrames/analytics/analytics';
import {ProductChildrenType} from 'reducers/product';
import {PRODUCT_TYPES} from 'constants/product/product';
import {ProductTypesType} from 'reducers/product/productType';

type RawProductImpressionType = {
    name?: string;
    sku?: string;
    price?: number;
    category?: string;
    color?: string;
    brand?: string;
    size?: string;
    type?: ProductTypesType;
    contactLensTypeLabel?: string;
    contactLensType?: string;
};

/**
 * Prepares base impression data
 * for e-commerce events
 *
 * @param {RawProductImpressionType} data - product`s raw data
 * @param {number} pricePrecision - amount of zeros after comma
 * @returns {EecBaseImpressionsType} impression data - eec data with base fields
 * */
export const prepareBaseImpressionData = (
    data: RawProductImpressionType,
    pricePrecision = 2,
): EecBaseImpressionsType => ({
    name: data.name || '',
    id: data.sku || '',
    brand: data.brand || '',
    variant:
        data.type === PRODUCT_TYPES.CONTACT_LENSES
            ? `C|${data.contactLensTypeLabel || data.contactLensType}`
            : `${emptyByDefault(data.size)}|${emptyByDefault(data.color)}`,
    price: (data.price || 0).toFixed(pricePrecision),
    category: data.category || 'Non category page',
});
export const prepareTryOnColorChanged = ({items, colors, sizes, additionalDimension = {}}) =>
    items.map(product => ({
        ...prepareBaseImpressionData(
            {
                ...pick(['name', 'sku', 'price', 'category'], product),
                brand: path(['brand'], product),
                size: path(['frame', 'size', 'shortLabel'], product),
            },
            4,
        ),
        variant: `${path(['frame', 'size', 'shortLabel'], product)}|${path(
            ['color', 'label'],
            product,
        )}`,
        dimension11: getStockLevel(product),
        dimension31: product?.description?.length || 0,
        dimension34: product.genders.join(', '),
        dimension35: product?.media ? getRotation(product) : '',
        dimension36: product.price.toFixed(4),
        dimension37: 0,
        dimension39: 0,
        dimension40: getPath(product),
        dimension42: uniq(colors).length,
        dimension43: uniq(sizes).length,
        dimension45: emptyByDefault(product?.manufacturerValue) || '',
        dimension60: getProductType(product.type, product.isEligibleForRx),
        ...additionalDimension,
    }));
/**
 * Prepares impressions list
 * for EEC PDP Product event
 *
 * @param {AdditionalProductType[]} recentlyViewedProducts - List of recently viewed products
 * @returns {EecProductImpressionsTypes} impressions - List of product`s recommendations prepared for ecommerce analysis
 * */
export const preparePDPImpressionsData = (
    recentlyViewedProducts: AdditionalProductType[],
): EecProductImpressionsTypes =>
    recentlyViewedProducts.map((product, position) => ({
        ...prepareBaseImpressionData(
            {
                ...pick(['name', 'sku', 'price', 'category'], product),
                color: path(['color', 'label'], product),
                size: path(['frame', 'size', 'shortLabel'], product),
                brand: path(['brand', 'label'], product),
            },
            4,
        ),
        position: position + 1,
        list: 'Recently viewed in product',
        product_id: (product.id || '').toString(),
        dimension36: (product.finalPrice || 0).toFixed(4),
    }));

const getBadge = badge => (badge ? badge.label : '');

const getPath = product => path(['frame', 'size', 'shortLabel'], product) || '';

/**
 * Prepares detail product data
 * for EEC PDP product event
 *
 * @param {AdditionalProductType} product - current viewed product
 * @returns {EecPdpUVPProductType} data - product`s data prepared for ecommerce analysis
 * */
export const preparePDPProductData = (product: AdditionalProductType) => ({
    ...prepareBaseImpressionData(
        {
            ...pick(['name', 'sku', 'category'], product),
            color: path(['color', 'label'], product),
            size: path(['frame', 'size', 'shortLabel'], product),
            brand: path(['brand', 'label'], product),
            price: product.price,
            type: path(['type'], product),
            contactLensTypeLabel: path(['contactLensTypeLabel'], product),
        },
        4,
    ),
    dimension31: product?.description?.length || 0,
    dimension34: (product.genders || []).join(', '),
    dimension35: product.frame?.size.label === 'Large' ? '360' : '180',
    dimension36: (product.price || 0).toFixed(4),
    dimension37: 0,
    dimension39: 0,
    dimension40: getPath(product),
    dimension41: getBadge(product.badge),
    dimension42: Array.isArray(product.color) ? product.color : 1,
    dimension43: Array.isArray(product.frame?.size) ? product.color : 1,
    dimension44: getFrameUrl(product),
    dimension45: emptyByDefault(product?.manufacturerValue) || '',
    dimension60: getProductType(product.type, product.isEligibleForRx),
});

/**
 * Prepare the actionField data for sending to the eec purchase event
 *
 * @param {OrderItemFullType} order - order data
 * @param {string} coupons - Store credit coupons
 * @returns {PurchaseActionFieldType} prepared data
 */
export const prepareActionField = (
    {real_order_id: id, shipping, subtotal, discountAmount, tax}: OrderItemFullType,
    coupons: string,
): PurchaseActionFieldType => ({
    action: 'purchase',
    id: emptyByDefault(id),
    affiliation: '',
    revenue: (Number(subtotal) - Number(discountAmount || 0)).toFixed(2),
    shipping: Number(shipping).toFixed(2),
    coupon: coupons || '',
    tax: (tax || 0).toFixed(2),
});

/**
 * Prepare the product data for sending to the eec purchase event
 *
 * @param {OrderItemFullType} order - order data
 * @param {CategoryMapType} categoryMap - map of categories related to sku product
 * @returns {Array<PurchaseProductType>} prepared data
 */
export const prepareProducts = (
    {items}: OrderItemFullType,
    categoryMap: CategoryMapType,
): PurchaseProductType[] =>
    items.map(
        ({qty: quantity, name, product = {}, totalFinalPrice, sku, prescription, options}) => ({
            ...prepareBaseImpressionData({
                ...pick(['sku', 'color', 'size'], product),
                price: totalFinalPrice,
                category: categoryMap[sku],
                name,
                brand: path(['brand', 'label'], product),
                type: path(['type'], product),
                contactLensTypeLabel: path(['contact_lens_type_label'], product),
            }),
            quantity: quantity || 0,
            dimension21: emptyByDefault(sku),
            dimension23: getLensType(options),
            dimension53: getPrescriptionType(product.type || '', prescription),
            dimension60: getProductType(product.type || '', !isEmpty(prescription)),
        }),
    );

/**
 * Prepares data for sending addToCartEvent
 *
 * @param {QuoteItemV2Type} item - cart item
 * @param {CategoryMapType} categoryMap - map of categories related to sku product
 * @returns {EecAddToCartUVPProductType} cart item addToCart event data
 * */
export const prepareCartItemToEEC = (
    item: QuoteItemV2Type,
    categoryMap?: CategoryMapType,
): EecAddToCartUVPProductType => ({
    ...prepareBaseImpressionData(
        {
            name: item.name,
            category: categoryMap?.[`${item.sku}`],
            color: path(['product', 'color'], item),
            size: path(['product', 'size'], item),
            price: item.total,
            brand: path(['product', 'brand', 'label'], item),
            sku: path(['product', 'sku'], item),
            type: path(['type'], item.product),
            contactLensTypeLabel: path(['contactLensTypeLabel'], item.product),
            contactLensType: path(['contactLensType'], item.product),
        },
        4,
    ),
    product_id: pathOr(0, ['product', 'id'], item).toString(),
    quantity: item.qty || 1,
    dimension21: emptyByDefault(item.sku),
    dimension23: getUsageSku(item.options || []),
    dimension36: pathOr(0, ['product', 'price'], item).toFixed(2),
    dimension53: getPrescriptionType(pathOr('', ['product', 'type'], item), {
        type: pathOr('', ['prescription', 'type'], item),
        name: pathOr('', ['prescription', 'name'], item),
    }),
    dimension60: getProductType(pathOr('', ['product', 'type'], item), Boolean(item.prescription)),
});

/**
 * Prepares item`s data for EEC_checkout event
 *
 * @param {QuoteItemV2Type[]} cartItems - list of cart items
 * @param {CategoryMapType} categoryMap - map of categories related to sku product
 * @returns {EecPurchaseProductType[]} product's data
 * */
export const prepareCheckoutProductData = (
    cartItems: QuoteItemV2Type[],
    categoryMap: CategoryMapType,
): EecPurchaseProductType[] =>
    cartItems.map(cartItem => ({
        ...prepareBaseImpressionData(
            {
                name: cartItem.name,
                sku: path(['product', 'sku'], cartItem),
                brand: path(['product', 'brand', 'label'], cartItem),
                size: path(['product', 'size'], cartItem),
                color: path(['product', 'color'], cartItem),
                category: categoryMap[`${cartItem.sku}`],
                price: cartItem.totalFinalPrice,
            },
            4,
        ),
        quantity: cartItem.qty || 1,
    }));

export const prepareRemoveFromCartEEC = (
    item: QuoteItemV2Type,
    product,
    categoryMap: CategoryMapType,
) => {
    const children = product.children[0];

    return {
        ...prepareBaseImpressionData(
            {
                name: item.name,
                category: categoryMap[`${item.sku}`],
                color: path(['product', 'color'], item),
                size: path(['product', 'size'], item),
                price: item.total,
                brand: path(['product', 'brand', 'label'], item),
                sku: path(['product', 'sku'], item),
            },
            4,
        ),
        product_id: pathOr(0, ['product', 'id'], item).toString(),
        dimension21: emptyByDefault(item.sku),
        dimension23: getUsageSku(item.options || []),
        dimension31: children?.description?.length || 0,
        dimension34: (children?.genders || []).join(', '),
        dimension35: item.product.size === 'Large' ? '360' : '180',
        dimension36: pathOr(0, ['product', 'price'], item).toFixed(2),
        dimension40: path(['product', 'size'], item),
        dimension44: getFrameUrl(children),
        dimension45: emptyByDefault(children?.manufacturerValue),
        dimension51: item.productAsHTO ? 'In HTO Cart' : 'In Buy Now Cart',
        dimension60: getProductType(
            pathOr('', ['product', 'type'], item),
            Boolean(item.prescription),
        ),
        dimension64: item.productAsHTO ? 'Eligible' : 'Not eligible',
    };
};

export const prepareCoupons = (certificates: {code: string}[] | undefined): string =>
    certificates ? certificates.map(coupon => coupon.code).join() : '';

export const prepareRemoddveFromCartEEC = (
    item: QuoteItemV2Type,
    product,
    categoryMap: CategoryMapType,
) => {
    const children = product.children[0];

    return {
        ...prepareBaseImpressionData(
            {
                name: item.name,
                category: categoryMap[`${item.sku}`],
                color: path(['product', 'color'], item),
                size: path(['product', 'size'], item),
                price: item.total,
                brand: path(['product', 'brand', 'label'], item),
                sku: path(['product', 'sku'], item),
            },
            4,
        ),
        product_id: pathOr(0, ['product', 'id'], item).toString(),
        dimension21: emptyByDefault(item.sku),
        dimension23: getUsageSku(item.options || []),
        dimension31: children?.description?.length || 0,
        dimension34: (children?.genders || []).join(', '),
        dimension35: item.product.size === 'Large' ? '360' : '180',
        dimension36: pathOr(0, ['product', 'price'], item).toFixed(2),
        dimension40: path(['product', 'size'], item),
        dimension44: getFrameUrl(children),
        dimension45: emptyByDefault(children?.manufacturerValue),
        dimension51: item.productAsHTO ? 'In HTO Cart' : 'In Buy Now Cart',
        dimension60: getProductType(
            pathOr('', ['product', 'type'], item),
            Boolean(item.prescription),
        ),
        dimension64: item.productAsHTO ? 'Eligible' : 'Not eligible',
    };
};

export const prepareImpressionData = (
    products: ProductChildrenType[],
    options: {category: string; list: string; index?: number},
) =>
    products.map((product, index) => {
        return {
            ...prepareBaseImpressionData(
                {
                    ...pick(['name', 'price'], product),
                    brand: path(['label'], product.brand),
                },
                4,
            ),
            id: product.sku,
            product_id: product.id,
            position: 1 + (options.index || index),
            list: options.list || 'Non category page',
            dimension36: convertPriceEEC(product.price),
            variant: `${path(['frame', 'size', 'shortLabel'], product)}|${path(
                ['color', 'label'],
                product,
            )}`,
            category: options.category || 'Non category page',
        };
    });

// Can be replaced usage with sendProductClickEvent
export const sendEECProductClick = (locationPath: string, products: ProductChildrenType[]) => {
    const impressions = prepareImpressionData(products, {
        list: CTP_SIMILAR_FRAMES,
        category: CTP_SIMILAR_FRAMES,
    });
    const data = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {list: locationPath, action: 'click'},
                products: impressions,
            },
        },
    };
    pushDataLayer(data);
};

export const getVariant = (product: ProductChildrenType) => {
    if (product.type === PRODUCT_TYPES.CONTACT_LENSES) {
        return `C|${product?.contactLensTypeLabel || ''}`;
    }
    return `${path(['frame', 'size', 'shortLabel'], product)}|${path(['color', 'label'], product)}`;
};

export const prepareProductClickImpressionData = (
    products: ProductChildrenType[],
    options: {category: string; list: string; selectedId: number; index?: number},
) => {
    const product = products.find(({id}) => id === options.selectedId);

    if (!product) return [];

    return [
        {
            ...prepareBaseImpressionData(
                {
                    ...pick(['name', 'price'], product),
                    brand: path(['label'], product.brand),
                },
                4,
            ),
            id: product.sku,
            product_id: product.id,
            position: 1 + (options.index || 0),
            list: options.list || 'Non category page',
            dimension36: convertPriceEEC(product.price),
            variant: getVariant(product),
            category: options.category || 'Non category page',
        },
    ];
};

type SendEECProductClickDataType = {
    list: string;
    category: string;
    index: number;
    products: ProductChildrenType[];
    selectedId: number;
};

export const sendProductClickEvent = ({
    list,
    category,
    index,
    products,
    selectedId,
}: SendEECProductClickDataType) => {
    const impressions = prepareProductClickImpressionData(products, {
        list,
        category,
        index,
        selectedId,
    });
    const data = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {list, action: 'click'},
                products: impressions,
            },
        },
    };
    pushDataLayer(data);
};

export const sendEECAddToCartEvent = (product: ProductChildrenType): void => {
    const data = {
        event: 'addToCart',
        ecommerce: {
            PlaceOfInteraction: '',
            add: {
                products: [product],
            },
            currencyCode: 'USD',
        },
    };
    pushDataLayer(data);
};
