export const FILL_IT_ONLINE_TAB = 1;
export const UPLOAD_OR_SEND_LATER_TAB = 2;

export const FILL_IT_ONLINE = 1;
export const UPLOAD_OR_SEND_LATER = 2;
export const PRESCRIPTION_UPLOAD = 4;
export const USE_MY_PRESCRIPTION = 3;
export const PRESCRIPTION_SEND_EMAIL_OR_FAX = 3;
export const NOT_SELECTED = 0;
export const MAGNIFICATION_METHOD = 5;

export const READING = 'Reading';
export const SINGLE_VISION = 'Single-Vision';
export const PROGRESSIVE = 'Progressive';
export const BIFOCAL = 'Bifocal';
export const NON_PRESCRIPTION = 'Frame';
export const MULTI_FOCAL = 'Multifocal';
export const PROGRESSIVE_OFFICE = 'OfficeNear';

export const PD_DEFAULT_VALUE = '35.5';

export const MAP_TABS = {
    [FILL_IT_ONLINE]: FILL_IT_ONLINE,
    [UPLOAD_OR_SEND_LATER]: UPLOAD_OR_SEND_LATER,
    [PRESCRIPTION_SEND_EMAIL_OR_FAX]: UPLOAD_OR_SEND_LATER,
    [PRESCRIPTION_UPLOAD]: PRESCRIPTION_SEND_EMAIL_OR_FAX,
};

export const LENS_TYPE_OPTIONS_KEYS = {
    CLEAR: 'clear',
    DIGITAL: 'digital_protection',
    PHOTOCHROMIC: 'light_adjusting',
    SUNGLASSES: 'sunglasses_options',
};

export const TOOLTIPS = {
    SPH:
        'The SPHERE indicates the strength of your prescription. Distance prescriptions usually have a (–) before the number. Reading prescriptions will usually have a (+) before the number.',
    CYL:
        'The CYLINDER indicates the amount of lens power needed to correct astigmatism. If nothing appears in this column, either you have no astigmatism, or your astigmatism is so slight that it is not really necessary to correct it with a lens.',
    AXIS:
        'The AXIS describes the lens meridian. It is defined by a number between 1 to 180. The number 90 corresponds to the vertical meridian of the eye, and the number 180 corresponds to the horizontal meridian.',
    ADD:
        'The ADD may be written in the near vision (N.V.) or ADD section of the prescription. Sometimes your doctor will write it only once - in which case it will be the same for both eyes. In that case, just insert the number twice when filling your prescription. In case you only want single vision distance glasses - simply leave the ADD off of your prescription.',
    LT:
        'With standard lenses the higher the prescription, the thicker the lens. This can be a problem for high prescriptions where the thickness of the lens can become unsightly. In these instances, it is common to use ultra thin lenses that remain thin even when the prescription is high.',
    ASC:
        'Anti-Scratch coatings extend the life of your lens and ensure a clearer and more natural quality of vision.',
    UVC:
        'UV coating provides the best protection from the harming effects of the sun. It reduces possible damage to the skin and the structure of the eye.',
    AGC:
        'Anti-Glare coating reduces eyestrain by getting rid of the ambient glare of headlights, phones and digital devices that otherwise make us squint.',
    SINGLE_PD:
        'PD (pupillary distance) is the distance between your pupils in millimeters. The average PD for adults is 62. For progressives/bifocals, please provide the Near PD.',
    DOUBLE_PD:
        'If you have 2 PD numbers, (30/32 for example), the numbers refer to distance between the center of each pupil to your nose bridge. The first number is for the left eye, the second is for the right eye. Fill in accordingly.',
};

export const HARDCODED_PRISM_PRICE = 30;

export const fromMultifocal = 'fromMultifocal';

export const Z_INDEX_POPUP_OVER_CHAT = 10000;
