/* eslint-disable max-lines */
import {createSelector} from 'reselect';

import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {PRODUCT_TYPES, STOCK_STATUSES} from 'constants/product/product';
import {Status} from 'constants/reducerStatus';
import {PRODUCT_TYPE, PRODUCT_VIEW_TYPE} from 'constants/productType';
import {BADGE} from 'constants/badge';
import {ProductChildrenType, ProductReducerType} from 'reducers/product';
import {getPageData} from 'selectors/page/getPageData';
import {getActiveUsage} from 'features/wizard/store/reducers/usages/usages';
import {NON_PRESCRIPTION} from 'constants/wizard/wizard';
import {CustomerConfigurationType} from 'features/wizard/store/reducers/customerConfiguration/customerConfigurationType';
import {WizardOptionType, WizardValueType} from '@optimaxdev/wizard-ms';
import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {propOr, uniqBy} from 'ramda';
import {
    getConfigureProductId,
    getQuoteItemIdForNewConfigure,
} from 'selectors/wizard/configure/configure';
import {UserDiscountItemType} from 'reducers/user';
import {getUserData} from 'selectors/customer/customer';
import {getCartProducts} from '../cartProducts/cartProducts';

export type ColorType = {
    items?: Array<WizardValueType | WizardOptionType>;
    title: string;
    parentNodeId: number;
};

export type DarknessesType = {
    basicDarkness: Maybe<WizardValueType>;
    gradientDarkness: Maybe<WizardValueType>;
    personalizedDarkness: WizardValueType[];
    parentNodeId: number;
};

/**
 * Get products
 *
 * @param {ApplicationStoreType} state - store state
 * @returns {ProductType} - Products list from window product
 */
export const getWindowProduct = (state: ApplicationStoreType): ProductReducerType => state.product;

/**
 * Get products
 *
 * @param {ApplicationStoreType} state - store state
 * @returns {ProductType} - Products list from window product
 */
export const isLoadingProduct = createSelector(
    getWindowProduct,
    (product: ProductReducerType): boolean => product.status === Status.Fulfilled,
);

export const getActiveProductForConfigure = createSelector(
    getConfigureProductId,
    getCartProducts,
    getQuoteItemIdForNewConfigure,
    (configureProductId, cartProducts, quoteItemId: number) => {
        const isConfigureId = Boolean(configureProductId);
        if (!isConfigureId) return null;
        const configureProduct = cartProducts[quoteItemId];
        return (
            configureProduct &&
            configureProduct.children.find(item => item.id === configureProductId)
        );
    },
);
/**
 * Get active product
 *
 * @returns {ProductChildrenType} - Active product
 */
export const getActiveProduct = createSelector(
    getWindowProduct,
    getPageData,
    getActiveProductForConfigure,
    ({item: {children}}, {params: {id}, page}, productForNewConfigure) => {
        // it needs to indicate new configure or old(new configure it is a popup so if the new configure is open we still on the cart page)
        const isCartPage = page === 'cart' && productForNewConfigure;
        return isCartPage
            ? (productForNewConfigure as ProductChildrenType)
            : children.find(child => child.id === Number(id)) || ({} as ProductChildrenType);
    },
);
export const getColors = (store: ApplicationStoreType): ColorType =>
    <ColorType>store.wizardMs.upsells.colors;
const getDarknessData = (darkness: WizardValueType | WizardOptionType) => {
    const basicDarkness =
        ((darkness as WizardOptionType).items || []).find(({sku}) => sku === 'basic-darkness') ||
        null;
    const gradientDarkness =
        ((darkness as WizardOptionType).items || []).find(({sku}) => sku === 'gradient-darkness') ||
        null;
    const personalizedDarkness = ((darkness as WizardOptionType).items || [])
        .filter(({sku}) => !['gradient-darkness', 'basic-darkness'].includes(sku))
        .sort((a, b) => a.sort - b.sort);
    return {basicDarkness, gradientDarkness, personalizedDarkness};
};
export const getDarknesses = createSelector(getColors, (colors): DarknessesType | null => {
    if (colors && colors.items) {
        const darkness = colors.items.find(item => propOr('', 'alias', item) === 'darkness');
        if (!darkness || !(darkness.items && darkness.items.length)) return null;

        return {
            ...getDarknessData(darkness),
            parentNodeId: darkness.node,
        };
    }
    return null;
});

export const selectedDarkness = createSelector<
    ApplicationStoreType,
    CustomerConfigurationType,
    ColorType,
    ProductChildrenType | null | undefined,
    WizardValueType | ''
>(
    getCustomerConfiguration,
    getColors,
    getActiveProduct,
    ({options}, upsellColors, activeProduct) => {
        const darkness = upsellColors?.items?.find(
            item => propOr('', 'alias', item) === 'darkness',
        );
        if (!darkness) return '';

        if (options[darkness.node]) {
            return (
                ((darkness as WizardOptionType).items || []).find(
                    ({node}) => options[(darkness as WizardOptionType).node] === node,
                ) || ''
            );
        }
        // in case when selected value from customer configuration not found we try to return the default darkness id
        return (
            ((darkness as WizardOptionType).items || []).find(
                ({sku}) => sku === (activeProduct && activeProduct?.defaultDarknessSku),
            ) || ''
        );
    },
);

/**
 * Defines active product type: 'sunglasses', 'eyeglasses', 'contact_lenses'
 *
 * @param {ApplicationStoreType} state - store state
 * @returns {string} - one of 'sunglasses', 'eyeglasses', 'contact_lenses'
 */
export const getActiveProductType = createSelector(
    getActiveProduct,
    activeProduct => activeProduct && activeProduct.type,
);
export const getProductChildrenByUniqColor = createSelector<
    ApplicationStoreType,
    ProductReducerType,
    ProductChildrenType[]
>(getWindowProduct, ({item}) =>
    uniqBy((child: ProductChildrenType) => child.color.id)(item.children),
);

/**
 * Get active product by id
 *
 * @param {ApplicationStoreType} store - Application state
 * @param {number} productId - product id
 * @returns {ProductChildrenType} - product
 */
export const getProductById = (store: ApplicationStoreType, productId: number) =>
    store.product.item.children.find(product => product.id === productId);
/**
 * Defines if glasses are sunglasses
 *
 * @param {ApplicationStoreType} state - store state
 * @returns {boolean} - true if glasses are sunglasses
 */
export const isActiveProductSunglasses = createSelector(
    getActiveProduct,
    getPageData,
    (activeProduct, {params: {type_id: typeId}}): boolean =>
        (activeProduct && activeProduct.type === PRODUCT_TYPES.SUNGLASSES) ||
        Number(typeId) === PRODUCT_TYPE.RX_SUNGLASSES,
);

/**
 * Get media type
 *
 * @param {ApplicationStoreType} store - Application state
 * @returns {string} - media type
 */
export const getMediaType = createSelector(
    [getWindowProduct, isActiveProductSunglasses],
    ({selectedType, selectedView}, isSunglasses): string => {
        const isFrameSelected = [
            PRODUCT_VIEW_TYPE.ROTATION_180 as string,
            PRODUCT_VIEW_TYPE.SUNGLASSES_180,
        ].includes(selectedView);

        if (selectedView !== '' && !isFrameSelected) return selectedView;

        if (
            isSunglasses ||
            [PRODUCT_TYPE.SUNGLASSES as number, PRODUCT_TYPE.RX_SUNGLASSES].includes(selectedType)
        )
            return PRODUCT_VIEW_TYPE.SUNGLASSES_180;

        return PRODUCT_VIEW_TYPE.ROTATION_180;
    },
);

/**
 * Return product discount
 *
 * @param {ApplicationStoreType} state - store state
 *  @returns {boolean} - true if glasses are premium
 */
export const isPremiumProduct = createSelector(
    getActiveProduct,
    activeProduct =>
        (activeProduct && activeProduct.badge && BADGE[activeProduct.badge.id] === 'Premium') ||
        false,
);

/**
 * Is selected usage "Frame"
 */
export const isSunglassesFrameUsage = createSelector(
    [isActiveProductSunglasses, getActiveUsage],
    (isSunglasses, usage) => usage && usage.sku === NON_PRESCRIPTION && isSunglasses,
);

/**
 * Is plano sunglasses
 */
export const isPlanoSunglasses = createSelector(
    getWindowProduct,
    ({isCustomizePlano, selectedType}) => {
        return selectedType === PRODUCT_TYPE.SUNGLASSES && !isCustomizePlano;
    },
);

/**
 * Detect if product out of stock
 * if status is out of stock it'll return true (it means we know that product in out of stock)
 * if qty is 0 it'll return true (it means we know that product in out of stock)
 * if product is undefined(unknown) it'll return false for both cases (false || false) (it means we do not know product in out of stock or not)
 */
export const isProductOutOfStock = createSelector(
    getActiveProduct,
    activeProduct =>
        activeProduct &&
        (activeProduct.stock?.status === STOCK_STATUSES.OUT_OF_STOCK ||
            activeProduct.stock?.qty < 1),
);

/**
 * Get lenses discount
 *
 * @param {ApplicationStoreType} store store
 * @returns {UserDiscountItemType} discounts
 */
export const getContactsDiscount = createSelector(
    [getUserData],
    ({discount}): Maybe<UserDiscountItemType> => {
        if (discount?.frame_discount?.contacts) {
            return discount.frame_discount.contacts;
        }
        return null;
    },
);
