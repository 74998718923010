import {createAction, createReducer} from 'redux-act';

export type WidgetType = {
    isOpen: boolean;
};

export const defaultState: WidgetType = {
    isOpen: true,
};

export const closeWidget = createAction('@@WIDGET/closeWidget');

/**
 * Member widget reducer
 */
export const memberWidget = createReducer<WidgetType>(
    {
        [`${closeWidget}`]: () => ({
            isOpen: false,
        }),
    },
    defaultState,
);
