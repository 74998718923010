import {
    PD_DEFAULT_VALUE,
    PRESCRIPTION_SEND_EMAIL_OR_FAX,
    UPLOAD_OR_SEND_LATER_TAB,
} from 'constants/wizard/wizard';
import {PrescriptionType} from './prescriptionTypes';

export const NONE = 'None';
export const initialState: PrescriptionType = {
    id: 0,
    name: '',
    comment: '',
    method: PRESCRIPTION_SEND_EMAIL_OR_FAX,
    activeTab: UPLOAD_OR_SEND_LATER_TAB,
    strength: '',
    isPrism: false,
    file: '',
    link: '',
    fileName: '',
    type: 'email',
    od: {
        sph: NONE,
        cyl: NONE,
        axi: NONE,
        add: NONE,
    },
    os: {
        sph: NONE,
        cyl: NONE,
        axi: NONE,
        add: NONE,
    },
    pd: {
        singular: '62',
        right: PD_DEFAULT_VALUE,
        left: PD_DEFAULT_VALUE,
        near: NONE,
    },
    isMultiPD: false,
};
