import {createReducer} from 'redux-act';

import {RMA_STATES} from '../../../constants/common';
import {changeRmaState} from '../../actions/actions';
import {RmaResponseType, RmaDataType} from '../../../types';
import {offerExchangeAdapter} from '../../../libs';

export const initialState: RmaDataType['thirdStep'] = {
    offerRefund: {
        customerName: '',
        types: {
            refund: 'No thanks',
            storecredit_110: 'Get 110% Store Credit',
        },
    },
    offerExchange: {
        isContactLenses: false,
        items: [],
    },
    types: {
        exchange: 'Exchange',
        refund: 'Refund',
    },
};

/**
 * Reducer rma improved third step.
 */
export const thirdStep = createReducer({}, initialState).on(
    `${changeRmaState}_FULFILLED`,
    // eslint-disable-next-line complexity
    (state, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.CANCELLATION_TYPES:
            case RMA_STATES.CANCELLATION_UHC_TYPES:
            case RMA_STATES.RETURNS_NO_REFUND_TYPES:
            case RMA_STATES.CANCELLATION_NO_REFUND_TYPES:
            case RMA_STATES.RETURNS_UHC_TYPES:
            case RMA_STATES.RETURNS_TYPES:
                return {...state, types: response.data};
            case RMA_STATES.OFFER_REFUND:
                return {...state, offerRefund: response.data};
            case RMA_STATES.OFFER_EXCHANGE:
                return {...state, offerExchange: offerExchangeAdapter(response.data)};

            default:
                return state;
        }
    },
);
