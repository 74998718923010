import {PrescriptionType as NewFormatPrescriptionType} from 'features/wizard/store/reducers/prescription/prescriptionTypes';
import {FILL_IT_ONLINE} from 'constants/wizard/wizard';

import {UserPrescriptionsType} from './userType';

export const prescriptionAdapter = (
    prescriptions: UserPrescriptionsType[],
): NewFormatPrescriptionType[] =>
    prescriptions.reduce((accumulator: NewFormatPrescriptionType[], prescription) => {
        return [
            ...accumulator,
            {
                activeTab: 1,
                comment: prescription.comment,
                file: '',
                id: prescription.id,
                isMultiPD: Boolean(prescription.data.isTwoPd),
                isPrism: false,
                link: '',
                fileName: '',
                method: FILL_IT_ONLINE,
                name: prescription.name,
                strength: '',
                type: 'online',
                od: {
                    sph: prescription.data.sph_od,
                    cyl: prescription.data.cyl_od,
                    axi: prescription.data.axi_od,
                    add: prescription.data.add_od,
                },
                os: {
                    sph: prescription.data.sph_os,
                    cyl: prescription.data.cyl_os,
                    axi: prescription.data.axi_os,
                    add: prescription.data.add_os,
                },
                pd: {
                    left: prescription.data.pd_left,
                    right: prescription.data.pd_right,
                    near: prescription.data.pd_near,
                    singular: prescription.data.pd,
                },
            },
        ];
    }, []);
