import {CategoryParamsType} from 'reducers/categoryItems';
import {CategoryFilterIdsType} from 'reducers/categoryFilters';
import {AppliedFiltersType} from 'reducers/categoryAppliedFilters';
import {SortType} from 'reducers/categorySort';
import {updateUrlParams} from 'libs/updateUrlParams';

/**
 * Add param to search object
 *
 * @param {string} param - param name
 * @param {string} value - param value
 */
export const addParamToUrl = (param: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const oldValue = searchParams.get(param);
    const newValue = oldValue
        ? [...oldValue.split(' ').filter(key => key !== value), value].join(' ')
        : value;

    searchParams.set(param, newValue);

    updateUrlParams(searchParams);
};

/**
 * Replace param in search object
 *
 * @param {string} param - param name
 * @param {string} value - param value
 */
export const replaceParamInUrl = (param: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (value === '') {
        searchParams.delete(param);
    } else {
        searchParams.set(param, value);
    }

    updateUrlParams(searchParams);
};

/**
 * Remove param from search object
 *
 * @param {string} param - param name
 * @param {string} value - param value
 */
export const removeParamFromUrl = (param: string, value = '') => {
    const searchParams = new URLSearchParams(window.location.search);
    const oldValue = searchParams.get(param);

    if (!oldValue) return;

    const newValue = oldValue.split(' ').filter(key => key !== value);

    if (newValue.length === 0 || value === '') {
        searchParams.delete(param);
    } else {
        searchParams.set(param, newValue.join(' '));
    }

    updateUrlParams(searchParams);
};

/**
 * Remove param from search object
 *
 * @param {{}} filtersIds - id of all filters
 */
export const removeAllCategoryParamsFromUrl = (filtersIds: Record<string, unknown>) => {
    const searchParams = new URLSearchParams(document.location.search);

    searchParams.delete('p');

    Object.keys(filtersIds).forEach((key: string) => {
        searchParams.delete(key);
    });

    updateUrlParams(searchParams);
};

/**
 * Get params for category
 *
 * @param {CategoryFilterIdsType} filtersIds - id of all filters
 * @returns {{categoryParams: CategoryParamsType, appliedFilters: AppliedFiltersType}} category params
 */
export const getCategoryParams = (
    filtersIds: CategoryFilterIdsType = {},
): {categoryParams: CategoryParamsType; appliedFilters: AppliedFiltersType} => {
    const searchParams = new URLSearchParams(document.location.search);
    const pageNumber = searchParams.get('p') || 1;
    const dir = searchParams.get('dir');
    const order = searchParams.get('order');
    const filters = {};
    const appliedFilters = {};

    searchParams.forEach((values: string, key: string) => {
        if (Object.keys(filtersIds).some(filterCode => filterCode === key)) {
            values.split(' ').forEach((value: string) => {
                filters[key] = [...(filters[key] || []), filtersIds[key][value]];
            });

            filters[key] = filters[key].join();

            appliedFilters[key] = values
                .split(' ')
                .reduce((obj, value) => ({...obj, [value]: true}), {});
        }
    });

    return {
        categoryParams: {
            page: Number(pageNumber),
            filters,
            dir,
            order,
        },
        appliedFilters,
    };
};

type SearchParamsType = {[x: string]: string};

/**
 * Get search params
 *
 * @returns {SearchParamsType} return search params
 */
export const getSearchParams = (): SearchParamsType => {
    const searchParams = new URLSearchParams(document.location.search);
    const params = {};

    searchParams.forEach((values: string, key: string) => {
        params[key] = values;
    });

    return params;
};

/**
 * Get sort params for category
 *
 * @returns {SortType} category params
 */
export const getSortParams = (): SortType => {
    const searchParams = new URLSearchParams(document.location.search);
    const dir = searchParams.get('dir');
    const order = searchParams.get('order');

    return {
        dir: dir && order ? dir : '',
        order: dir && order ? order : '',
    };
};

/**
 * Parse url params and return object
 *
 * @param {string} item Current name
 * @returns {string} - url param
 */
export const getUrlParameter = (item: string): string => {
    const name = item.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
