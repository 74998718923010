// @flow
import {createActions, handleActions, type ActionType} from 'redux-actions';
import type {AxiosResponse} from 'axios';

import {FULFILLED} from 'constants/actionSuffix';
import {ENDPOINTS} from 'constants/endpoints';
import {API_URL_ELASTICSEARCH} from 'constants/api';
import type {ProductType} from 'reducers/categoryItems/categoryItemsType';

type BestSellersItemsType = {
    products: Array<ProductType>,
};

const defaultState = {
    products: [],
};

export const {getBestSellersItems} = createActions({
    GET_BEST_SELLERS_ITEMS: (ids: Array<number>): ActionType<AxiosResponse> => ({
        request: {
            method: 'get',
            baseURL: API_URL_ELASTICSEARCH,
            url: `${ENDPOINTS.CATEGORY_ITEMS}/0`,
            params: {
                parentIds: ids.join(','),
            },
        },
    }),
});

/**
 * Best sellers products. Return only 4 products
 *
 * @returns {BestSellersItemsType} state - next state
 */
export const bestSellersItems = handleActions(
    {
        [`${getBestSellersItems}${FULFILLED}`]: (
            state: BestSellersItemsType,
            {payload}: ActionType<typeof getBestSellersItems>,
        ): BestSellersItemsType => ({
            products: payload.data.items.filter((item, i) => i < 4),
        }),
    },
    defaultState,
);
