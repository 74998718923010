import {createSelector} from 'reselect';
import {WizardOptionType} from '@optimaxdev/wizard-ms';

import {
    isEyePrescriptionEmpty,
    validatePrescription,
} from 'features/wizard/utils/prescriptionValidation/prescriptionValidation';
import {getWizardMs} from 'features/wizard/store/selectors/wizard/wizard';
import {CustomerConfigurationType} from 'features/wizard/store/reducers/customerConfiguration/customerConfigurationType';
import {getCustomerConfiguration} from 'features/wizard/store/reducers/customerConfiguration/customerConfiguration';
import {FILL_IT_ONLINE, NON_PRESCRIPTION} from 'constants/wizard/wizard';
import {
    getActiveUsage,
    isFashionUsage,
    UsageItemType,
} from 'features/wizard/store/reducers/usages/usages';
import {ApplicationStoreType, Maybe} from 'constants/typescript/types';
import {WizardMsType} from 'features/wizard/store/reducers';
import {PrescriptionReducerType} from 'features/wizard/store/reducers/prescription';
import {PrescriptionValidationRulesType} from 'features/wizard/utils/prescriptionValidation/prescriptionRules/prescriptionRules';

/**
 * get prescription method
 *
 * @param {ApplicationStoreType} store - ApplicationStoreType
 * @returns {number} - method
 */
export const getPrescriptionSelectedMethod = createSelector<
    ApplicationStoreType,
    WizardMsType,
    number
>(getWizardMs, ({prescription}) => prescription.values.method);

/**
 * get active tab
 *
 * @param {ApplicationStoreType} store - ApplicationStoreType
 * @returns {string} - active tab
 */
export const getActiveTab = createSelector<ApplicationStoreType, WizardMsType, number>(
    getWizardMs,
    ({prescription}) => prescription.values.activeTab,
);

/**
 * Determines is prescription section available
 *
 * @returns {boolean} - flag if it can be shown
 */
export const isPrescriptionSectionAvailable = createSelector<
    ApplicationStoreType,
    Maybe<UsageItemType>,
    boolean
>(getActiveUsage, usage => !(!usage || usage.sku === NON_PRESCRIPTION));

/**
 * Get prescription field
 */
export const getPrescription = createSelector<
    ApplicationStoreType,
    WizardMsType,
    PrescriptionReducerType
>(getWizardMs, ({prescription}) => prescription);

/**
 * validated prescription method
 * if prescription fill correct it will return true without errors
 * if prescription fill wrong it will return false and errors
 */
export const getPrescriptionValidateResult = createSelector<
    ApplicationStoreType,
    PrescriptionReducerType,
    Maybe<UsageItemType>,
    {isPrescriptionValid: boolean; errors: Record<keyof PrescriptionValidationRulesType, string>}
>(getPrescription, getActiveUsage, ({values}, usage) => {
    const [isPrescriptionValid, errors] = validatePrescription(usage ? usage.sku : '', values);

    return {
        isPrescriptionValid,
        errors,
    };
});

/**
 * Returns prism node
 */
export const getPrismNode = createSelector<
    ApplicationStoreType,
    Maybe<UsageItemType>,
    Maybe<WizardOptionType>
>(getActiveUsage, selectedUsage => {
    if (!selectedUsage || !selectedUsage.items) return null;

    return (
        (selectedUsage.items.find(
            item => 'alias' in item && item.alias === 'prism',
        ) as WizardOptionType) || null
    );
});

/**
 * Determines added prism
 */
export const isPrismAdded = createSelector<
    ApplicationStoreType,
    Maybe<WizardOptionType>,
    CustomerConfigurationType,
    boolean
>(getPrismNode, getCustomerConfiguration, (prismNode, {options}) => {
    if (!prismNode) return false;
    return Boolean(options[prismNode.node]);
});

export const getIsPrescriptionEmpty = createSelector<
    ApplicationStoreType,
    ApplicationStoreType['wizardMs']['prescription'],
    boolean,
    boolean
>(getPrescription, isFashionUsage, ({values}, isFashion) => {
    const isPrescriptionEmpty =
        isEyePrescriptionEmpty(values.od) && isEyePrescriptionEmpty(values.os);

    return isPrescriptionEmpty && values.method === FILL_IT_ONLINE && !isFashion;
});
