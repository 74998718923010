import {createAction, createReducer} from 'redux-act';

import {API_URL_CUSTOMER} from 'constants/api';
import {REJECTED} from 'constants/actionSuffix';
import {RequestType} from 'constants/typescript/types';

import {NewPasswordType} from './newPasswordType';

export const defaultState: NewPasswordType = {error: '', email: '', password: ''};

export const resetPassword = createAction<string, string, string, RequestType>(
    'RESET_PASSWORD',
    (password: string, token: string, email: string): RequestType => {
        return {
            request: {
                baseURL: API_URL_CUSTOMER,
                method: 'PUT',
                url: `password/${token}`,
                data: {
                    email,
                    password,
                },
            },
        };
    },
);
export const setValidationError = createAction<string, string>(
    'SET_VALIDATION_ERROR',
    (error: string) => error,
);
export const clearErrorMessage = createAction('CLEAR_ERROR_MESSAGE');

/**
 * New password reducer
 */
export const newPassword = createReducer<NewPasswordType>(
    {
        [`${resetPassword}${REJECTED}`]: (
            state: NewPasswordType,
            {error: {response}},
        ): NewPasswordType => ({
            error: response.data.message,
            password: '',
            email: '',
        }),
        [resetPassword.toString()]: (state: NewPasswordType, {request}) => ({
            ...state,
            password: request.data.password,
            email: request.email,
        }),
        [setValidationError.toString()]: (state: NewPasswordType, error): NewPasswordType => ({
            error,
            password: '',
            email: '',
        }),
        [clearErrorMessage.toString()]: (): NewPasswordType => defaultState,
    },
    defaultState,
);
