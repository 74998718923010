import {RoundButton} from '@optimaxdev/design-system-u';
import React, {FC, useContext} from 'react';

import {LINK_UHC_SUPPORT} from 'constants/links';

import {LoginPopupContext, LoginPopupContextType} from '../../../context/loginPopupContext';
import {CheckIcon} from '../checkIcon';

import s from './successLogin.scss';

type PropsType = {
    onRequestClose: () => void;
};

export const SuccessLogin: FC<PropsType> = ({onRequestClose}) => {
    const {phoneNumber} = useContext(LoginPopupContext) as LoginPopupContextType;
    return (
        <div className={s.wrap}>
            <CheckIcon />
            <h3 className={s.title}>Verification successful</h3>
            <div className={s.phoneWrap}>
                <p>Your phone number is set to</p>
                <div className={s.phoneNumber}>
                    <p>{phoneNumber}</p>
                </div>
            </div>
            <p className={s.notification}>Verification codes will be sent to this number.</p>
            <RoundButton className={s.btn} size="regular" type="button" onClick={onRequestClose}>
                Ok, great!
            </RoundButton>
            <p className={s.support}>
                To change the number, please contact our&nbsp;
                <a
                    rel="noopener noreferrer"
                    className={s.link}
                    href={LINK_UHC_SUPPORT}
                    target="_blank"
                >
                    customer support
                </a>
                .
            </p>
        </div>
    );
};
