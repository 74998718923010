export const DROPDOWN_MENU_CONTACTS_LINKS = {
    contacts: {
        menuLinks: {
            id: 1,
            links: [
                {title: 'Shop All Contacts', link: '/contact-lenses', isUnderlined: false},
                {
                    title: 'Daily',
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_type=254',
                },
                {
                    title: 'Weekly',
                    link:
                        '/contact-lenses?frame=1&tab=0&dir=desc&order=bestsellers&p=1&contact_lens_type=256',
                },
                {
                    title: 'Monthly',
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_type=255',
                },
                {
                    title: 'Single Vision',
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_prescription_type=250',
                },
                {
                    title: 'Multifocal & Bifocal',
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&contact_lens_prescription_type=252',
                },
            ],
        },
        menuBrands: {
            links: [
                {
                    name: 'Acuvue' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=226',
                },
                {
                    name: 'Airoptimax' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=227',
                },
                {
                    name: 'Biofinity' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=229',
                },
                {
                    name: 'Biotrue' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=242',
                },
                {
                    name: 'Dailies' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=231',
                },
                {
                    name: 'Clariti' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=238',
                },
                {
                    name: 'Ultra' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=237',
                },
                {
                    name: 'Proclear' as const,
                    hide: false,
                    link: '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&brand=234',
                },
                {
                    name: 'BauschLomb' as const,
                    hide: false,
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&manufacturer=311',
                },
                {
                    name: 'CooperVision' as const,
                    hide: false,
                    link:
                        '/contact-lenses?dir=desc&order=bestsellers&p=1&frame=2&tab=0&manufacturer=242',
                },
                {
                    name: 'ShopAll' as const,
                    hide: false,
                    link: '/contact-lenses',
                },
            ],
        },
    },
};
