import {Flag} from 'rox-browser';

export default {
    mfaUHCGD: new Flag(false),
    perfectTryOnUHCGD: new Flag(false),
    similarFramesUHCGD: new Flag(false),
    categorySafetyUHCGD: new Flag(false),
    couponUHCGD: new Flag(false),
    dUHCCL: new Flag(false),
};
