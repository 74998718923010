import {SagaIterator} from 'redux-saga';
import {select, put, takeEvery, all} from 'redux-saga/effects';

import {getPageData} from 'selectors/page/getPageData';
import {setPage} from 'reducers/route';
import {getOrders, getOrder} from 'reducers/ordersInfo';
import {getCountry} from 'reducers/country/country';

/**
 * Fetch orders data
 */
export function* getOrdersData(): SagaIterator {
    const {page} = yield select(getPageData);

    if (page !== 'orders-list') return;

    yield put(getOrders());
}

/**
 * Fetch order data
 */
export function* getOrderData(): SagaIterator {
    const {page, params} = yield select(getPageData);

    if (!['order-print', 'order-details'].includes(page) || !params.id) return;

    yield all([put(getOrder(Number(params.id))), put(getCountry())]);
}

/**
 * Begin of saga
 */
export function* ordersSaga(): SagaIterator {
    yield takeEvery(setPage, getOrdersData);
    yield takeEvery(setPage, getOrderData);
}
