import {conformToMask, MaskedInputProps} from 'react-text-mask';

export type MaskedInputPropsType = MaskedInputProps & {
    regex?: RegExp;
};

export const COMMON_INPUTS_MASKS: Record<string, MaskedInputPropsType['mask']> = {
    memberId: Array(16).fill(/\w/),
    birthday: [/\d/, /\d/, '-', /\d/, /\d/, '-', /[0-9]/, /\d/, /\d/, /\d/],
};

export const COMMON_INPUTS_REGEX: Record<string, MaskedInputPropsType['regex']> = {
    // email regex from the https://emailregex.com/
    email: /^.+@\S+.\S+$/,
    // NOTICE! it allows gap in the end of the string
    userInitials: /^([a-zA-Z]+\s?)+$/,
    password: /[^ ]{2,}/,
    zipCode: /[0-9]{1,5}/,
};

export type InitialValidateFormType = {
    errors: Record<string, string>;
    hasErrors: boolean;
};

export type InputsValuesType = Record<string, string>;

export type InputMaskType = MaskedInputPropsType & {
    name: string;
    error: string;
};

export type InputFieldDescriptionType = MaskedInputPropsType & {
    /**
     * Field name
     *
     * @type {MaskedInputPropsType['name']}
     */
    name: NonNullable<MaskedInputPropsType['name']>;
    /**
     * Input field type
     *
     * @type {('number' | 'text' | 'password' | 'email' | 'tel')}
     */
    type: 'number' | 'text' | 'password' | 'email' | 'tel';
    /**
     * Placeholder for the field
     *
     * @type {string}
     */
    placeholder: string;
    /**
     * Error message will be shown if the field validation fails
     *
     * @type {string}
     */
    error?: string;
};

export type InputFieldDescriptionMapType = Record<string, Omit<InputFieldDescriptionType, 'name'>>;

/**
 *
 * @param {string} value - field value
 * @param {(boolean | string[] | RegExp[] | Function)} mask - validating mask
 * @param {string} name - field name
 * @returns {boolean} is valid
 */
export const validateByMask = (
    value: string,
    mask: MaskedInputPropsType['mask'],
    name?: string,
): boolean => {
    if (!Array.isArray(mask)) return true;

    if (name === 'id') {
        return !conformToMask(value, mask, {}).meta.someCharsRejected;
    }

    return value.length === mask.length && !conformToMask(value, mask, {}).meta.someCharsRejected;
};

/**
 * Validate UHC Sign In form
 *
 * @param {InputMaskType[]} items - map of masks
 * @param {InputsValuesType} fields - map of form fields
 * @returns {{errors: ValidateType[], hasErrors: boolean}} - object with errors
 */
export const validateForm = (
    items: InputFieldDescriptionType[],
    fields: InputsValuesType,
): InitialValidateFormType => {
    return items.reduce<InitialValidateFormType>(
        (validationResult, {name, error, mask, regex}): InitialValidateFormType => {
            const fieldValue = fields[name];
            const isValidMask = fieldValue === '' ? false : validateByMask(fieldValue, mask, name);
            const isValidRegex = regex ? regex.test(fieldValue) : true;
            const isValidFieldValue = isValidMask && isValidRegex;

            return {
                ...validationResult,
                hasErrors: validationResult.hasErrors || !isValidFieldValue,
                errors: {
                    ...validationResult.errors,
                    [name]: String(isValidFieldValue ? '' : error),
                },
            };
        },
        {errors: {}, hasErrors: false} as InitialValidateFormType,
    );
};

/**
 * Transform input fields descriptions map
 * to an array with input fields.
 *
 * @export
 * @param {InputFieldDescriptionMapType} inputsMap - an object with fields descriptions
 * @returns {InputMaskType[]} - array with inputs descriptions
 */
export function transformInputsDescriptionsMapToInputMasks(
    inputsMap: InputFieldDescriptionMapType,
): InputFieldDescriptionType[] {
    return Object.keys(inputsMap).reduce((inputsArray, inputFieldName) => {
        inputsArray.push({
            name: inputFieldName,
            error: `The ${inputFieldName} is not valid`,
            ...inputsMap[inputFieldName],
        });
        return inputsArray;
    }, [] as InputFieldDescriptionType[]);
}
