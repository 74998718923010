import {createAutoCorrectedDatePipe} from 'text-mask-addons';

import {
    COMMON_INPUTS_MASKS,
    COMMON_INPUTS_REGEX,
    InputFieldDescriptionType,
} from 'libs/validation/validateForm';

export type FullNameInputsType = {
    birthday: string;
    first_name: string;
    last_name: string;
    zip_code: string;
};

export const inputs: InputFieldDescriptionType[] = [
    {
        error: 'Please enter your Member ID',
        guide: false,
        mask: COMMON_INPUTS_MASKS.memberId,
        name: 'id',
        placeholder: 'Member ID',
        type: 'text',
    },
    {
        autoComplete: 'bday',
        error: 'Enter a valid date of birth MM-DD-YYYY',
        guide: false,
        mask: COMMON_INPUTS_MASKS.birthday,
        name: 'birthday',
        pipe: createAutoCorrectedDatePipe('mm-dd-yyyy'),
        placeholder: 'Date of Birth (MM-DD-YYYY)',
        type: 'text',
    },
];

export const inputsFullNameForm: InputFieldDescriptionType[] = [
    {
        autoComplete: 'given-name',
        error: 'Please fill out First Name',
        guide: false,
        mask: false,
        name: 'first_name',
        placeholder: 'First Name',
        type: 'text',
        regex: COMMON_INPUTS_REGEX.userInitials,
    },
    {
        autoComplete: 'family-name',
        error: 'Please fill out Last Name',
        guide: false,
        mask: false,
        name: 'last_name',
        placeholder: 'Last Name',
        type: 'text',
        regex: COMMON_INPUTS_REGEX.userInitials,
    },
    {
        autoComplete: 'bday',
        error: 'Enter a valid date of birth MM-DD-YYYY',
        guide: false,
        mask: COMMON_INPUTS_MASKS.birthday,
        name: 'birthday',
        pipe: createAutoCorrectedDatePipe('mm-dd-yyyy'),
        placeholder: 'Date of Birth (MM-DD-YYYY)',
        type: 'text',
    },
    {
        autoComplete: 'postal-code',
        error: 'Please fill out Zip Code',
        guide: false,
        mask: false,
        name: 'zip_code',
        placeholder: 'Zip Code',
        type: 'text',
        regex: COMMON_INPUTS_REGEX.zipCode,
    },
];
