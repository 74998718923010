import {createAction, createReducer} from 'redux-act';

import {FULFILLED, REJECTED} from 'constants/actionSuffix';
import {getMessage} from 'libs/getErrorMessages';
import {RequestType} from 'constants/typescript/types';
import {API_URL_CUSTOMER} from 'constants/api';
import {PASSWORD_FIELDS} from 'constants/account/account';

import {AccountSettingsType, AccountSettingsRequestType} from './accountSettingsType';

export const defaultState: AccountSettingsType = {
    firstname: '',
    lastname: '',
    middlename: '',
    email: '',
    changePassword: false,
    currentPassword: '',
    password: '',
    confirmation: '',
    errors: {
        form: '',
    },
    isProcessing: false,
    isSuccessUpdate: false,
};

type FieldType = {name: string; value: string | number};

export const updateAccountSettings = createAction<AccountSettingsRequestType, RequestType>(
    'UPDATE_ACCOUNT_SETTINGS',
    data => ({
        request: {
            baseURL: API_URL_CUSTOMER,
            headers: {Authorization: ''},
            method: 'PUT',
            url: 'me',
            data,
        },
    }),
);
export const togglePasswordForm = createAction('TOGGLE_PASSWORD_FORM');
export const clearAccountSettings = createAction('CLEAR_ACCOUNT_SETTINGS');
export const setAccountSettingsError = createAction(
    'SET_ACCOUNT_SETTINGS_ERROR',
    (invalidFieldName: string, errorMsg: string) => ({
        invalidFieldName,
        errorMsg,
    }),
);
export const clearErrors = createAction('CLEAR_ERRORS');
export const updateField = createAction<string, string, FieldType>(
    'UPDATE_FIELD',
    (name: string, value: string) => ({
        name,
        value,
    }),
);
export const updateFields = createAction<FieldType>('UPDATE_FIELDS', fieldSet => fieldSet);
export const clearField = createAction<string, string>('CLEAR_FIELD', name => name);

/**
 * Account settings reducer
 */
export const accountSettings = createReducer<AccountSettingsType>(
    {
        [`${updateAccountSettings}${FULFILLED}`]: (
            state: AccountSettingsType,
        ): AccountSettingsType => ({
            ...defaultState,
            firstname: state.firstname,
            lastname: state.lastname,
            email: state.email,
            changePassword: false,
            isProcessing: false,
            isSuccessUpdate: true,
        }),
        [`${updateAccountSettings}`]: (state: AccountSettingsType): AccountSettingsType => ({
            ...state,
            isProcessing: true,
        }),
        [`${updateAccountSettings}${REJECTED}`]: (
            state: AccountSettingsType,
            {error: {response}},
        ) => ({
            ...state,
            errors: {
                form: '',
                ...getMessage(response.data.message),
            },
            isProcessing: false,
        }),
        [`${updateField}`]: (state: AccountSettingsType, {name, value}): AccountSettingsType => ({
            ...state,
            [name]: value,
            errors: {...state.errors, [name]: ''},
        }),
        [`${updateFields}`]: (state: AccountSettingsType, fieldSet): AccountSettingsType => ({
            ...state,
            ...fieldSet,
        }),
        [`${clearField}`]: (state: AccountSettingsType, name): AccountSettingsType => ({
            ...state,
            [name]: defaultState[name],
        }),
        [togglePasswordForm.toString()]: state => ({
            ...state,
            changePassword: !state.changePassword,
            errors: {
                ...state.errors,
                ...PASSWORD_FIELDS.reduce((obj, {name}) => ({...obj, [name]: ''}), {}), // Clear only password errors
            },
        }),
        [`${clearAccountSettings}`]: (): AccountSettingsType => defaultState,
        [`${setAccountSettingsError}`]: (
            state: AccountSettingsType,
            {invalidFieldName, errorMsg},
        ): AccountSettingsType => ({
            ...state,
            errors: {
                ...state.errors,
                [invalidFieldName]: errorMsg,
            },
        }),
        [`${clearErrors}`]: (state): AccountSettingsType => ({
            ...state,
            errors: defaultState.errors,
            changePassword: false,
            isSuccessUpdate: false,
        }),
    },
    defaultState,
);
