import Rox, {RoxFetcherResult} from 'rox-browser';

import flags from 'constants/flags/flags';
import rolloutKeys from 'constants/flags/keys';

import {FETCH_EVENT} from './fetcher';

export type CustomPropertiesProp = {
    origin: string;
};

// eslint-disable-next-line no-underscore-dangle
window.__showRolloutFlags = () => Rox.showOverrides();

export const config = {
    configurationFetchedHandler: (result: RoxFetcherResult) => {
        const event = new CustomEvent(FETCH_EVENT, {detail: result});
        window.dispatchEvent(event);
    },
};

export const setCustomProperties = ({origin}: CustomPropertiesProp) => {
    Rox.setCustomStringProperty('domain', origin);

    initRollout();
};

const initRollout = () => {
    Rox.register('', flags);
    Rox.setup(rolloutKeys[process.env.APP_ENV || 'production'], config);
};
