/* istanbul ignore file */

import React, {useContext, ChangeEvent} from 'react';
import {Input, CtaButton} from '@optimaxdev/design-system-u';
import {connect} from 'react-redux';
import {useMutation} from '@tanstack/react-query';
import {pushDataLayer} from '@optimaxdev/analytics/desktop';
import SessionStore from 'store/storages/sessionStorage';

import {setToken as setTokenAction} from 'reducers/oauth2';
import {AxiosError} from 'constants/typescript/types';
import {GetTokenErrorType} from 'features/loginPopup/api/customerMs/customerMsType';
import {USER_MEMBER_TYPE} from 'constants/authorization';
import {ACCOUNT, LOGIN_STATUS} from 'constants/loginStatus/loginStatus';
import flags from 'constants/flags/flags';
import {
    LoginPopupContext,
    LoginPopupContextType,
} from 'features/loginPopup/context/loginPopupContext';

import {ERROR_REASONS, INPUTS} from '../../constants/loginPopup';
import s from '../loginPopup.scss';
import {getToken} from '../../api/customerMs/customerMs';
import {LoginPopupNames} from '../loginPopup';
import {
    sendCreateNonUhcAccountEvent,
    sendLoginAsEmailSuccessEvent,
    sendLoginStatusEvent,
} from '../memberLogin/analytics';

import {PropsType} from './helpers';

// TODO: Add test for this component
export const EmailLoginForm: React.FC<PropsType> = ({setToken, setPopup, onRequestClose}) => {
    const {
        emailLoginData,
        setEmailLoginData,
        setCanEditPhone,
        setAccountUuid,
        setPhoneNumber,
    } = useContext(LoginPopupContext) as LoginPopupContextType;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.currentTarget;
        setEmailLoginData({...emailLoginData, [name]: {value}});
    };

    const {isLoading, mutate} = useMutation(
        () => getToken(emailLoginData.email.value, emailLoginData.password.value),
        {
            // eslint-disable-next-line max-statements
            onSuccess: data => {
                if (flags.mfaUHCGD.isEnabled() && data.phone) {
                    setCanEditPhone(data.canEditPhone);
                    setAccountUuid(data.accountUuid);
                    setPhoneNumber(data.phone);
                    setPopup(LoginPopupNames.mfa);
                } else {
                    setToken({
                        ...data,
                        memberType: USER_MEMBER_TYPE.NOT_UHC_MEMBER,
                    });
                    onRequestClose();
                    sendLoginAsEmailSuccessEvent();
                    if (SessionStore.read(LOGIN_STATUS) !== ACCOUNT) {
                        sendLoginStatusEvent(true);
                        SessionStore.write(LOGIN_STATUS, ACCOUNT);
                    }
                }
            },
            onError: ({response}: AxiosError<GetTokenErrorType>) => {
                if (
                    flags.mfaUHCGD.isEnabled() &&
                    response?.status === 401 &&
                    response?.data?.hint === 'phone'
                ) {
                    setPopup(LoginPopupNames.mfa);
                } else {
                    const errorReason = ERROR_REASONS.find(
                        reason => reason.error === String(response?.data.error),
                    );
                    pushDataLayer({
                        event: 'GeneralInteraction',
                        eventAction: 'Login As Account',
                        eventCategory: 'Login - D',
                        eventLabel: `Error${
                            errorReason ? ` - ${String(response?.data.message)}` : ''
                        }`,
                    });
                    if (errorReason) {
                        setEmailLoginData({
                            ...emailLoginData,
                            [errorReason.input]: {value: '', error: String(response?.data.message)},
                        });
                    }
                }
            },
        },
    );

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        mutate();
    };

    const handleSignUp = () => {
        setPopup(LoginPopupNames.create);
        sendCreateNonUhcAccountEvent();
    };

    return (
        <section>
            <h2 className={s.title}>Sign in</h2>
            <p className={s.text}>Access your UHCGlasses.com account</p>

            <form onSubmit={onSubmit}>
                {INPUTS.map(({name, placeholder, autoComplete}) => (
                    <div key={name} className={s.input}>
                        <Input
                            label={placeholder}
                            type={name}
                            name={name}
                            onChange={handleChange}
                            value={emailLoginData[name].value}
                            message={emailLoginData[name].error}
                            isError={Boolean(emailLoginData[name].error)}
                            autoComplete={autoComplete}
                            isRequired
                        />
                    </div>
                ))}
                <button
                    type="button"
                    className={s.forgot}
                    onClick={() => setPopup(LoginPopupNames.forgot)}
                >
                    Forgot password?
                </button>

                <CtaButton type="submit" size="large" isLoading={isLoading}>
                    Continue
                </CtaButton>
            </form>

            <p className={`${s.text} ${s.signUp}`}>
                Don’t have an account? <button onClick={handleSignUp}>Sign up</button>
            </p>
        </section>
    );
};

const mapDispatchToProps = {
    setToken: setTokenAction,
};

export const EmailLoginFormConnected = connect(null, mapDispatchToProps)(EmailLoginForm);
