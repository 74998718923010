import {EecAddToCartUVPProductType, pushDataLayer} from '@optimaxdev/analytics/desktop';
import {SagaIterator} from 'redux-saga';
import {all, call, put, select, take} from 'redux-saga/effects';
import {isEmpty} from 'ramda';

import {clearCart, getCartItems, getCartTotals} from 'reducers/cart';
import {FULFILLED} from 'constants/actionSuffix';
import {
    getLastCartItem,
    getCartItems as getItemsFromCart,
    getCartData,
    getRemovedProductId,
} from 'selectors/cart/cart';
import {
    prepareCartItemToEEC,
    prepareCoupons,
    prepareRemoveFromCartEEC,
    sendEECAddToCartEvent,
} from 'libs/analytics/eec';
import {isConfigurePage} from 'selectors/wizard/configure/configure';
import {getCategoryMap} from 'selectors/order/order';
import {getWishListItems} from 'reducers/wishListItems';
import {LINK_PAGE} from 'constants/links';

/**
 * Sends addToCart event with added product
 *
 */
export function* addToCartEECSaga(): SagaIterator {
    yield take(`${getCartItems}${FULFILLED}`);
    const [categoryMap, cartItem, isConfigure] = yield all([
        select(getCategoryMap),
        select(getLastCartItem),
        select(isConfigurePage),
    ]);

    if (isEmpty(cartItem) || isConfigure) return;

    const addedProduct = yield call(prepareCartItemToEEC, cartItem, categoryMap);
    yield call(sendEECAddToCartEvent, addedProduct);
}

/**
 * Collects and sends
 * ecommerce data
 * about removed cart items
 * */
export function* clearCartEECSaga(): SagaIterator {
    const [cartItems, categoryMap, {isLoading}] = yield all([
        select(getItemsFromCart),
        select(getCategoryMap),
        select(getCartData),
    ]);

    let products = cartItems.map(item => prepareCartItemToEEC(item, categoryMap));

    // if cart is in isLoading state we expect to get new cart items
    // need wait for updated cart items
    if (isLoading) {
        const {
            payload: {data: newCartItems},
        } = yield take(`${getCartItems}${FULFILLED}`);

        products = newCartItems.map(item => prepareCartItemToEEC(item, categoryMap));
    }

    yield take(`${clearCart}${FULFILLED}`);

    yield call(pushDataLayer, {
        event: 'clearCart',
        ecommerce: {
            remove: {
                products,
            },
        },
    });
}

/**
 * send analytics when cart have items
 * */
export function* sendWhenCartHaveItems(): SagaIterator {
    yield all([take(`${getCartTotals}${FULFILLED}`), take(`${getCartItems}${FULFILLED}`)]);
    const {items, totals} = yield select(getCartData);
    const categoryMap = yield select(getCategoryMap);
    const dimensionData: EecAddToCartUVPProductType[] = [];
    if (items?.length && !window.location.pathname.includes(LINK_PAGE.THANK_YOU)) {
        items.forEach(element => {
            dimensionData.push(prepareCartItemToEEC(element, categoryMap));
        });

        const EECobj = {
            event: 'EEC_cart_products',
            ecommerce: {
                currencyCode: 'USD',
                impressions: {
                    products: dimensionData,
                },
            },
            AppliedCoupon: prepareCoupons(totals.certificates),
        };
        yield call(pushDataLayer, EECobj);
    }
}

/**
 * send analytics when change product in cart
 * */
export function* sendAnalyticsEveryChangeProductInCart(): SagaIterator {
    yield take(`${getCartItems}${FULFILLED}`);

    const [categoryMap, cartItem, isConfigure] = yield all([
        select(getCategoryMap),
        select(getLastCartItem),
        select(isConfigurePage),
    ]);

    if (isEmpty(cartItem) || isConfigure) return;

    const changeProduct = yield call(prepareCartItemToEEC, cartItem, categoryMap);

    yield call(sendEECAddToCartEvent, changeProduct);
}

/**
 * send analytics when user remove product in cart
 * */
export function* sendAnalyticsWhenRemoveInCard(): SagaIterator {
    const categoryMap = yield select(getCategoryMap);

    const productItem = yield select(getRemovedProductId);

    yield put(getWishListItems([productItem.product.id]));

    const product = yield take(`${getWishListItems}${FULFILLED}`);

    if (productItem && categoryMap && product.payload.data.items[0]) {
        yield call(pushDataLayer, {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: prepareRemoveFromCartEEC(
                        productItem,
                        product.payload.data.items[0],
                        categoryMap,
                    ),
                },
            },
        });
    }
}
