import {STORE_ID} from 'constants/store';
import {formatString} from 'libs/utils/utils';

export const ENDPOINTS = {
    PRODUCT_MAGENTO: formatString.bind(null, '/backend/api/rest/v4/catalog/product/{{productId}}'),
    CUSTOMER: 'api/rest/customer',
    ADDRESS: 'api/rest/customer/address',
    CART: 'api/rest/optimaxcheckout/cart',
    CART_ITEMS: 'api/rest/optimaxcheckout/v2/cart/items',
    CART_TOTALS: 'api/rest/optimaxcheckout/v2/cart/totals',
    CLEAR_CART: 'api/rest/checkout/cart',
    CART_ITEM: 'api/rest/optimaxcheckout/cart/item',
    CART_ITEM_V2: 'api/rest/optimaxcheckout/v2/cart/item',
    CART_PRODUCTS: `${STORE_ID}/product`,
    CATEGORY: 'api/rest/catalog/category',
    CATEGORY_FILTERS: `${STORE_ID}/filters`,
    CATEGORY_ITEMS: `${STORE_ID}/products`,
    CMS_PAGE: 'api/rest/cms/block',
    CONTACT_US_FORM: 'contacts/index/post/',
    COUNTRY: `directory/country`,
    CUSTOMER_CURRENT: 'customer/api/current/',
    DELETE_UHC_MEMBER: 'api/rest/uhc/member/v3',
    MY_PLAN: 'api/rest/uvp/plan',
    RESET_PASSWORD: `password`,
    RMA: 'order/rma',
    OAUTH2_TOKEN: `token`,
    OLD_LOG_IN_ACTION: 'customer/account/loginPost',
    MAGENTO_LOGOUT: 'customer/account/logout',
    SAVE_ORDER: 'checkout/onepage/saveOrder',
    SAVE_ORDER_ADDRESSES: 'optimaxcheckout/address/save',
    ORDERS: 'api/rest/sales/order',
    GET_ORDER_BY_INVOICE: 'api/rest/sales/order/invoice',
    FORGOT_PASSWORD: `password/reset`,
    GET_PAYMENT_METHODS: 'optimaxcheckout/payment',
    SAVE_PAYMENT_METHOD: '/backend/optimaxcheckout/payment/save',
    SET_ALLOWANCE: 'api/rest/uhc/item',
    REMOVE_ALLOWANCE: 'api/rest/uhc/item',
    PRESCRIPTION: `prescription`,
    REIMBURSEMENT: 'customer/reimbursement/post',
    GET_ROUTE: `route/${STORE_ID}`,
    GET_SESSION: 'glassespage/react/getSession',
    SAVE_SHIPPING_METHOD: '/backend/api/rest/optimaxcheckout/v2/regular/cart/shipping',
    GET_SHIPPING_METHODS: '/backend/optimaxcheckout/shipping',
    GET_STORE_INFO: 'api/rest/store_info',
    GET_TRACKING: 'api/rest/ordertracking/tracking',
    GET_IMAGES_TRY_ON: 'mirror/react/getImages',
    REMOVE_USER_PHOTO: 'mirror/customer/deletephoto/photoId',
    TRYON_UPLOAD_IMAGE: 'mirror/wizard/upload',
    TRYON_SET_ADJUST: 'mirror/wizard/adjustsave',
    GET_USER: `me`,
    GET_USER_DISCOUNT: 'api/rest/customer/group/current',
    WISH_LIST: 'wishlist',
    GET_WISH_LIST_ITEMS: `${STORE_ID}/products/0`,
    WIZARD: {
        GET_LENS_IMAGES: 'api/rest/v2/catalog/product/',
        GET_PRESCRIPTIONS: 'api/rest/customer/prescription',
        SAVE_PRESCRIPTION: 'api/rest/customer/prescriptions',
        FETCH_WIZARD_DATA: 'api/rest/wizard/',
    },
    FIND_SIMILAR_PRODUCTS: 'find-similar-products',
    // wizard ms
    ADD_TO_CART_V2: 'api/rest/optimaxcheckout/v2/cart/items',
    UHC_API_LOGIN: '/backend/api/rest/uhc',
    MERGE_ACCOUNT: '/backend/api/rest/uhc/associate/uhcg',
    PRODUCT_PRICE: formatString.bind(null, 'api/rest/catalog/product/{{id}}/price'),
    APPLY_COUPON: '/backend/optimaxcart/react/applyCoupon',
    REMOVE_COUPON: '/backend/optimaxcart/react/removeCoupon',
    CART_PUT_GET_QUOTE_ITEM_V2: 'api/rest/optimaxcheckout/v2/cart/item',
    PRODUCT_GET_WIZARD: formatString.bind(null, 'api/rest/catalog/product/{{id}}/wizard'),
    SUCCESS_PAGE_CONFIRM_ORDER: formatString.bind(null, 'api/rest/sales/order/{{orderId}}'),
};
