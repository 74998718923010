import React, {ChangeEvent, FC, ReactElement, SyntheticEvent, useCallback, useState} from 'react';
import InputMask from 'react-text-mask';
import {CtaButton, Input} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import {pickBy} from 'ramda';
import {connect} from 'react-redux';

import {setCmsPopupVisibility} from 'reducers/cms/cmsPopup';
import {InputsValuesType, validateForm} from 'libs/validation/validateForm';
import {
    inputs,
    inputsFullNameForm,
} from 'features/loginPopup/components/memberLogin/memberLoginInputs';
import {
    AxiosErrorWithApiResponseErrorDetails,
    getErrorMessageByAxiosErrorWithErrorDetails,
} from 'libs/axiosResponceHandling';
import {setToken as setTokenAction} from 'reducers/oauth2';
import {USER_MEMBER_TYPE} from 'constants/authorization';
import {ApplicationStoreType} from 'constants/typescript/types';
import {getUserRefreshToken} from 'selectors/oauth2Data/oauth2Data';

import {mergeAcc} from '../api/mergeAcc';
import {MergeAccountErrorPopupConnected} from '../mergeAccountErrorPopup/mergeAccountErrorPopup';

import s from './mergeAccountForm.scss';

export type PropsType = {
    openErrorPopup: (node: ReactElement) => void;
    setToken: typeof setTokenAction;
    refreshToken: string;
};

export const MergeAccountForm: FC<PropsType> = ({openErrorPopup, setToken, refreshToken}) => {
    const [isFullNameFrom, toggleFormOption] = useState<boolean>(true);
    const INPUTS = isFullNameFrom ? inputsFullNameForm : inputs;
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [input, setInputValue] = useState<Record<keyof InputsValuesType, string>>({
        id: '',
        birthday: '',
        first_name: '',
        last_name: '',
        zip_code: '',
    });

    const {isLoading, mutate} = useMutation(
        () => mergeAcc(pickBy(value => value !== '', {...input, refreshToken})),
        {
            onSettled: () => {
                localStorage.setItem('popUpWasShown', '');
            },
            onSuccess: data => {
                setToken({
                    ...data,
                    memberType: USER_MEMBER_TYPE.UHC_MEMBER,
                    settingsSafetyMode: isFullNameFrom,
                });
            },
            onError: (errorResponse: AxiosErrorWithApiResponseErrorDetails) => {
                const backendResponseErrorString = getErrorMessageByAxiosErrorWithErrorDetails(
                    errorResponse,
                );
                setErrors(errors => ({
                    ...errors,
                    response: backendResponseErrorString,
                }));
                openErrorPopup(
                    <MergeAccountErrorPopupConnected errorMessage={backendResponseErrorString} />,
                );
            },
        },
    );

    const handleChange = useCallback(
        ({
            target: {name, value},
        }: ChangeEvent<{name: keyof InputsValuesType} & HTMLInputElement>) => {
            setInputValue(prevState => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setInputValue],
    );

    const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {hasErrors, errors} = validateForm(INPUTS, input);
        setErrors(errors);
        if (!hasErrors) mutate();
    };

    return (
        <section className={s.mergeAccountIDWrap}>
            <p className={s.mergeAccountIDTitle}>Access your vision benefits</p>
            <p className={s.mergeAccountIDSubTitle}>
                Check your vision plan coverage and eligibility
            </p>

            <form onSubmit={onSubmit}>
                {INPUTS.map(({name, placeholder, ...props}) => (
                    <div className={s.input} key={name}>
                        <InputMask
                            value={input[name]}
                            onChange={handleChange}
                            {...props}
                            render={(ref, {defaultValue, ...inputProps}) => (
                                <Input
                                    {...inputProps}
                                    label={placeholder}
                                    value={defaultValue}
                                    message={errors[name]}
                                    isError={Boolean(errors[name])}
                                    name={name}
                                    ref={ref}
                                    isRequired
                                />
                            )}
                        />
                    </div>
                ))}
                <CtaButton
                    type="submit"
                    size="large"
                    isLoading={isLoading}
                    className={s.mergeAccountIDButton}
                >
                    Access Benefits
                </CtaButton>
            </form>

            <a
                className={s.mergeAccountIDLink}
                onClick={() => toggleFormOption(prevState => !prevState)}
            >
                {isFullNameFrom ? 'Log in with your member ID' : 'Forgot your member ID?'}
            </a>
        </section>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    refreshToken: getUserRefreshToken(store),
});

export const mapDispatchToProps = {
    openErrorPopup: (content: ReactElement) =>
        setCmsPopupVisibility(true, content, {
            className: 'mergeAccountForm',
        }),
    setToken: setTokenAction,
};

export const MergeAccountFormConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MergeAccountForm);
