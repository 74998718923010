import React from 'react';
import {Link} from 'react-router-dom';

import {Icon} from 'components/__helpers/icon/icon';
import {LINK_PAGE, LINK_UHC_SUPPORT} from 'constants/links';
import {openChat} from 'libs/chat';

import {TopStripMenuDropdown} from '../topStripMenuDropdown/topStripMenuDropdown';
import s from './customerCare.scss';

export const CustomerCare = () => {
    return (
        <div className={s.customerCare}>
            <div className={s.titleContainer}>
                <div className={s.title}>Customer Care</div>
                <div className={s.arrowIcon}>
                    <Icon name="myAccountArrow" />
                </div>
            </div>
            <TopStripMenuDropdown activeClass={s.activeClass}>
                <ul>
                    <li>
                        <a href={LINK_UHC_SUPPORT}>
                            <Icon name="helpCenter" />
                            Help Center
                        </a>
                    </li>
                    <li>
                        <button onClick={openChat}>
                            <Icon name="chatWidthUs" />
                            Chat with Us
                        </button>
                    </li>
                    <li>
                        <Link to={LINK_PAGE.CONTACT_US}>
                            <Icon name="contactUsCustomCare" />
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </TopStripMenuDropdown>
        </div>
    );
};
