import React, {useCallback, useState, ChangeEvent, FC} from 'react';
import classnames from 'classnames';
import {Input, CtaButton} from '@optimaxdev/design-system-u';
import {useMutation} from '@tanstack/react-query';
import {pushDataLayer} from '@optimaxdev/analytics/desktop';

import {
    validateForm,
    InitialValidateFormType,
    transformInputsDescriptionsMapToInputMasks,
} from 'libs/validation/validateForm';
import {
    getErrorMessageByAxiosErrorWithErrorDetails,
    AxiosErrorWithApiResponseErrorDetails,
} from 'libs/axiosResponceHandling';

import s from '../loginPopup.scss';
import {passwordRecovery} from '../../api/customerMs/customerMs';

import forgotPasswordStyles from './forgotPassword.scss';
import {ForgotPasswordCongratulationsPopup} from './forgotPasswordCongratulationsPopup';
import {INPUT_FIELDS} from './forgotPasswordInputs';

export type PropsType = {
    onRequestClose: () => void;
};

/**
 * Forgot password popup component
 *
 * @export
 * @param {PropsType} props - props
 * @returns {React.ReactElement} - React component
 */
export const ForgotPasswordPopUp: FC<PropsType> = ({onRequestClose}) => {
    const [formHasSubmitted, setFormHasSubmitted] = useState(false);
    const [responseErrorMessage, setResponseErrorMessage] = useState('');
    const [inputsFieldsValues, setInput] = useState({
        email: '',
    });
    const [formValidationResult, setFormValidationResult] = useState(
        {} as Partial<InitialValidateFormType>,
    );
    const {isLoading, mutate} = useMutation(
        ({email}: {email: string}) => passwordRecovery({email}),
        {
            onSuccess: () => {
                setFormHasSubmitted(true);
                pushDataLayer({
                    event: 'GeneralInteraction',
                    eventAction: 'Forgot Password',
                    eventCategory: 'Login - D',
                    eventLabel: 'Success',
                });
            },
            onError: (errorDetails: AxiosErrorWithApiResponseErrorDetails) => {
                setResponseErrorMessage(getErrorMessageByAxiosErrorWithErrorDetails(errorDetails));
                pushDataLayer({
                    event: 'GeneralInteraction',
                    eventAction: 'Forgot Password',
                    eventCategory: 'Login - D',
                    eventLabel: `Error - ${errorDetails.message}`,
                });
            },
        },
    );
    const onSubmit = useCallback(
        (ev: React.SyntheticEvent<HTMLFormElement>) => {
            ev.preventDefault();
            const formValidationResult = validateForm(
                transformInputsDescriptionsMapToInputMasks(INPUT_FIELDS),
                inputsFieldsValues,
            );
            setFormValidationResult(formValidationResult);
            if (!formValidationResult.hasErrors) {
                setResponseErrorMessage('');
                mutate({
                    email: inputsFieldsValues.email,
                });
            }
        },
        [inputsFieldsValues, mutate],
    );
    const handleChange = useCallback(
        ({currentTarget: {value, name}}: ChangeEvent<{name: string} & HTMLInputElement>) => {
            setResponseErrorMessage('');
            setInput(prevState => ({
                ...prevState,
                [name]: value,
            }));
        },
        [],
    );
    if (formHasSubmitted) {
        return <ForgotPasswordCongratulationsPopup />;
    }
    return (
        <section className={forgotPasswordStyles.container}>
            <h2 className={classnames(s.title, forgotPasswordStyles.header)}>
                Can’t remember your password?
            </h2>
            <p className={s.text}>Just enter your email and we’ll remind you.</p>

            <form onSubmit={onSubmit} className={forgotPasswordStyles.form}>
                {Object.keys(INPUT_FIELDS).map(inputFieldName => {
                    const {placeholder, autoComplete, type} = INPUT_FIELDS[inputFieldName];
                    const value = inputsFieldsValues[inputFieldName];
                    const error = formValidationResult.errors?.[inputFieldName];
                    return (
                        <div key={inputFieldName} className={s.input}>
                            <Input
                                label={placeholder}
                                type={type}
                                name={inputFieldName}
                                onChange={handleChange}
                                value={value}
                                message={error}
                                isError={Boolean(error)}
                                autoComplete={autoComplete}
                                isRequired
                            />
                        </div>
                    );
                })}

                {responseErrorMessage && <p className={s.errorMessage}>{responseErrorMessage}</p>}
                <CtaButton
                    type="submit"
                    size="large"
                    className={s.submitButton}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    ariaLabel="Submit data for access recovery"
                >
                    Continue
                </CtaButton>
            </form>

            <button
                type="button"
                className={classnames(s.cancelButton, forgotPasswordStyles.cancelButton)}
                onClick={onRequestClose}
                disabled={Boolean(isLoading)}
            >
                Cancel
            </button>
        </section>
    );
};
