import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {Icon} from 'components/__helpers/icon/icon';
import {LINK_MY_ACCOUNT, LINK_PAGE} from 'constants/links';
import {ApplicationStoreType} from 'constants/typescript/types';
import {getFirstName} from 'selectors/eligibilityWidget/eligibilityWidget';
import {LoginPopUpShownEvent} from 'libs/analytics/loginPopUp/GeneralInteractionEvents';

import {TopStripMenuDropdown} from '../topStripMenuDropdown/topStripMenuDropdown';
import s from './myAccount.scss';

type PropsType = {
    firstName: string;
    setIsOpen: (type: boolean) => void;
    isLoggedIn: boolean;
    logOutForAccount: () => void;
};

export const MyAccount: FC<PropsType> = ({setIsOpen, isLoggedIn, logOutForAccount, firstName}) => {
    const handleLoginAccount = () => {
        if (isLoggedIn) {
            logOutForAccount();
        } else {
            setIsOpen(true);
            LoginPopUpShownEvent(['LogIn']);
        }
    };

    return (
        <div className={s.myAccountMenu}>
            <div className={s.accountContainer}>
                <div className={s.myAccountIcon}>
                    <Icon name="user" />
                </div>
                <div className={s.title}>{isLoggedIn ? `Hello, ${firstName}` : 'My Account'}</div>
                <div className={s.arrowIcon}>
                    <Icon name="myAccountArrow" />
                </div>
            </div>
            <TopStripMenuDropdown activeClass={s.activeClass}>
                <ul className={classNames({[s.logged]: isLoggedIn})}>
                    {isLoggedIn ? (
                        <li>
                            <Link to={LINK_MY_ACCOUNT.ORDERS}>
                                <Icon name="myAccountDropDown" />
                                My Account
                            </Link>
                        </li>
                    ) : (
                        <li>
                            <button className={s.item} type="button" onClick={handleLoginAccount}>
                                <>
                                    <Icon name="logInMenu" />
                                    Log in
                                </>
                            </button>
                        </li>
                    )}
                    <li>
                        <Link to={LINK_PAGE.TRACKING}>
                            <Icon name="location" />
                            Order Tracking
                        </Link>
                    </li>
                    <li>
                        <Link to={LINK_PAGE.RMA}>
                            <Icon name="exchanges" />
                            Exchanges & Returns
                        </Link>
                    </li>

                    {isLoggedIn && (
                        <li>
                            <button className={s.item} type="button" onClick={handleLoginAccount}>
                                <Icon name="signOutSignOutMenu" />
                                Sign out
                            </button>
                        </li>
                    )}
                </ul>
            </TopStripMenuDropdown>
        </div>
    );
};

export const mapStateToProps = (store: ApplicationStoreType) => ({
    firstName: getFirstName(store),
});

export const MyAccountConnected = connect(mapStateToProps)(MyAccount);
