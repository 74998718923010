import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';

import {axiosClient} from 'libs/configureStore';

export type DiscountType = {
    name: string;
    discount: number;
    description: string;
    action: 'by_percent' | 'by_fixed';
};

export type DiscountDataObjType = {
    [key: string]: DiscountType;
};

export type DiscountGroupType = {
    title: string;
    data: DiscountDataObjType;
};

export type DiscountsType = {
    frame: DiscountGroupType;
    package: DiscountGroupType;
    packageOption: DiscountGroupType;
    otherBenefits: DiscountGroupType;
    insuranceBenefits: DiscountGroupType;
};

export type BenefitType = {
    copay: number;
    allowance: number;
    description: string;
    priceProtection: number;
    discount?: number;
};

export type PlanType = {
    first_name: string;
    last_name: string;
    benefits?: BenefitType[];
    discounts?: DiscountsType;
};

export const usePlanRequest = () => {
    return useQuery<AxiosResponse<PlanType>, Error>(['plan'], () =>
        axiosClient({
            method: 'GET',
            headers: {Authorization: ''},
            url: '/backend/api/rest/uhc/member/v3',
            data: data => ({
                ...data,
                discounts: Array.isArray(data.discounts)
                    ? {
                          frame: {title: '', data: []},
                          package: {title: '', data: []},
                          packageOption: {title: '', data: []},
                          otherBenefits: {title: '', data: []},
                          insuranceBenefits: {title: '', data: []},
                      }
                    : data.discounts,
            }),
        }),
    );
};
