import {createAction, createReducer} from 'redux-act';
import {resetDataFromLocalStorage} from 'features/contacts/reducers/resetData/resetData';

export const PRESCRIPTION = 'prescription' as const;
export const CUSTOMIZE = 'customize' as const;
export const FILL_IT_LEFT_EYE = 'fill it left eye' as const;
export const FILL_IT_RIGHT_EYE = 'fill it right eye' as const;
export const FILL_IT_FOR_BOTH = 'fill it for both' as const;
export const SEND_LATER = 'send later' as const;
export const COUNTING = 'counting' as const;
export const COLORS = 'colors' as const;
export const SUBSCRIBE = 'subscribe' as const;
export const SUCCESS_OR_ERROR_PRESCRIPTION_UPLOAD_FILE = 'success or error prescription upload file' as const;

export type StepType =
    | typeof PRESCRIPTION
    | typeof COUNTING
    | typeof CUSTOMIZE
    | typeof SUCCESS_OR_ERROR_PRESCRIPTION_UPLOAD_FILE
    | typeof FILL_IT_LEFT_EYE
    | typeof FILL_IT_RIGHT_EYE
    | typeof FILL_IT_FOR_BOTH
    | typeof SEND_LATER
    | typeof COLORS
    | typeof SUBSCRIBE;

export type StepTypeForBothType = Omit<
    StepType,
    typeof FILL_IT_LEFT_EYE | typeof FILL_IT_RIGHT_EYE
> &
    typeof FILL_IT_FOR_BOTH;

export const initialState: {step: StepType | StepTypeForBothType} = {step: PRESCRIPTION};

export const changeStep = createAction<StepType>('CONTACTS_WIZARD_SET_STEP');

export const wizardStep = createReducer<{step: StepType}>({}, initialState)
    .on(changeStep, (state: {step: StepType}, payload: StepType): {step: StepType} => ({
        ...state,
        step: payload,
    }))
    .on(resetDataFromLocalStorage, () => initialState);
