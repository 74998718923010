import {SagaIterator} from 'redux-saga';
import {all, take, put, call, takeEvery, select} from 'redux-saga/effects';
import {Action} from 'redux-act';

import {
    updateField,
    startSetAddress,
    setAutocomplete,
    StartSetAddressType,
    AutocompleteResType,
} from 'reducers/addressEdit';
import {FULFILLED} from 'constants/actionSuffix';
import {updateAddress, createAddress} from 'reducers/address';
import {CountryRegionItemType, getCountryRegions} from 'reducers/country';
import {getRegionsData, getCountryData} from 'selectors/country/country';

/**
 * Send address request
 */
export function* addressRequest({address, isEdit, newAddress}: StartSetAddressType): SagaIterator {
    if (isEdit && address) {
        yield put(updateAddress(address.id, newAddress));
    } else {
        yield put(createAddress(newAddress));
    }
}

/**
 * Send address to the backend
 *
 * @param {Action<StartSetAddressType>} payload - payload
 */
export function* callAddressRequest({payload}: Action<StartSetAddressType>): SagaIterator {
    yield call(addressRequest, payload);
}

/**
 * Add parsed address from autocomplete to store
 *
 * @param {Action} payload - payload
 */
export function* onAutocomplete({payload}: Action<AutocompleteResType>): SagaIterator {
    yield all([
        put(updateField('street', payload.street)),
        put(updateField('postcode', payload.postCode)),
        put(updateField('city', payload.city)),
    ]);

    const {regions, selectedCountry} = yield select(getCountryData);
    yield put(updateField('countryCode', payload.country));

    if (selectedCountry === payload.country) {
        yield call(trySetRegion, payload.region, regions);
    } else {
        yield take(`${getCountryRegions}${FULFILLED}`);
        const regions = yield select(getRegionsData);
        yield call(trySetRegion, payload.region, regions);
    }
}

/**
 * Add parsed address from autocomplete to store
 *
 * @param {string} region - region's name
 * @param {CountryRegionItemType[]} regions - list of regions
 */
export function* trySetRegion(region: string, regions: CountryRegionItemType[]): SagaIterator {
    if (regions && regions.length > 0) {
        yield put(updateField('regionId', regions.find(reg => reg.name === region)?.id || 0));
    } else {
        yield put(updateField('regionId', 0));
        yield put(updateField('region', region));
    }
}

/**
 * Begin of saga
 */
export function* editAddressSaga(): SagaIterator {
    yield takeEvery(startSetAddress, callAddressRequest);
    yield takeEvery(setAutocomplete, onAutocomplete);
}
